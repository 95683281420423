import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";
import {
  backlogFeedback,
  cancelBacklogFeedback,
  deleteFeedback,
  getDetailFeedback,
} from "../../../containers/Pelanggan/actions";

const HeaderFeedback = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = useSelector((state) => state.pelanggan.statusBacklog);

  const [loading, setLoading] = useState(false);

  const { menu, fb_id } = props;
  const { confirm } = Modal;

  const handleBackScreen = async () => {
    await dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    if (menu === "detailfeedback") navigate("/pelanggan/feedback");
  };

  const confirmHapusFeedback = (id) => {
    confirm({
      title: "Yakin ingin Hapus Feedback ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Hapus",
      cancelText: "Tidak",
      async onOk() {
        setLoading(true);
        await dispatch(setHeader("default"));
        localStorage.setItem("otherTemplate", "false");
        await dispatch(deleteFeedback(id));
        setLoading(false);
        navigate("/pelanggan/feedback");
      },
      onCancel() {
        console.log("Cancel hapus");
      },
    });
  };

  const confirmBacklog = (id) => {
    confirm({
      title: "Yakin ingin Jadikan Backlog Feedback ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Jadikan Backlog",
      cancelText: "Tidak",
      async onOk() {
        setLoading(true);
        await dispatch(backlogFeedback(id));
        dispatch(getDetailFeedback(id));
        setLoading(false);
      },
      onCancel() {
        console.log("Cancel backlog");
      },
    });
  };

  const confirmCancelBacklog = (id) => {
    confirm({
      title: "Yakin ingin Batalkan Backlog Feedback ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Batalkan Backlog",
      cancelText: "Tidak",
      async onOk() {
        setLoading(true);
        await dispatch(cancelBacklogFeedback(id));
        dispatch(getDetailFeedback(id));
        setLoading(false);
      },
      onCancel() {
        console.log("Cancel backlog");
      },
    });
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>{menu === "detailfeedback" ? "Detail Feedback" : ""}</h1>
      </div>
      <div className="rightHeader">
        <Button
          className="btnAct btnActAlert"
          onClick={() => confirmHapusFeedback(fb_id)}
        >
          Hapus
        </Button>
        {status === 0 ? (
          <Button
            className="btnAct btnActSubmit"
            loading={loading}
            onClick={() => confirmBacklog(fb_id)}
          >
            Jadikan Backlog
          </Button>
        ) : status === 1 ? (
          <Button
            className="btnAct btnActSubmit"
            loading={loading}
            onClick={() => confirmCancelBacklog(fb_id)}
          >
            Batalkan Backlog
          </Button>
        ) : (
          <Button
            className="btnAct btnActSubmit"
            loading={loading}
            onClick={() => confirmBacklog(fb_id)}
          >
            Jadikan Backlog
          </Button>
        )}
      </div>
    </>
  );
};
export default HeaderFeedback;
