import {
  GET_PENGGUNA,
  GET_PENGGUNA_SUCCESS,
  GET_PENGGUNA_FAILED,
  DETAIL_PENGGUNA,
  DETAIL_PENGGUNA_SUCCESS,
  DETAIL_PENGGUNA_FAILED,
  HAPUS_PENGGUNA,
  HAPUS_PENGGUNA_SUCCESS,
  HAPUS_PENGGUNA_FAILED,
  TAMBAH_PENGGUNA,
  TAMBAH_PENGGUNA_SUCCESS,
  TAMBAH_PENGGUNA_FAILED,
  GET_JABATAN,
  GET_JABATAN_SUCCESS,
  GET_JABATAN_FAILED,
  GET_SIMPLE_JABATAN,
  GET_SIMPLE_JABATAN_SUCCESS,
  GET_SIMPLE_JABATAN_FAILED,
  GET_TOTAL_JABATAN,
  GET_TOTAL_JABATAN_SUCCESS,
  DETAIL_JABATAN,
  DETAIL_JABATAN_SUCCESS,
  DETAIL_JABATAN_FAILED,
  TAMBAH_JABATAN,
  TAMBAH_JABATAN_SUCCESS,
  TAMBAH_JABATAN_FAILED,
  HAPUS_JABATAN,
  HAPUS_JABATAN_SUCCESS,
  HAPUS_JABATAN_FAILED,
  GET_PARENT,
  GET_PARENT_SUCCESS,
  GET_PARENT_FAILED,
  DETAIL_PARENT,
  DETAIL_PARENT_SUCCESS,
  DETAIL_PARENT_FAILED,
  TAMBAH_PARENT,
  TAMBAH_PARENT_SUCCESS,
  TAMBAH_PARENT_FAILED,
  HAPUS_PARENT,
  HAPUS_PARENT_SUCCESS,
  HAPUS_PARENT_FAILED,
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
  DETAIL_MENU,
  DETAIL_MENU_SUCCESS,
  DETAIL_MENU_FAILED,
  TAMBAH_MENU,
  TAMBAH_MENU_SUCCESS,
  TAMBAH_MENU_FAILED,
  HAPUS_MENU,
  HAPUS_MENU_SUCCESS,
  HAPUS_MENU_FAILED,
  GET_FITUR,
  GET_FITUR_SUCCESS,
  GET_FITUR_FAILED,
  DETAIL_FITUR,
  DETAIL_FITUR_SUCCESS,
  DETAIL_FITUR_FAILED,
  TAMBAH_FITUR,
  TAMBAH_FITUR_SUCCESS,
  TAMBAH_FITUR_FAILED,
  HAPUS_FITUR,
  HAPUS_FITUR_SUCCESS,
  HAPUS_FITUR_FAILED,
  GET_NESTED_FITUR,
  GET_NESTED_FITUR_SUCCESS,
  GET_NESTED_FITUR_FAILED,
  SET_FILTER_JABATAN,
  RESET_FILTER_JABATAN,
} from "./constants";

const initialState = {
  loadingPengguna: true,
  loadingDetailPengguna: true,
  loadingTambahPengguna: false,
  loadingHapusPengguna: false,
  loadingJabatan: true,
  loadingSimpleJabatan: true,
  loadingDetailJabatan: true,
  loadingTambahJabatan: false,
  loadingHapusJabatan: false,
  loadingParent: true,
  loadingDetailParent: true,
  loadingTambahParent: false,
  loadingHapusParent: false,
  loadingMenu: true,
  loadingDetailMenu: true,
  loadingTambahMenu: false,
  loadingHapusMenu: false,
  loadingFitur: true,
  loadingDetailFitur: true,
  loadingTambahFitur: false,
  loadingHapusFitur: false,
  loadingNestedFitur: true,
  pengguna: [],
  jabatan: [],
  simpleJabatan: [],
  parent: [],
  menu: [],
  fitur: [],
  nestedFitur: [],
  detailPengguna: null,
  detailJabatan: null,
  totalJabatan: 0,
  detailParent: null,
  detailMenu: null,
  detailFitur: null,
  jabatanFilter: null,
  forbiddenPengguna: false,
  forbiddenJabatan: false,
  forbiddenSimpleJabatan: false,
  forbiddenParent: false,
  forbiddenMenu: false,
  forbiddenFitur: false,
  forbiddenDetailPengguna: false,
  forbiddenDetailJabatan: false,
  forbiddenDetailParent: false,
  forbiddenDetailMenu: false,
  forbiddenDetailFitur: false,
};

export const penggunaReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_PENGGUNA:
      return {
        ...state,
        loadingPengguna: true,
        pengguna: initialState.pengguna,
        forbiddenPengguna: initialState.forbiddenPengguna,
      };
    case GET_PENGGUNA_SUCCESS:
      return {
        ...state,
        loadingPengguna: false,
        pengguna: action.payload,
      };
    case GET_PENGGUNA_FAILED:
      return {
        ...state,
        loadingPengguna: false,
        forbiddenPengguna: action.payload === 403,
      };
    case GET_JABATAN:
      return {
        ...state,
        loadingJabatan: true,
        jabatan: initialState.jabatan,
        forbiddenJabatan: initialState.forbiddenJabatan,
      };
    case GET_JABATAN_SUCCESS:
      return {
        ...state,
        loadingJabatan: false,
        jabatan: action.payload,
      };
    case GET_JABATAN_FAILED:
      return {
        ...state,
        loadingJabatan: false,
        forbiddenJabatan: action.payload === 403,
      };
    case GET_SIMPLE_JABATAN:
      return {
        ...state,
        loadingSimpleJabatan: true,
        simpleJabatan: initialState.simpleJabatan,
      };
    case GET_SIMPLE_JABATAN_SUCCESS:
      return {
        ...state,
        loadingSimpleJabatan: false,
        simpleJabatan: action.payload,
      };
    case GET_SIMPLE_JABATAN_FAILED:
      return {
        ...state,
        loadingSimpleJabatan: false,
      };
    case GET_TOTAL_JABATAN:
      return {
        ...state,
        totalJabatan: initialState.totalJabatan,
      };
    case GET_TOTAL_JABATAN_SUCCESS:
      return {
        ...state,
        totalJabatan: action.payload,
      };
    case GET_PARENT:
      return {
        ...state,
        loadingParent: true,
        parent: initialState.parent,
        forbiddenParent: initialState.forbiddenParent,
      };
    case GET_PARENT_SUCCESS:
      return {
        ...state,
        loadingParent: false,
        parent: action.payload,
      };
    case GET_PARENT_FAILED:
      return {
        ...state,
        loadingParent: false,
        forbiddenParent: action.payload === 403,
      };
    case GET_MENU:
      return {
        ...state,
        loadingMenu: true,
        menu: initialState.menu,
        forbiddenMenu: initialState.forbiddenMenu,
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        loadingMenu: false,
        menu: action.payload,
      };
    case GET_MENU_FAILED:
      return {
        ...state,
        loadingMenu: false,
        forbiddenMenu: action.payload === 403,
      };
    case GET_FITUR:
      return {
        ...state,
        loadingFitur: true,
        fitur: initialState.fitur,
        forbiddenFitur: initialState.forbiddenFitur,
      };
    case GET_FITUR_SUCCESS:
      return {
        ...state,
        loadingFitur: false,
        fitur: action.payload,
      };
    case GET_FITUR_FAILED:
      return {
        ...state,
        loadingFitur: false,
        forbiddenFitur: action.payload === 403,
      };
    case GET_NESTED_FITUR:
      return {
        ...state,
        loadingNestedFitur: true,
        nestedFitur: initialState.nestedFitur,
      };
    case GET_NESTED_FITUR_SUCCESS:
      return {
        ...state,
        loadingNestedFitur: false,
        nestedFitur: action.payload,
      };
    case GET_NESTED_FITUR_FAILED:
      return {
        ...state,
        loadingNestedFitur: false,
      };
    case TAMBAH_PENGGUNA:
      return {
        ...state,
        loadingTambahPengguna: true,
      };
    case TAMBAH_PENGGUNA_SUCCESS:
      return {
        ...state,
        loadingTambahPengguna: false,
      };
    case TAMBAH_PENGGUNA_FAILED:
      return {
        ...state,
        loadingTambahPengguna: false,
      };
    case TAMBAH_JABATAN:
      return {
        ...state,
        loadingTambahJabatan: true,
      };
    case TAMBAH_JABATAN_SUCCESS:
      return {
        ...state,
        loadingTambahJabatan: false,
      };
    case TAMBAH_JABATAN_FAILED:
      return {
        ...state,
        loadingTambahJabatan: false,
      };
    case TAMBAH_PARENT:
      return {
        ...state,
        loadingTambahParent: true,
      };
    case TAMBAH_PARENT_SUCCESS:
      return {
        ...state,
        loadingTambahParent: false,
      };
    case TAMBAH_PARENT_FAILED:
      return {
        ...state,
        loadingTambahParent: false,
      };
    case TAMBAH_MENU:
      return {
        ...state,
        loadingTambahMenu: true,
      };
    case TAMBAH_MENU_SUCCESS:
      return {
        ...state,
        loadingTambahMenu: false,
      };
    case TAMBAH_MENU_FAILED:
      return {
        ...state,
        loadingTambahMenu: false,
      };
    case TAMBAH_FITUR:
      return {
        ...state,
        loadingTambahFitur: true,
      };
    case TAMBAH_FITUR_SUCCESS:
      return {
        ...state,
        loadingTambahFitur: false,
      };
    case TAMBAH_FITUR_FAILED:
      return {
        ...state,
        loadingTambahFitur: false,
      };
    case HAPUS_PENGGUNA:
      return {
        ...state,
        loadingHapusPengguna: true,
      };
    case HAPUS_PENGGUNA_SUCCESS:
      return {
        ...state,
        pengguna: state.pengguna.filter((item) => item.id_pegawai !== action.payload),
        loadingHapusPengguna: false,
      };
    case HAPUS_PENGGUNA_FAILED:
      return {
        ...state,
        loadingHapusPengguna: false,
      };
    case HAPUS_JABATAN:
      return {
        ...state,
        loadingHapusJabatan: true,
      };
    case HAPUS_JABATAN_SUCCESS:
      return {
        ...state,
        jabatan: state.jabatan.filter((item) => item.id_role !== action.payload),
        loadingHapusJabatan: false,
      };
    case HAPUS_JABATAN_FAILED:
      return {
        ...state,
        loadingHapusJabatan: false,
      };
    case HAPUS_PARENT:
      return {
        ...state,
        loadingHapusParent: true,
      };
    case HAPUS_PARENT_SUCCESS:
      return {
        ...state,
        parent: state.parent.filter((item) => item.parent_code !== action.payload),
        loadingHapusParent: false,
      };
    case HAPUS_PARENT_FAILED:
      return {
        ...state,
        loadingHapusParent: false,
      };
    case HAPUS_MENU:
      return {
        ...state,
        loadingHapusMenu: true,
      };
    case HAPUS_MENU_SUCCESS:
      return {
        ...state,
        menu: state.menu.filter((item) => item.code_menu !== action.payload),
        loadingHapusMenu: false,
      };
    case HAPUS_MENU_FAILED:
      return {
        ...state,
        loadingHapusMenu: false,
      };
    case HAPUS_FITUR:
      return {
        ...state,
        loadingHapusFitur: true,
      };
    case HAPUS_FITUR_SUCCESS:
      return {
        ...state,
        fitur: state.fitur.filter((item) => item.code_fitur !== action.payload),
        loadingHapusFitur: false,
      };
    case HAPUS_FITUR_FAILED:
      return {
        ...state,
        loadingHapusFitur: false,
      };
    case DETAIL_PENGGUNA:
      return {
        ...state,
        loadingDetailPengguna: true,
        detailPengguna: initialState.detailPengguna,
        forbiddenDetailPengguna: initialState.forbiddenDetailPengguna,
      };
    case DETAIL_PENGGUNA_SUCCESS:
      return {
        ...state,
        loadingDetailPengguna: false,
        detailPengguna: action.payload,
      };
    case DETAIL_PENGGUNA_FAILED:
      return {
        ...state,
        loadingDetailPengguna: false,
        forbiddenDetailPengguna: action.payload === 403,
      };
    case DETAIL_JABATAN:
      return {
        ...state,
        loadingDetailJabatan: true,
        detailJabatan: initialState.detailJabatan,
        forbiddenDetailJabatan: initialState.forbiddenDetailJabatan,
      };
    case DETAIL_JABATAN_SUCCESS:
      return {
        ...state,
        loadingDetailJabatan: false,
        detailJabatan: action.payload,
      };
    case DETAIL_JABATAN_FAILED:
      return {
        ...state,
        loadingDetailJabatan: false,
        forbiddenDetailJabatan: action.payload === 403,
      };
    case DETAIL_PARENT:
      return {
        ...state,
        loadingDetailParent: true,
        detailParent: initialState.detailParent,
        forbiddenDetailParent: initialState.forbiddenDetailParent,
      };
    case DETAIL_PARENT_SUCCESS:
      return {
        ...state,
        loadingDetailParent: false,
        detailParent: action.payload,
      };
    case DETAIL_PARENT_FAILED:
      return {
        ...state,
        loadingDetailParent: false,
        forbiddenDetailParent: action.payload === 403,
      };
    case DETAIL_MENU:
      return {
        ...state,
        loadingDetailMenu: true,
        detailMenu: initialState.detailMenu,
        forbiddenDetailMenu: initialState.forbiddenDetailMenu,
      };
    case DETAIL_MENU_SUCCESS:
      return {
        ...state,
        loadingDetailMenu: false,
        detailMenu: action.payload,
      };
    case DETAIL_MENU_FAILED:
      return {
        ...state,
        loadingDetailMenu: false,
        forbiddenDetailMenu: action.payload === 403,
      };
    case DETAIL_FITUR:
      return {
        ...state,
        loadingDetailFitur: true,
        detailFitur: initialState.detailFitur,
        forbiddenDetailFitur: initialState.forbiddenDetailFitur,
      };
    case DETAIL_FITUR_SUCCESS:
      return {
        ...state,
        loadingDetailFitur: false,
        detailFitur: action.payload,
      };
    case DETAIL_FITUR_FAILED:
      return {
        ...state,
        loadingDetailFitur: false,
        forbiddenDetailFitur: action.payload === 403,
      };
    case SET_FILTER_JABATAN:
      return {
        ...state,
        jabatanFilter: action.payload,
      };
    case RESET_FILTER_JABATAN:
      return {
        ...state,
        jabatanFilter: initialState.jabatanFilter,
      };
  }
};
