import { Layout, Form } from "antd";
import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import FallbackLoader from "../components/FallbackLoader";
import HeaderNav from "../components/HeaderNav";
import FormPage from "../components/FormPage";
const SideNav = lazy(() => import("../components/SideNav"));
const Dashboard = lazy(() => import("../containers/Dashboard"));
const SubMenu1 = lazy(() => import("../containers/Menu1/SubMenu1"));
const SubMenu2 = lazy(() => import("../containers/Menu1/SubMenu2"));
const Pelanggan = lazy(() => import("../containers/Pelanggan"));
const Setting = lazy(() => import("../containers/Setting"));
const Produk = lazy(() => import("../containers/Produk"));
const Deposit = lazy(() => import("../containers/Deposit"));
const Publish = lazy(() => import("../containers/Publish"));
const Pengguna = lazy(() => import("../containers/Pengguna"));
// import Integrasi from "../containers/Integrasi";

const { Content } = Layout;

const MainRouter = (props) => {
  const [formProduk] = Form.useForm();

  return (
    <>
      {props.formTemplate === true ? (
        <FormPage />
      ) : (
        <>
          <HeaderNav />
          <Layout>
            <Suspense fallback={<FallbackLoader />}>
              <SideNav />
            </Suspense>
            {/* <SideNav /> */}
            <Content>
              <div className="wrapContainer">
                <Suspense fallback={<FallbackLoader />}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/produk/*"
                      element={<Produk form={formProduk} />}
                    />
                    <Route path="/deposit/*" element={<Deposit />} />
                    <Route path="/publish/*" element={<Publish />} />
                    <Route path="/pelanggan/*" element={<Pelanggan />} />
                    <Route path="/setting/*" element={<Setting />} />
                    <Route path="/pengguna/*" element={<Pengguna />} />
                    {/* <Route path="/integrasi/*" element={<Integrasi />} /> */}
                    <Route path="menu1/submenu1" element={<SubMenu1 />} exact />
                    <Route path="menu1/submenu2" element={<SubMenu2 />} exact />
                  </Routes>
                </Suspense>
              </div>
            </Content>
          </Layout>
          {/* <FooterNav /> */}
        </>
      )}
    </>
  );
};

export default MainRouter;
