import { Menu, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { execSignOut } from "./actions";
import { useEffect } from "react";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const loading = useSelector((state) => state.global.isLoadingSignOut);
  const isAuthenticate = useSelector((state) => state.global.isAuthenticate);

  useEffect(() => {
    if (loading === false && isAuthenticate === false) {
      console.log("kesini");
      localStorage.removeItem("key");
      localStorage.removeItem("token");
      localStorage.setItem("isAuth", "0");
      navigate("/signin");
    }
  }, [dispatch, loading, isAuthenticate, navigate]);

  const showConfirm = () => {
    console.log("Call Modal");
    confirm({
      title: "Yakin ingin Log Out ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Log Out",
      cancelText: "Tidak",
      async onOk() {
        dispatch(execSignOut());
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Menu.Item key="sign_out" onClick={showConfirm}>
      Sign Out
    </Menu.Item>
  );
};

export default SignOut;
