export const GET_LIST_KABUPATEN_SUCCESS = "GET_LIST_KABUPATEN_SUCCESS";
export const GET_LIST_KABUPATEN_FAILED = "GET_LIST_KABUPATEN_FAILED";
export const GET_LIST_KECAMATAN_SUCCESS = "GET_LIST_KECAMATAN_SUCCESS";
export const GET_LIST_KECAMATAN_FAILED = "GET_LIST_KECAMATAN_FAILED";
export const GET_DETAIL_PELANGGAN = "GET_DETAIL_PELANGGAN";
export const GET_DETAIL_PELANGGAN_SUCCESS = "GET_DETAIL_PELANGGAN_SUCCESS";
export const GET_DETAIL_PELANGGAN_FAILED = "GET_DETAIL_PELANGGAN_FAILED";
export const UPDATE_DATA_PELANGGAN = "UPDATE_DATA_PELANGGAN";
export const UPDATE_DATA_PELANGGAN_SUCCESS = "UPDATE_DATA_PELANGGAN_SUCCESS";
export const UPDATE_DATA_PELANGGAN_FAILED = "UPDATE_DATA_PELANGGAN_FAILED";
export const GET_TRANSAKSI_PRODUK_PELANGGAN = "GET_TRANSAKSI_PRODUK_PELANGGAN";
export const GET_TRANSAKSI_PRODUK_PELANGGAN_SUCCESS =
  "GET_TRANSAKSI_PRODUK_PELANGGAN_SUCCESS";
export const GET_TRANSAKSI_PRODUK_PELANGGAN_FAILED =
  "GET_TRANSAKSI_PRODUK_PELANGGAN_FAILED";
export const GET_TRANSAKSI_DEPOSIT_PELANGGAN =
  "GET_TRANSAKSI_DEPOSIT_PELANGGAN";
export const GET_TRANSAKSI_DEPOSIT_PELANGGAN_SUCCESS =
  "GET_TRANSAKSI_DEPOSIT_PELANGGAN_SUCCESS";
export const GET_TRANSAKSI_DEPOSIT_PELANGGAN_FAILED =
  "GET_TRANSAKSI_DEPOSIT_PELANGGAN_FAILED";
export const GET_TRANSAKSI_PENGECEKAN_PELANGGAN =
  "GET_TRANSAKSI_PENGECEKAN_PELANGGAN";
export const GET_TRANSAKSI_PENGECEKAN_PELANGGAN_SUCCESS =
  "GET_TRANSAKSI_PENGECEKAN_PELANGGAN_SUCCESS";
export const GET_TRANSAKSI_PENGECEKAN_PELANGGAN_FAILED =
  "GET_TRANSAKSI_PENGECEKAN_PELANGGAN_FAILED";
export const GET_MUTASI_SALDO = "GET_MUTASI_SALDO";
export const GET_MUTASI_SALDO_SUCCESS = "GET_MUTASI_SALDO_SUCCESS";
export const GET_MUTASI_SALDO_FAILED = "GET_MUTASI_SALDO_FAILED";
export const GET_LOG_UPDATE_PHONE = "GET_LOG_UPDATE_PHONE";
export const GET_LOG_UPDATE_PHONE_SUCCESS = "GET_LOG_UPDATE_PHONE_SUCCESS";
export const GET_LOG_UPDATE_PHONE_FAILED = "GET_LOG_UPDATE_PHONE_FAILED";
export const GET_TOTAL_TRANSAKSI_PRODUK_PELANGGAN_SUCCESS =
  "GET_TOTAL_TRANSAKSI_PRODUK_PELANGGAN_SUCCESS";
export const GET_TOTAL_TRANSAKSI_PRODUK_PELANGGAN_FAILED =
  "GET_TOTAL_TRANSAKSI_PRODUK_PELANGGAN_FAILED";
export const GET_TOTAL_TRANSAKSI_DEPOSIT_PELANGGAN_SUCCESS =
  "GET_TOTAL_TRANSAKSI_DEPOSIT_PELANGGAN_SUCCESS";
export const GET_TOTAL_TRANSAKSI_DEPOSIT_PELANGGAN_FAILED =
  "GET_TOTAL_TRANSAKSI_DEPOSIT_PELANGGAN_FAILED";
export const GET_TOTAL_TRANSAKSI_PENGECEKAN_PELANGGAN_SUCCESS =
  "GET_TOTAL_TRANSAKSI_PENGECEKAN_PELANGGAN_SUCCESS";
export const GET_TOTAL_TRANSAKSI_PENGECEKAN_PELANGGAN_FAILED =
  "GET_TOTAL_TRANSAKSI_PENGECEKAN_PELANGGAN_FAILED";
export const GET_TOTAL_MUTASI_SALDO_SUCCESS = "GET_TOTAL_MUTASI_SALDO_SUCCESS";
export const GET_TOTAL_MUTASI_SALDO_FAILED = "GET_TOTAL_MUTASI_SALDO_FAILED";
export const GET_TOTAL_LOG_UPDATE_PHONE_SUCCESS = "GET_TOTAL_LOG_UPDATE_PHONE_SUCCESS";
export const GET_TOTAL_LOG_UPDATE_PHONE_FAILED = "GET_TOTAL_LOG_UPDATE_PHONE_FAILED";
export const GET_LIST_PERANGKAT = "GET_LIST_PERANGKAT";
export const GET_LIST_PERANGKAT_SUCCESS = "GET_LIST_PERANGKAT_SUCCESS";
export const GET_LIST_PERANGKAT_FAILED = "GET_LIST_PERANGKAT_FAILED";
export const DELETE_PERANGKAT_SUCCESS = "DELETE_PERANGKAT_SUCCESS";
export const DELETE_PERANGKAT_FAILED = "DELETE_PERANGKAT_FAILED";
export const LOGOUT_PERANGKAT_SUCCESS = "LOGOUT_PERANGKAT_SUCCESS";
export const LOGOUT_PERANGKAT_FAILED = "LOGOUT_PERANGKAT_FAILED";
export const KONFIRMASI_PERANGKAT_SUCCESS = "KONFIRMASI_PERANGKAT_SUCCESS";
export const KONFIRMASI_PERANGKAT_FAILED = "KONFIRMASI_PERANGKAT_FAILED";
export const GET_LIST_KEAMANAN = "GET_LIST_KEAMANAN";
export const GET_LIST_KEAMANAN_SUCCESS = "GET_LIST_KEAMANAN_SUCCESS";
export const GET_LIST_KEAMANAN_FAILED = "GET_LIST_KEAMANAN_FAILED";
export const DETAIL_OTP_PELANGGAN_SUCCESS = "DETAIL_OTP_PELANGGAN_SUCCESS";
export const DETAIL_OTP_PELANGGAN_FAILED = "DETAIL_OTP_PELANGGAN_FAILED";
export const SET_TIME_OTP_SUCCESS = "SET_TIME_OTP_SUCCESS";
export const SET_TIME_OTP_FAILED = "SET_TIME_OTP_FAILED";
export const RESET_OTP_SUCCESS = "RESET_OTP_SUCCESS";
export const RESET_OTP_FAILED = "RESET_OTP_FAILED";
export const RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS";
export const RESET_PIN_FAILED = "RESET_PIN_FAILED";
