export const GET_PENGGUNA = "GET_PENGGUNA";
export const GET_PENGGUNA_SUCCESS = "GET_PENGGUNA_SUCCESS";
export const GET_PENGGUNA_FAILED = "GET_PENGGUNA_FAILED";
export const HAPUS_PENGGUNA = "HAPUS_PENGGUNA";
export const HAPUS_PENGGUNA_SUCCESS = "HAPUS_PENGGUNA_SUCCESS";
export const HAPUS_PENGGUNA_FAILED = "HAPUS_PENGGUNA_FAILED";
export const TAMBAH_PENGGUNA = "TAMBAH_PENGGUNA";
export const TAMBAH_PENGGUNA_SUCCESS = "TAMBAH_PENGGUNA_SUCCESS";
export const TAMBAH_PENGGUNA_FAILED = "TAMBAH_PENGGUNA_FAILED";
export const DETAIL_PENGGUNA = "DETAIL_PENGGUNA";
export const DETAIL_PENGGUNA_SUCCESS = "DETAIL_PENGGUNA_SUCCESS";
export const DETAIL_PENGGUNA_FAILED = "DETAIL_PENGGUNA_FAILED";
export const GET_JABATAN = "GET_JABATAN";
export const GET_JABATAN_SUCCESS = "GET_JABATAN_SUCCESS";
export const GET_JABATAN_FAILED = "GET_JABATAN_FAILED";
export const GET_SIMPLE_JABATAN = "GET_SIMPLE_JABATAN";
export const GET_SIMPLE_JABATAN_SUCCESS = "GET_SIMPLE_JABATAN_SUCCESS";
export const GET_SIMPLE_JABATAN_FAILED = "GET_SIMPLE_JABATAN_FAILED";
export const GET_TOTAL_JABATAN = "GET_TOTAL_JABATAN";
export const GET_TOTAL_JABATAN_SUCCESS = "GET_TOTAL_JABATAN_SUCCESS";
export const GET_TOTAL_JABATAN_FAILED = "GET_TOTAL_JABATAN_FAILED";
export const TAMBAH_JABATAN = "TAMBAH_JABATAN";
export const TAMBAH_JABATAN_SUCCESS = "TAMBAH_JABATAN_SUCCESS";
export const TAMBAH_JABATAN_FAILED = "TAMBAH_JABATAN_FAILED";
export const DETAIL_JABATAN = "DETAIL_JABATAN";
export const DETAIL_JABATAN_SUCCESS = "DETAIL_JABATAN_SUCCESS";
export const DETAIL_JABATAN_FAILED = "DETAIL_JABATAN_FAILED";
export const HAPUS_JABATAN = "HAPUS_JABATAN";
export const HAPUS_JABATAN_SUCCESS = "HAPUS_JABATAN_SUCCESS";
export const HAPUS_JABATAN_FAILED = "HAPUS_JABATAN_FAILED";
export const GET_PARENT = "GET_PARENT";
export const GET_PARENT_SUCCESS = "GET_PARENT_SUCCESS";
export const GET_PARENT_FAILED = "GET_PARENT_FAILED";
export const TAMBAH_PARENT = "TAMBAH_PARENT";
export const TAMBAH_PARENT_SUCCESS = "TAMBAH_PARENT_SUCCESS";
export const TAMBAH_PARENT_FAILED = "TAMBAH_PARENT_FAILED";
export const DETAIL_PARENT = "DETAIL_PARENT";
export const DETAIL_PARENT_SUCCESS = "DETAIL_PARENT_SUCCESS";
export const DETAIL_PARENT_FAILED = "DETAIL_PARENT_FAILED";
export const HAPUS_PARENT = "HAPUS_PARENT";
export const HAPUS_PARENT_SUCCESS = "HAPUS_PARENT_SUCCESS";
export const HAPUS_PARENT_FAILED = "HAPUS_PARENT_FAILED";
export const GET_MENU = "GET_MENU";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAILED = "GET_MENU_FAILED";
export const TAMBAH_MENU = "TAMBAH_MENU";
export const TAMBAH_MENU_SUCCESS = "TAMBAH_MENU_SUCCESS";
export const TAMBAH_MENU_FAILED = "TAMBAH_MENU_FAILED";
export const DETAIL_MENU = "DETAIL_MENU";
export const DETAIL_MENU_SUCCESS = "DETAIL_MENU_SUCCESS";
export const DETAIL_MENU_FAILED = "DETAIL_MENU_FAILED";
export const HAPUS_MENU = "HAPUS_MENU";
export const HAPUS_MENU_SUCCESS = "HAPUS_MENU_SUCCESS";
export const HAPUS_MENU_FAILED = "HAPUS_MENU_FAILED";
export const GET_FITUR = "GET_FITUR";
export const GET_FITUR_SUCCESS = "GET_FITUR_SUCCESS";
export const GET_FITUR_FAILED = "GET_FITUR_FAILED";
export const TAMBAH_FITUR = "TAMBAH_FITUR";
export const TAMBAH_FITUR_SUCCESS = "TAMBAH_FITUR_SUCCESS";
export const TAMBAH_FITUR_FAILED = "TAMBAH_FITUR_FAILED";
export const DETAIL_FITUR = "DETAIL_FITUR";
export const DETAIL_FITUR_SUCCESS = "DETAIL_FITUR_SUCCESS";
export const DETAIL_FITUR_FAILED = "DETAIL_FITUR_FAILED";
export const HAPUS_FITUR = "HAPUS_FITUR";
export const HAPUS_FITUR_SUCCESS = "HAPUS_FITUR_SUCCESS";
export const HAPUS_FITUR_FAILED = "HAPUS_FITUR_FAILED";
export const GET_NESTED_FITUR = "GET_NESTED_FITUR";
export const GET_NESTED_FITUR_SUCCESS = "GET_NESTED_FITUR_SUCCESS";
export const GET_NESTED_FITUR_FAILED = "GET_NESTED_FITUR_FAILED";
export const SET_FILTER_JABATAN = "SET_FILTER_JABATAN";
export const RESET_FILTER_JABATAN = "RESET_FILTER_JABATAN";
