import {
  GET_TRANSAKSI_PRODUK_SUCCESS,
  GET_TRANSAKSI_PRODUK_FAILED,
  GET_TRANSAKSI_DEPOSIT_SUCCESS,
  GET_TRANSAKSI_DEPOSIT_FAILED,
  GET_TRANSAKSI_PENGECEKAN_SUCCESS,
  GET_TRANSAKSI_PENGECEKAN_FAILED,
  GET_TOTAL_TRANSAKSI_PRODUK_SUCCESS,
  GET_TOTAL_TRANSAKSI_PRODUK_FAILED,
  GET_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS,
  GET_TOTAL_TRANSAKSI_DEPOSIT_FAILED,
  GET_TOTAL_TRANSAKSI_PENGECEKAN_SUCCESS,
  GET_TOTAL_TRANSAKSI_PENGECEKAN_FAILED,
  GET_TRANSAKSI_PRODUK,
  GET_TRANSAKSI_DEPOSIT,
  GET_TRANSAKSI_PENGECEKAN,
  GET_DETAIL_TRANSAKSI_PRODUK,
  GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS,
  GET_DETAIL_TRANSAKSI_PRODUK_FAILED,
  GET_DETAIL_TRANSAKSI_DEPOSIT,
  GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS,
  GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED,
  GET_DETAIL_TRANSAKSI_PENGECEKAN,
  GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS,
  GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED,
  GET_LIST_FEEDBACK,
  GET_LIST_FEEDBACK_SUCCESS,
  GET_LIST_FEEDBACK_FAILED,
  GET_DETAIL_FEEDBACK,
  GET_DETAIL_FEEDBACK_SUCCESS,
  GET_DETAIL_FEEDBACK_FAILED,
  GET_LIST_KATEGORI_FEEDBACK_SUCCESS,
  GET_LIST_KATEGORI_FEEDBACK_FAILED,
  GET_METODE_DEPOSIT_SUCCESS,
  GET_METODE_DEPOSIT_FAILED,
  GET_STATUS_NOBU_SUCCESS,
  GET_MUTASI_SALAH_TRANSFER_SUCCESS,
  GET_MUTASI_SALAH_TRANSFER_FAILED,
  GET_MUTASI_SALAH_TRANSFER,
  SUKSESKAN_SALAH_TRANSFER,
  SUKSESKAN_SALAH_TRANSFER_SUCCESS,
  SUKSESKAN_SALAH_TRANSFER_FAILED,
  INIT_SALAH_TRANSFER,
  SET_MUTASI_SALAH_TRANSFER,
  GET_STATUS_NOBU_FAILED,
  SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS,
  BACKLOG_FEEDBACK_SUCCESS,
  CANCEL_BACKLOG_FEEDBACK_SUCCESS,
  GET_SUPPLIER_REPLY,
  GET_SUPPLIER_REPLY_SUCCESS,
  GET_SUPPLIER_REPLY_FAILED,
  GET_TOTAL_SUPPLIER_REPLY_SUCCESS,
  GET_TOTAL_SUPPLIER_REPLY_FAILED,
  GET_CREATE_TRANSACTION_REPLY,
  GET_CREATE_TRANSACTION_REPLY_SUCCESS,
  GET_CREATE_TRANSACTION_REPLY_FAILED,
  GET_TOTAL_CREATE_TRANSACTION_REPLY_SUCCESS,
  GET_TOTAL_CREATE_TRANSACTION_REPLY_FAILED,
  SET_FILTER_SUPPLIER_REPLY,
  RESET_FILTER_SUPPLIER_REPLY,
  GET_PELANGGAN,
  GET_PELANGGAN_SUCCESS,
  GET_PELANGGAN_FAILED,
  GET_TOTAL_PELANGGAN_SUCCESS,
  GET_TOTAL_PELANGGAN_FAILED,
  GET_PELANGGAN_BLOKIR,
  GET_PELANGGAN_BLOKIR_SUCCESS,
  GET_PELANGGAN_BLOKIR_FAILED,
  GET_TOTAL_PELANGGAN_BLOKIR_SUCCESS,
  GET_TOTAL_PELANGGAN_BLOKIR_FAILED,
  GET_PELANGGAN_VERIFIKASI_SUCCESS,
  GET_PELANGGAN_VERIFIKASI_FAILED,
  BANNED_PELANGGAN_SUCCESS,
  OPEN_BANNED_PELANGGAN_SUCCESS,
  DELETE_PELANGGAN_SUCCESS,
  GET_LIST_BLACKLIST,
  GET_LIST_BLACKLIST_SUCCESS,
  GET_LIST_BLACKLIST_FAILED,
  OPEN_BLACKLIST_SUCCESS,
  UPDATE_BLACKLIST_SUCCESS,
} from "./constants";

const initialState = {
  loadingPelanggan: true,
  loadingPelangganBlokir: true,
  loadingPelangganVerifikasi: true,
  pelanggan: [],
  pelangganBlokir: [],
  pelangganVerifikasi: [],
  idPelanggan: 0,
  idPelangganBlokir: 0,
  idPelangganDelete: 0,
  totalPelanggan: 0,
  totalPelangganBlokir: 0,
  totalPelangganVerifikasi: 0,
  loadingTransaksiProduk: true,
  loadingTransaksiDeposit: true,
  loadingTransaksiPengecekan: true,
  loadingSupplierReply: true,
  loadingCreateTransactionReply: true,
  loadingFeedback: true,
  loadingCheckMutasiSalahTransfer: null,
  statusSalahTransfer: "",
  transaksiProduk: [],
  transaksiDeposit: [],
  metodeDeposit: [],
  transaksiPengecekan: [],
  supplierReply: [],
  supplierReplyFilter: null,
  createTransactionReply: [],
  totalTransaksiProduk: 0,
  totalTransaksiDeposit: 0,
  totalTransaksiPengecekan: 0,
  totalSupplierReply: 0,
  totalCreateTransactionReply: 0,
  feedback: [],
  totalFeedback: 0,
  infoDataPelanggan: {},
  infoDataProduk: {},
  infoDataDeposit: {},
  infoDataPengecekan: {},
  infoReplyNobu: null,
  infoCheckMutasiSalahTransfer: null,
  infoSalahTransfer: null,
  mutasiSalahTransfer: null,
  actionTransaksi: {},
  failedEditReferensi: true,
  failedEditTotal: true,
  infoDataFeedback: {},
  kategoriFeedback: [],
  statusBacklog: 0,
  blacklist: [],
  totalBlacklist: 0,
  loadingBlacklist: true,

  forbiddenPelanggan: false,
  forbiddenPelangganBlokir: false,
  forbiddenTransaksiProduk: false,
  forbiddenTransaksiDeposit: false,
  forbiddenTransaksiPengecekan: false,
  forbiddenSupplierReply: false,
  forbiddenCreateTransactionReply: false,
  forbiddenFeedback: false,
  forbiddenBlacklist: false,
  forbiddenInfoDataProduk: false,
  forbiddenInfoDataDeposit: false,
  forbiddenInfoDataPengecekan: false,
  forbiddenInfoDataFeedback: false,
};

export const pelangganReducer = (state = initialState, action) => {
  let newFeedback;
  let idxFeedback;
  let newBlacklist;
  let idxBlacklist;
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_PELANGGAN:
      return {
        ...state,
        loadingPelanggan: true,
        forbiddenPelanggan: initialState.forbiddenPelanggan,
      };
    case GET_PELANGGAN_SUCCESS:
      return {
        ...state,
        loadingPelanggan: false,
        pelanggan: action.payload,
      };
    case GET_PELANGGAN_FAILED:
      return {
        ...state,
        loadingPelanggan: false,
        pelanggan: initialState.pelanggan,
        forbiddenPelanggan: action.payload === 403,
      };
    case GET_TOTAL_PELANGGAN_SUCCESS:
      return {
        ...state,
        totalPelanggan: action.payload,
      };
    case GET_TOTAL_PELANGGAN_FAILED:
      return {
        ...state,
        totalPelanggan: initialState.totalPelanggan,
      };
    case BANNED_PELANGGAN_SUCCESS:
      return {
        ...state,
        idPelanggan: action.payload,
      };
    case GET_PELANGGAN_BLOKIR:
      return {
        ...state,
        loadingPelangganBlokir: true,
        forbiddenPelangganBlokir: initialState.forbiddenPelangganBlokir,
      };
    case GET_PELANGGAN_BLOKIR_SUCCESS:
      return {
        ...state,
        loadingPelangganBlokir: false,
        pelangganBlokir: action.payload,
      };
    case GET_PELANGGAN_BLOKIR_FAILED:
      return {
        ...state,
        loadingPelangganBlokir: false,
        pelangganBlokir: initialState.pelangganBlokir,
        forbiddenPelangganBlokir: action.payload === 403,
      };
    case GET_TOTAL_PELANGGAN_BLOKIR_SUCCESS:
      return {
        ...state,
        totalPelangganBlokir: action.payload,
      };
    case GET_TOTAL_PELANGGAN_BLOKIR_FAILED:
      return {
        ...state,
        totalPelangganBlokir: initialState.totalPelangganBlokir,
      };
    case GET_PELANGGAN_VERIFIKASI_SUCCESS:
      return {
        ...state,
        loadingPelangganVerifikasi: false,
        pelangganVerifikasi: action.payload,
        totalPelangganVerifikasi: action.payload.length,
      };
    case GET_PELANGGAN_VERIFIKASI_FAILED:
      return {
        ...state,
        loadingPelangganVerifikasi: false,
        pelangganVerifikasi: initialState.pelangganVerifikasi,
        totalPelangganVerifikasi: initialState.totalPelangganVerifikasi,
      };
    case OPEN_BANNED_PELANGGAN_SUCCESS:
      return {
        ...state,
        idPelangganBlokir: action.payload,
      };
    case DELETE_PELANGGAN_SUCCESS:
      return {
        ...state,
        idPelangganDelete: action.payload,
      };
    case GET_TRANSAKSI_PRODUK:
      return {
        ...state,
        loadingTransaksiProduk: true,
        forbiddenTransaksiProduk: initialState.forbiddenTransaksiProduk,
      };
    case GET_TRANSAKSI_PRODUK_SUCCESS:
      return {
        ...state,
        loadingTransaksiProduk: false,
        transaksiProduk: action.payload,
      };
    case GET_TRANSAKSI_PRODUK_FAILED:
      return {
        ...state,
        loadingTransaksiProduk: false,
        transaksiProduk: initialState.transaksiProduk,
        forbiddenTransaksiProduk: action.payload === 403,
      };
    case GET_TOTAL_TRANSAKSI_PRODUK_SUCCESS:
      return {
        ...state,
        totalTransaksiProduk: action.payload,
      };
    case GET_TOTAL_TRANSAKSI_PRODUK_FAILED:
      return {
        ...state,
        totalTransaksiProduk: initialState.totalTransaksiProduk,
      };
    case GET_DETAIL_TRANSAKSI_PRODUK:
      return {
        ...state,
        forbiddenInfoDataProduk: initialState.forbiddenInfoDataProduk,
      };
    case GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS:
      return {
        ...state,
        infoDataPelanggan: action.payload.pelanggan,
        infoDataProduk: action.payload.produk,
        actionTransaksi: action.payload.action,
      };
    case GET_DETAIL_TRANSAKSI_PRODUK_FAILED:
      return {
        ...state,
        infoDataPelanggan: {},
        infoDataProduk: {},
        actionTransaksi: 0,
        forbiddenInfoDataProduk: action.payload === 403,
      };
    case GET_TRANSAKSI_DEPOSIT:
      return {
        ...state,
        loadingTransaksiDeposit: true,
        forbiddenTransaksiDeposit: initialState.forbiddenTransaksiDeposit,
      };
    case GET_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        loadingTransaksiDeposit: false,
        transaksiDeposit: action.payload,
      };
    case GET_TRANSAKSI_DEPOSIT_FAILED:
      return {
        ...state,
        loadingTransaksiDeposit: false,
        transaksiDeposit: initialState.transaksiDeposit,
        forbiddenTransaksiDeposit: action.payload === 403,
      };
    case GET_METODE_DEPOSIT_SUCCESS:
      return {
        ...state,
        metodeDeposit: action.payload,
      };
    case GET_METODE_DEPOSIT_FAILED:
      return {
        ...state,
        metodeDeposit: initialState.transaksiDeposit,
      };
    case GET_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        totalTransaksiDeposit: action.payload,
      };
    case GET_TOTAL_TRANSAKSI_DEPOSIT_FAILED:
      return {
        ...state,
        totalTransaksiDeposit: initialState.totalTransaksiDeposit,
      };
    case GET_DETAIL_TRANSAKSI_DEPOSIT:
      return {
        ...state,
        infoDataDeposit: initialState.infoDataDeposit,
        forbiddenInfoDataDeposit: initialState.forbiddenInfoDataDeposit,
      };
    case GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        infoDataPelanggan: action.payload.pelanggan,
        infoDataDeposit: action.payload.produk,
        actionTransaksi: action.payload.action,
      };
    case GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED:
      return {
        ...state,
        infoDataPelanggan: {},
        infoDataDeposit: {},
        actionTransaksi: 0,
        forbiddenInfoDataDeposit: action.payload === 403,
      };
    case SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        infoReplyNobu: null,
      };
    case GET_TRANSAKSI_PENGECEKAN:
      return {
        ...state,
        loadingTransaksiPengecekan: true,
        forbiddenTransaksiPengecekan: initialState.forbiddenTransaksiPengecekan,
      };
    case GET_TRANSAKSI_PENGECEKAN_SUCCESS:
      return {
        ...state,
        loadingTransaksiPengecekan: false,
        transaksiPengecekan: action.payload,
      };
    case GET_TRANSAKSI_PENGECEKAN_FAILED:
      return {
        ...state,
        loadingTransaksiPengecekan: false,
        transaksiPengecekan: initialState.transaksiPengecekan,
        forbiddenTransaksiPengecekan: action.payload === 403,
      };
    case GET_TOTAL_TRANSAKSI_PENGECEKAN_SUCCESS:
      return {
        ...state,
        totalTransaksiPengecekan: action.payload,
      };
    case GET_TOTAL_TRANSAKSI_PENGECEKAN_FAILED:
      return {
        ...state,
        totalTransaksiPengecekan: initialState.totalTransaksiPengecekan,
      };
    case GET_DETAIL_TRANSAKSI_PENGECEKAN:
      return {
        ...state,
        forbiddenInfoDataPengecekan: initialState.forbiddenInfoDataPengecekan,
      };
    case GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS:
      return {
        ...state,
        infoDataPelanggan: action.payload.pelanggan,
        infoDataPengecekan: action.payload.produk,
      };
    case GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED:
      return {
        ...state,
        infoDataPelanggan: {},
        infoDataPengecekan: {},
        forbiddenInfoDataPengecekan: action.payload === 403,
      };
    case GET_LIST_FEEDBACK:
      return {
        ...state,
        loadingFeedback: true,
        forbiddenFeedback: initialState.forbiddenFeedback,
      };
    case GET_LIST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingFeedback: false,
        feedback: action.payload.data,
        totalFeedback: action.payload.total,
      };
    case GET_LIST_FEEDBACK_FAILED:
      return {
        ...state,
        loadingFeedback: false,
        feedback: initialState.feedback,
        totalFeedback: initialState.totalFeedback,
        forbiddenFeedback: action.payload === 403,
      };
    case GET_DETAIL_FEEDBACK:
      return {
        ...state,
        forbiddenInfoDataFeedback: initialState.forbiddenInfoDataFeedback,
      };
    case GET_DETAIL_FEEDBACK_SUCCESS:
      return {
        ...state,
        infoDataFeedback: action.payload,
        statusBacklog: action.payload.backlog,
      };
    case GET_DETAIL_FEEDBACK_FAILED:
      return {
        ...state,
        infoDataFeedback: {},
        statusBacklog: 0,
        forbiddenInfoDataFeedback: action.payload === 403,
      };
    case BACKLOG_FEEDBACK_SUCCESS:
      newFeedback = [...state.feedback];
      idxFeedback = newFeedback.findIndex((x) => x.id === action.payload);
      newFeedback[idxFeedback].backlog = 1;
      return {
        ...state,
        feedback: newFeedback,
      };
    case CANCEL_BACKLOG_FEEDBACK_SUCCESS:
      newFeedback = [...state.feedback];
      idxFeedback = newFeedback.findIndex((x) => x.id === action.payload);
      newFeedback[idxFeedback].backlog = 0;
      return {
        ...state,
        feedback: newFeedback,
      };
    case GET_LIST_KATEGORI_FEEDBACK_SUCCESS:
      return {
        ...state,
        kategoriFeedback: action.payload,
      };
    case GET_LIST_KATEGORI_FEEDBACK_FAILED:
      return {
        ...state,
        kategoriFeedback: initialState.kategoriFeedback,
      };
    case GET_STATUS_NOBU_SUCCESS:
      return {
        ...state,
        infoReplyNobu: action.payload.responseDetail.data,
      };
    case INIT_SALAH_TRANSFER:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer:
          initialState.loadingCheckMutasiSalahTransfer,
        infoCheckMutasiSalahTransfer: initialState.infoCheckMutasiSalahTransfer,
        mutasiSalahTransfer : initialState.mutasiSalahTransfer
      };
    case GET_MUTASI_SALAH_TRANSFER:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer: true,
        infoCheckMutasiSalahTransfer: initialState.infoCheckMutasiSalahTransfer,
        mutasiSalahTransfer: initialState.mutasiSalahTransfer,
      };
    case GET_MUTASI_SALAH_TRANSFER_SUCCESS:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer: false,
        infoCheckMutasiSalahTransfer: action.payload,
      };
    case GET_MUTASI_SALAH_TRANSFER_FAILED:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer: false,
        infoCheckMutasiSalahTransfer: initialState.infoCheckMutasiSalahTransfer,
      };
    case SUKSESKAN_SALAH_TRANSFER:
      return {
        ...state,
        statusSalahTransfer: "loading",
        infoSalahTransfer: initialState.infoSalahTransfer,
      };
    case SUKSESKAN_SALAH_TRANSFER_SUCCESS:
      return {
        ...state,
        statusSalahTransfer: "success",
        infoSalahTransfer: action.payload,
      };
    case SUKSESKAN_SALAH_TRANSFER_FAILED:
      return {
        ...state,
        statusSalahTransfer: "failed",
        infoSalahTransfer: action.payload,
      };
    case GET_STATUS_NOBU_FAILED:
      return {
        ...state,
        infoReplyNobu: initialState.infoReplyNobu,
      };

    case GET_SUPPLIER_REPLY:
      return {
        ...state,
        loadingSupplierReply: true,
        forbiddenSupplierReply: initialState.forbiddenSupplierReply,
      };
    case GET_SUPPLIER_REPLY_SUCCESS:
      return {
        ...state,
        loadingSupplierReply: false,
        supplierReply: action.payload,
      };
    case GET_SUPPLIER_REPLY_FAILED:
      return {
        ...state,
        loadingSupplierReply: false,
        supplierReply: initialState.supplierReply,
        forbiddenSupplierReply: action.payload === 403,
      };
    case GET_TOTAL_SUPPLIER_REPLY_SUCCESS:
      return {
        ...state,
        totalSupplierReply: action.payload,
      };
    case GET_TOTAL_SUPPLIER_REPLY_FAILED:
      return {
        ...state,
        totalSupplierReply: initialState.totalSupplierReply,
      };

    case GET_CREATE_TRANSACTION_REPLY:
      return {
        ...state,
        loadingCreateTransactionReply: true,
        forbiddenCreateTransactionReply:
          initialState.forbiddenCreateTransactionReply,
      };
    case GET_CREATE_TRANSACTION_REPLY_SUCCESS:
      return {
        ...state,
        loadingCreateTransactionReply: false,
        createTransactionReply: action.payload,
      };
    case GET_CREATE_TRANSACTION_REPLY_FAILED:
      return {
        ...state,
        loadingCreateTransactionReply: false,
        createTransactionReply: initialState.createTransactionReply,
        forbiddenCreateTransactionReply: action.payload === 403,
      };
    case GET_TOTAL_CREATE_TRANSACTION_REPLY_SUCCESS:
      return {
        ...state,
        totalCreateTransactionReply: action.payload,
      };
    case GET_TOTAL_CREATE_TRANSACTION_REPLY_FAILED:
      return {
        ...state,
        totalCreateTransactionReply: initialState.totalCreateTransactionReply,
      };
    case SET_FILTER_SUPPLIER_REPLY:
      return {
        ...state,
        supplierReplyFilter: action.payload,
      };
    case RESET_FILTER_SUPPLIER_REPLY:
      return {
        ...state,
        supplierReplyFilter: initialState.supplierReplyFilter,
      };
    case GET_LIST_BLACKLIST:
      return {
        ...state,
        loadingBlacklist: true,
        forbiddenBlacklist: initialState.forbiddenBlacklist,
      };
    case GET_LIST_BLACKLIST_SUCCESS:
      return {
        ...state,
        loadingBlacklist: false,
        blacklist: action.payload.data,
        totalBlacklist: action.payload.total,
      };
    case GET_LIST_BLACKLIST_FAILED:
      return {
        ...state,
        loadingBlacklist: false,
        blacklist: initialState.blacklist,
        totalBlacklist: initialState.totalBlacklist,
        forbiddenBlacklist: action.payload === 403,
      };
    case OPEN_BLACKLIST_SUCCESS:
      newBlacklist = [...state.blacklist];
      idxBlacklist = newBlacklist.findIndex(
        (x) => x.nomor === action.payload.number
      );
      newBlacklist[idxBlacklist].status = action.payload.status;
      return {
        ...state,
        blacklist: newBlacklist,
      };
    case UPDATE_BLACKLIST_SUCCESS:
      newBlacklist = [...state.blacklist];
      idxBlacklist = newBlacklist.findIndex(
        (x) => x.nomor === action.payload.number
      );
      newBlacklist[idxBlacklist].status = action.payload.status;
      return {
        ...state,
        blacklist: newBlacklist,
      };
    case SET_MUTASI_SALAH_TRANSFER:
      return {
        ...state,
        mutasiSalahTransfer: action.payload,
      };
  }
};
