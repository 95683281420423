import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";

const HeaderProduk = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menu, form, loading } = props;

  const handleBackScreen = async () => {
    await dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    form.resetFields();
    if (menu === "tambahproduk") navigate("/produk/setproduk");
    else if (menu === "detailproduk") navigate("/produk/list");
    else if (menu === "tambah_produk_detail") navigate("/produk/list");
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>
          {menu === "tambahproduk" || menu === "tambah_produk_detail"
            ? "Tambah Produk"
            : menu === "detailproduk"
            ? "Detail Produk"
            : ""}
        </h1>
      </div>
      <div className="rightHeader">
        <Button
          className="btnAct btnActCancel"
          onClick={() => handleBackScreen()}
        >
          Batal
        </Button>
        <Button
          className="btnAct btnActSubmit"
          onClick={() => form.submit()}
          loading={loading}
        >
          {menu === "tambahproduk" ? "Tambah" : "Simpan"}
        </Button>
      </div>
    </>
  );
};
export default HeaderProduk;
