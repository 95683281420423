import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";
import { useState } from "react";
import { gagalkanTransaksiDeposit, getDetailDeposit } from "../../../containers/Pelanggan/actions";

const HeaderSalahTransfer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menu, trx_id, form } = props;
  const { confirm } = Modal;
  const action = useSelector((state) => state.pelanggan.actionTransaksi);
  const loading = useSelector(
    (state) => state.pelanggan.statusSalahTransfer
  );
  const mutasi = useSelector(
    (state) => state.pelanggan.mutasiSalahTransfer
  );
  const [loadingGagalkan, setLoadingGagalkan] = useState(false);

  const handleBackScreen = async () => {
    dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    form.resetFields();
    navigate("/pelanggan/transaksi/deposit");
  };

  const confirmSukseskanDeposit = (id, tipe) => {
    confirm({
      title: "Yakin ingin Sukseskan Deposit ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Sukseskan",
      cancelText: "Tidak",
      async onOk() {
        form.submit()
      },
      onCancel() {
        console.log("Cancel sukseskan");
      },
    });
  };

  const confirmGagalkanDeposit = (id, tipe) => {
    confirm({
      title: "Yakin ingin Gagalkan Deposit ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Gagalkan",
      cancelText: "Tidak",
      async onOk() {
        setLoadingGagalkan(true);
        dispatch(gagalkanTransaksiDeposit(id)).then(() => {
          dispatch(getDetailDeposit(id));
        });
        setLoadingGagalkan(false);
      },
      onCancel() {
        console.log("Cancel gagalkan");
      },
    });
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>
          {menu === "salahtransfer"
            ? "Salah Transfer"
            : ""}
        </h1>
      </div>
      <div className="rightHeader">
        <Button
          disabled={
            action.gagalkan === false
          }
          loading={loadingGagalkan}
          className="btnAct btnActAlert"
          onClick={() => confirmGagalkanDeposit(trx_id, menu)}
        >
          Gagalkan
        </Button>
        <Button
          className="btnAct btnActSubmit"
          onClick={() => confirmSukseskanDeposit(trx_id, menu)}
          loading={loading==="loading"}
          disabled={loading==="loading" || mutasi==null}
        >
          Sukseskan
        </Button>
      </div>
    </>
  );
};

export default HeaderSalahTransfer;
