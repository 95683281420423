import { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Spin } from "antd";
import logo from "../../assets/img/logo-blue.png";

import "../../assets/style/Login.scss";

const SignInForm = lazy(() => import("./SignInForm"));

const SignIn = () => {
  return (
    <Fragment>
      <Helmet>
        <title>KIOSER: Sign in</title>
      </Helmet>
      <div className="login-wrapper">
        <div className="sign_in-form">
          <Suspense
            fallback={
              <div>
                <Spin tip="Loading..." size="large" />
              </div>
            }
          >
            <div className="form-wrapper">
              <div className="logo">
                <img src={logo} alt="logo-kioser" />
              </div>
              <div className="title">
                <h4>Welcome</h4>
                <label>Validasi datamu terlebih dahulu</label>
              </div>
              <SignInForm />
            </div>
          </Suspense>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
