import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import { useEffect, useState } from "react";
import ImageAddLineIcon from "remixicon-react/ImageAddLineIcon";
import FloatInput from "../../components/FloatForm/FloatInput";
import FloatSelect from "../../components/FloatForm/FloatSelect";
import FloatTextArea from "../../components/FloatForm/FloatTextArea";
import KeyValueList from "../../components/FloatForm/KeyValueList";
import "../../assets/style/GridList.scss";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const FormSetOTP = (props) => {
  const { form } = props;

  const [loadingImage, setLoadingImage] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [reset, setReset] = useState(false);
  const [change, setChange] = useState(false);
  const [dataProvider, setDataProvider] = useState([]);
  const [dataMetode, setDataMetode] = useState([]);
  const [dataPrioritas, setDataPrioritas] = useState([]);

  const handleChange = (info) => {
    console.log(change);
    setUploadImage(info.fileList);
    getBase64(info.file.originFileObj, (url) => {
      setLoadingImage(false);
      setImageUrl(url);
    });
  };

  useEffect(() => {
    let arrProvider = [];
    let arrMetode = [];
    let arrPrioritas = [];

    setDataProvider(arrProvider);
    setDataMetode(arrMetode);
    setDataPrioritas(arrPrioritas);

    form.setFieldsValue({
      saldo: 0,
    });
  }, [form]);

  const uploadButton = (
    <div className="btnImage">
      {loadingImage ? <LoadingOutlined /> : <ImageAddLineIcon />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Foto
      </div>
    </div>
  );

  return (
    <Form form={form} key="frmSetOTP">
      <div className="subContent">
        <div className="wrapFormLeft">
          <div className="listForm">
            <div className="itemForm">
              <Form.Item name="image">
                <Upload
                  className="uploadPublish"
                  listType="picture-card"
                  fileList={uploadImage}
                  onChange={handleChange}
                  showUploadList={false}
                  maxCount={1}
                >
                  {imageUrl ? (
                    <img
                      className="previewPublish"
                      src={imageUrl}
                      alt="photo_publish"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <FloatInput
                label="Nama Provider"
                placeholder="Nama Provider"
                name="provider"
                reset={reset}
                setReset={setReset}
              />
              <FloatSelect
                label="Jenis Provider"
                placeholder="Jenis Provider"
                name="jenis_provider"
                items={dataProvider}
                reset={reset}
                setReset={setReset}
                setChange={setChange}
              />
              <FloatSelect
                label="Jenis Metode"
                placeholder="Jenis Metode"
                name="metode"
                items={dataMetode}
                reset={reset}
                setReset={setReset}
                setChange={setChange}
              />
              <FloatInput
                label="Batas Saldo"
                placeholder="Batas Saldo"
                name="saldo"
                prefix="Rp"
                reset={reset}
                setReset={setReset}
                always={true}
              />
              <FloatSelect
                label="Prioritas"
                placeholder="Prioritas"
                name="prioritas"
                items={dataPrioritas}
                reset={reset}
                setReset={setReset}
                setChange={setChange}
              />
            </div>
          </div>
        </div>

        <div className="wrapForm">
          <div className="listForm">
            <div className="itemForm">
              <Form.Item
                name="id"
                style={{ marginBottom: "0px", height: "0px" }}
              >
                <Input type="hidden" disabled />
              </Form.Item>
              <FloatInput
                label="URL Endpoint"
                placeholder="URL Endpoint"
                name="url"
                required
                reset={reset}
                setReset={setReset}
              />
              <h2>Provider</h2>
              <KeyValueList />
              <h2>Header</h2>
              <KeyValueList />
              <h2>Body</h2>
              <KeyValueList />
              <FloatTextArea
                rows="14"
                label="Hasil Pengecekan"
                placeholder="Hasil Pengecekan"
                name="preview"
                reset={reset}
                setReset={setReset}
                readOnly
                always={true}
              />
              <div className="actionForm">
                <Button className="btnActionForm">Kirim</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FormSetOTP;
