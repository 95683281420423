import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MoreFillIcon from "remixicon-react/MoreFillIcon";
import { setHeader } from "../../../containers/Main/actions";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import {
  deleteFitur,
  deleteJabatan,
  deleteMenu,
  deleteParent,
  deletePengguna,
} from "../../../containers/Pengguna/actions";
import QuestionLineIcon from "remixicon-react/QuestionLineIcon";

const HeaderPengguna = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingTambahPengguna = useSelector(
    (state) => state.pengguna.loadingTambahPengguna
  );
  const loadingHapusPengguna = useSelector(
    (state) => state.pengguna.loadingHapusPengguna
  );
  const loadingTambahJabatan = useSelector(
    (state) => state.pengguna.loadingTambahJabatan
  );
  const loadingHapusJabatan = useSelector(
    (state) => state.pengguna.loadingHapusJabatan
  );
  const loadingTambahParent = useSelector(
    (state) => state.pengguna.loadingTambahParent
  );
  const loadingHapusParent = useSelector(
    (state) => state.pengguna.loadingHapusParent
  );
  const loadingTambahMenu = useSelector(
    (state) => state.pengguna.loadingTambahMenu
  );
  const loadingHapusMenu = useSelector(
    (state) => state.pengguna.loadingHapusMenu
  );
  const loadingTambahFitur = useSelector(
    (state) => state.pengguna.loadingTambahFitur
  );
  const loadingHapusFitur = useSelector(
    (state) => state.pengguna.loadingHapusFitur
  );

  const forbiddenPengguna = useSelector(
    (state) => state.pengguna.forbiddenPengguna
  );

  const forbiddenJabatan = useSelector(
    (state) => state.pengguna.forbiddenJabatan
  );

  const forbiddenSimpleJabatan = useSelector(
    (state) => state.pengguna.forbiddenSimpleJabatan
  );

  const forbiddenParent = useSelector(
    (state) => state.pengguna.forbiddenParent
  );

  const forbiddenMenu = useSelector((state) => state.pengguna.forbiddenMenu);

  const forbiddenFitur = useSelector((state) => state.pengguna.forbiddenFitur);

  const forbiddenDetailPengguna = useSelector(
    (state) => state.pengguna.forbiddenDetailPengguna
  );

  const forbiddenDetailJabatan = useSelector(
    (state) => state.pengguna.forbiddenDetailJabatan
  );

  const forbiddenDetailParent = useSelector(
    (state) => state.pengguna.forbiddenDetailParent
  );

  const forbiddenDetailMenu = useSelector(
    (state) => state.pengguna.forbiddenDetailMenu
  );

  const forbiddenDetailFitur = useSelector(
    (state) => state.pengguna.forbiddenDetailFitur
  );

  const prevPage = useSelector((state) => state.global.prevPage);

  const [title, setTitle] = useState("");
  const { menu, form, id } = props;
  const { confirm } = Modal;

  const checkLoading = () => {
    return (
      loadingTambahPengguna ||
      loadingHapusPengguna ||
      loadingTambahJabatan ||
      loadingHapusJabatan ||
      loadingTambahParent ||
      loadingHapusParent ||
      loadingTambahMenu ||
      loadingHapusMenu ||
      loadingTambahFitur ||
      loadingHapusFitur
    );
  };

  const showHeaderButtons = () => {
    return (
      forbiddenPengguna ||
      forbiddenJabatan ||
      forbiddenSimpleJabatan ||
      forbiddenParent ||
      forbiddenMenu ||
      forbiddenFitur ||
      forbiddenDetailPengguna ||
      forbiddenDetailJabatan ||
      forbiddenDetailParent ||
      forbiddenDetailMenu ||
      forbiddenDetailFitur
    );
  };

  const handleBackScreen = async () => {
    dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    switch (menu) {
      case "tambah_parent":
      case "tambah_menu":
      case "tambah_fitur":
      case "detail_parent":
      case "detail_menu":
      case "detail_fitur":
        navigate("/pengguna/list_menu");
        break;

      case "tambah_jabatan":
      case "custom_jabatan":
      case "detail_jabatan":
        if (prevPage.includes("/pengguna/")) {
          if (prevPage.includes("tambah") || prevPage.includes("detail")) {
            const parts = prevPage.split("/")[2];
            dispatch(setHeader(`/pengguna/${parts[2]}`));
            localStorage.setItem("otherTemplate", "true");
          }

          navigate(prevPage);
        } else {
          navigate("/pengguna/jabatan/list_jabatan");
        }
        break;

      case "tambah_pengguna":
      case "detail_pengguna":
        navigate("/pengguna/list_pengguna");
        break;

      default:
        break;
    }
  };

  const deleteHandler = async () => {
    confirm({
      title: `Apakah anda yakin ingin menghapus data ${title}?`,
      icon: <QuestionLineIcon style={{ color: "red" }} />,
      content: "",
      async onOk() {
        switch (menu) {
          case "detail_parent":
            dispatch(deleteParent(id));
            break;
          case "detail_menu":
            dispatch(deleteMenu(id));
            break;
          case "detail_fitur":
            dispatch(deleteFitur(id));
            break;
          case "detail_pengguna":
            dispatch(deletePengguna(id));
            break;
          case "detail_jabatan":
            dispatch(deleteJabatan(id));
            break;

          default:
            break;
        }

        handleBackScreen();
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  useEffect(() => {
    var newTitle = "";
    switch (menu) {
      case "tambah_parent":
      case "detail_parent":
        newTitle = "Parent";
        break;
      case "tambah_menu":
      case "detail_menu":
        newTitle = "Menu";
        break;
      case "tambah_fitur":
      case "detail_fitur":
        newTitle = "Fitur";
        break;
      case "tambah_pengguna":
      case "detail_pengguna":
        newTitle = "Pengguna";
        break;
      case "tambah_jabatan":
      case "custom_jabatan":
      case "detail_jabatan":
        newTitle = "Jabatan";
        break;

      default:
        newTitle = "";
        break;
    }

    setTitle(newTitle);
  }, [menu]);

  const menuAction = () => {
    let menus = [];
    menus.push(
      <Menu.Item
        key="delete"
        className="alertMenu"
        onClick={() => deleteHandler()}
      >
        <DeleteBinLineIcon />
        <span>Hapus {title}</span>
      </Menu.Item>
    );

    return <Menu>{menus}</Menu>;
  };

  const initTitle = () => {
    var text = "";
    if (menu.includes("tambah") || menu.includes("custom")) text = "Tambah";
    else text = "Detail";
    return `${text} ${title}`;
  };

  const initButton = () => {
    var text = "";
    if (menu.includes("tambah") || menu.includes("custom")) text = "Tambah";
    else text = "Simpan";
    return text;
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>{initTitle()}</h1>
      </div>
      <div className="rightHeader">
        {showHeaderButtons() === false && (
          <>
            <Button
              className="btnAct btnActCancel"
              onClick={() => handleBackScreen()}
            >
              Batal
            </Button>
            <Button
              className="btnAct btnActSubmit"
              onClick={() => form.submit()}
              loading={checkLoading()}
            >
              {initButton()}
            </Button>
            {menu.includes("detail") === true && (
              <Dropdown
                overlay={menuAction()}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Button
                  className="btnAct btnMenuAction"
                  loading={checkLoading()}
                >
                  {checkLoading() === false && (
                    <MoreFillIcon className="dropdownIcon" />
                  )}
                </Button>
              </Dropdown>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default HeaderPengguna;
