import { CaretDownOutlined } from "@ant-design/icons";
import { Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

import "../../assets/style/FloatForm.scss";

const FloatSelect = (props) => {
  const [focus, setFocus] = useState(false);
  const [inVal, setInVal] = useState(false);
  const [value, setValue] = useState("");
  let {
    label,
    name,
    items,
    placeholder,
    required,
    disabled,
    container,
    reset,
    setReset,
    setChange,
    data,
    always,
  } = props;

  if (!placeholder) placeholder = label;
  const isChange = (value && value.length !== 0) || inVal;
  const selectClass =
    isChange || always
      ? "modal-select border-active"
      : "modal-select border-not-active";
  const isOccupied = focus || inVal || always;
  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";
  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const handleChange = useCallback(
    (val) => {
      // console.log("handleChange");
      setValue(val);
      setChange(val);
      if (val === "" || val === undefined || val === null) {
        setInVal(false);
      } else {
        if (inVal === false) {
          setInVal(true);
          setReset(false);
        }
      }
    },
    [inVal, setReset, setChange]
  );

  useEffect(() => {
    setValue(data);
    if (reset) {
      setValue("");
      setInVal(false);
    }
  }, [data, reset]);

  useEffect(() => {
    handleChange(value);
  }, [handleChange, value]);

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Form.Item
        name={name}
        rules={
          required
            ? [
                {
                  required: true,
                  message: "Silahkan dipilih " + label + " yang tersedia",
                },
              ]
            : [{ required: false }]
        }
      >
        {container === undefined ? (
          <Select
            className={selectClass}
            onChange={(val) => handleChange(val)}
            suffixIcon={<CaretDownOutlined />}
            disabled={disabled}
            allowClear
            dropdownStyle={{ textAlign: "left" }}
          >
            {items.map((item) => (
              <Select.Option key={item.key} value={item.value}>
                {item.text}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Select
            className={selectClass}
            onChange={(val) => handleChange(val)}
            suffixIcon={<CaretDownOutlined />}
            disabled={disabled}
            allowClear
            dropdownStyle={{ textAlign: "left" }}
            getPopupContainer={() => document.getElementById(container)}
          >
            {items.map((item) => (
              <Select.Option key={item.key} value={item.value}>
                {item.text}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatSelect;
