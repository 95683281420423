import {
  DELETE_PROVIDER_SUCCESS,
  GET_LIST_SET_OTP_FAILED,
  GET_LIST_SET_OTP_SUCCESS,
  SET_STATUS_PROVIDER_SUCCESS,
} from "./constants";

const initialState = {
  loadingSetOTP: true,
  listSetOTP: [],
};

export const integrasiReducer = (state = initialState, action) => {
  let newList;
  let index;
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_LIST_SET_OTP_SUCCESS:
      return {
        ...state,
        loadingSetOTP: false,
        listSetOTP: action.payload,
      };
    case GET_LIST_SET_OTP_FAILED:
      return {
        ...state,
        loadingSetOTP: false,
        listSetOTP: initialState.listSetOTP,
      };
    case DELETE_PROVIDER_SUCCESS:
      newList = [...state.listSetOTP];
      let filterList = newList.filter((x) => x.id !== action.payload.code);
      console.log(filterList);
      return {
        ...state,
        listSetOTP: filterList,
      };
    case SET_STATUS_PROVIDER_SUCCESS:
      newList = [...state.listSetOTP];
      index = newList.findIndex((x) => x.id === action.payload.code);
      newList[index].status = action.payload.status;
      return {
        ...state,
        listSetOTP: newList,
      };
  }
};
