import { notification } from "antd";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import InformationFillIcon from "remixicon-react/InformationFillIcon";

const openNotificationWithIcon = (type, code, message) => {
    notification[type]({
      description: code + " | " + message,
      placement: "bottom",
      closeIcon: <></>,
      maxCount:1,
      icon:
        type === "success" ? (
          <div
            style={{
              background: "#ffffff",
              borderRadius: "50%",
              width: "18px",
              height: "18px",
            }}
          >
            <CheckboxCircleFillIcon
              style={{ color: "#03BF51", marginTop: "-3px", marginLeft: "-3px" }}
            />{" "}
          </div>
        ) : (
          <div
            style={{
              background: "#ffffff",
              borderRadius: "50%",
              width: "18px",
              height: "18px",
            }}
          >
            <InformationFillIcon
              style={{ color: "#E66F89", marginTop: "-3px", marginLeft: "-3px" }}
            />{" "}
          </div>
        ),
      duration: 2,
      style:
        type === "success"
          ? {
              background: "#07A84D",
            }
          : {
              background: "#DB3457",
            },
    });
  };

  export const getNotifError = () => {
    openNotificationWithIcon("error", "String Code", "String Description");
  };
  
  export const getNotifSuccess = () => {
    openNotificationWithIcon("success", "String Code", "String Description");
  };

export default openNotificationWithIcon;