import { Dropdown, Layout, Badge } from "antd";
import Account from "./Account";
import logo from "../../assets/img/logo-blue.png";
import profile from "../../assets/img/profile.png";
import NotificationFillIcon from "remixicon-react/NotificationFillIcon";

import "../../assets/style/Header.scss";

const HeaderNav = () => {
  const { Header } = Layout;

  let arrbulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  const header_style = {
    position: "fixed",
    padding: 0,
    zIndex: 999,
  };

  return (
    <Header className="site-layout-sub-header-background" style={header_style}>
      <div className="head-container">
        <div className="title-item">
          <img className="img-kioser" src={logo} alt="logo-kioser" />
          <h1>KIBOARD</h1>
        </div>
        <div className="nav-item">
          <div className="date">
            <p>
              {day} {arrbulan[month]} {year}
            </p>
          </div>
          <div className="notification">
            <Badge dot="true" color="#FF355C" offset={[-5, 5]}>
              <NotificationFillIcon
                style={{ fontSize: "20px", color: "#37474F" }}
              />
            </Badge>
          </div>
          <Dropdown overlay={Account} trigger={["click"]}>
            <img className="img-profile" src={profile} alt="profile-user" />
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
