import { Form, Input } from "antd";
import { useCallback, useEffect, useState } from "react";

import "../../assets/style/FloatForm.scss";

const FloatTextArea = (props) => {
  const [focus, setFocus] = useState(false);
  const [inVal, setInVal] = useState(false);
  const [value, setValue] = useState("");
  let {
    rows,
    label,
    name,
    placeholder,
    type,
    required,
    disabled,
    data,
    reset,
    setReset,
    readOnly,
    always,
  } = props;

  if (!placeholder) placeholder = label;
  const inputClass = inVal || always ? "border-active" : "border-not-active";
  const isOccupied = focus || inVal || always;
  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";
  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const { TextArea } = Input;

  const handleInVal = useCallback(
    (val) => {
      setValue(val);
      if (val === "" || val === undefined || val === null) {
        setInVal(false);
      } else {
        if (inVal === false) {
          setInVal(true);
          setReset(false);
        }
      }
    },
    [inVal, setReset]
  );

  useEffect(() => {
    setValue(data);
    if (reset) {
      setValue("");
      setInVal(false);
    }
  }, [data, reset]);

  useEffect(() => {
    handleInVal(value);
  }, [handleInVal, value]);

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Form.Item name={name}>
        <TextArea
          rows={rows}
          className={inputClass}
          onChange={(event) => handleInVal(event.target.value)}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Form.Item>
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatTextArea;
