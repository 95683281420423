import { Button, Dropdown, Menu, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";
import {
  deletePromoKupon,
  duplicatePromoKupon,
  publishPromoKupon,
  unpublishPromoKupon,
} from "../../../containers/Publish/action";
import { ArrowLeftOutlined } from "@ant-design/icons";
import MoreFillIcon from "remixicon-react/MoreFillIcon";
import ShareForwardLineIcon from "remixicon-react/ShareForwardLineIcon";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";

const HeaderKupon = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menu, form, loading, code } = props;
  const { confirm } = Modal;

  const editData = useSelector((state) => state.publish.editDataPromoKupon);

  const handleBackScreen = async () => {
    confirm({
      title: "Yakin ingin Batalkan Kupon ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Batal",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(setHeader("default"));
        localStorage.setItem("otherTemplate", "false");
        form.resetFields();
        navigate("/publish/promo/kupon");
      },
      onCancel() {
        console.log("Cancel hapus");
      },
    });
  };

  const confirmPublish = (id) => {
    confirm({
      title: "Yakin ingin Publish Kupon ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Publish",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(publishPromoKupon(id));
        await dispatch(setHeader("default"));
        window.setTimeout(() => {
          localStorage.setItem("otherTemplate", "false");
          form.resetFields();
          navigate("/publish/promo/kupon");
        }, 1000);
      },
      onCancel() {
        console.log("Cancel publish kupon");
      },
    });
  };

  const confirmUnpublish = (id) => {
    confirm({
      title: "Yakin ingin Unpublish Kupon ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Unpublish",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(unpublishPromoKupon(id));
        await dispatch(setHeader("default"));
        window.setTimeout(() => {
          localStorage.setItem("otherTemplate", "false");
          form.resetFields();
          navigate("/publish/promo/kupon");
        }, 1000);
      },
      onCancel() {
        console.log("Cancel unpublish kupon");
      },
    });
  };

  const duplicateKupon = (id) => {
    confirm({
      title: "Yakin ingin Duplikat Kupon ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Duplikat",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(duplicatePromoKupon(id));
        await dispatch(setHeader("default"));
        window.setTimeout(() => {
          localStorage.setItem("otherTemplate", "false");
          form.resetFields();
          navigate("/publish/promo/kupon");
        }, 1000);
      },
      onCancel() {
        console.log("Cancel duplicate kupon");
      },
    });
  };

  const deleteKupon = (id) => {
    confirm({
      title: "Yakin ingin Hapus Kupon ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Hapus",
      cancelText: "Tidak",
      async onOk() {
        console.log("code kupon: " + id);
        await dispatch(deletePromoKupon(id));
        await dispatch(setHeader("default"));
        window.setTimeout(() => {
          localStorage.setItem("otherTemplate", "false");
          form.resetFields();
          navigate("/publish/promo/kupon");
        }, 1000);
      },
      onCancel() {
        console.log("Cancel hapus kupon");
      },
    });
  };

  const menuAction = (id, status) => (
    <Menu>
      {status === "Berakhir" ? (
        <></>
      ) : (
        <>
          {status === "Published" ? (
            <Menu.Item key="unpublish" onClick={() => confirmUnpublish(id)}>
              <ShareForwardLineIcon />
              <span>Unpublish Kupon</span>
            </Menu.Item>
          ) : (
            <Menu.Item key="publish" onClick={() => confirmPublish(id)}>
              <ShareForwardLineIcon />
              <span>Publish Kupon</span>
            </Menu.Item>
          )}
          <Menu.Item key="duplikat" onClick={() => duplicateKupon(id)}>
            <FileCopyLineIcon />
            <span>Duplikat Kupon</span>
          </Menu.Item>
        </>
      )}
      <Menu.Item key="hapus" danger onClick={() => deleteKupon(id)}>
        <DeleteBinLineIcon />
        <span>Hapus Kupon</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>
          {menu === "tambahkupon"
            ? "Tambah Kupon"
            : menu === "detailkupon"
            ? "Detail Kupon"
            : ""}
        </h1>
      </div>
      <div className="rightHeader">
        <Button
          className="btnAct btnActCancel"
          onClick={() => handleBackScreen()}
        >
          Batal
        </Button>
        <Button
          className="btnAct btnActSubmit"
          onClick={() => form.submit()}
          loading={loading}
        >
          {menu === "tambahkupon" ? "Tambah" : "Simpan"}
        </Button>
        {menu === "detailkupon" ? (
          <Dropdown
            overlay={menuAction(code, editData.status)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button className="btnAct btnMenuAction">
              <MoreFillIcon className="dropdownIcon" />
            </Button>
          </Dropdown>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default HeaderKupon;
