import moment from "moment";
import {
  FORM_INFORMASI_MENARIK_ADD_DATA,
  FORM_INFORMASI_PEMBERITAHUAN_ADD_DATA,
  FORM_INFORMASI_PRODUK_ADD_DATA,
  FORM_PROMO_FLASHSALE_ADD_DATA,
  FORM_PROMO_KUPON_ADD_DATA,
  FORM_PROMO_SPESIAL_ADD_DATA,
  GET_ALL_PRODUCT_FAILED,
  GET_ALL_PRODUCT_SUCCESS,
  GET_CAPTION_FAILED,
  GET_CAPTION_SUCCESS,
  GET_CATEGORY_BY_PROVIDER_FAILED,
  GET_CATEGORY_BY_PROVIDER_SUCCESS,
  GET_DETAIL_INFORMASI_MENARIK_SUCCESS,
  GET_DETAIL_INFORMASI_PEMBERITAHUAN_SUCCESS,
  GET_DETAIL_INFORMASI_PRODUK_SUCCESS,
  GET_DETAIL_PROMO_FLASHSALE_SUCCESS,
  GET_DETAIL_PROMO_KUPON_SUCCESS,
  GET_DETAIL_PROMO_SPESIAL_SUCCESS,
  GET_DETAIL_INFORMASI_MENARIK_FAILED,
  GET_DETAIL_INFORMASI_PEMBERITAHUAN_FAILED,
  GET_DETAIL_INFORMASI_PRODUK_FAILED,
  GET_DETAIL_PROMO_FLASHSALE_FAILED,
  GET_DETAIL_PROMO_KUPON_FAILED,
  GET_DETAIL_PROMO_SPESIAL_FAILED,
  GET_DETAIL_INFORMASI_MENARIK,
  GET_DETAIL_INFORMASI_PEMBERITAHUAN,
  GET_DETAIL_INFORMASI_PRODUK,
  GET_DETAIL_PROMO_FLASHSALE,
  GET_DETAIL_PROMO_KUPON,
  GET_DETAIL_PROMO_SPESIAL,
  GET_INFORMASI_MENARIK,
  GET_INFORMASI_MENARIK_FAILED,
  GET_INFORMASI_MENARIK_SUCCESS,
  GET_INFORMASI_PEMBERITAHUAN,
  GET_INFORMASI_PEMBERITAHUAN_FAILED,
  GET_INFORMASI_PEMBERITAHUAN_SUCCESS,
  GET_INFORMASI_PRODUK,
  GET_INFORMASI_PRODUK_FAILED,
  GET_INFORMASI_PRODUK_SUCCESS,
  GET_PROMO_FLASHSALE,
  GET_PROMO_FLASHSALE_FAILED,
  GET_PROMO_FLASHSALE_SUCCESS,
  GET_PROMO_KUPON,
  GET_PROMO_KUPON_FAILED,
  GET_PROMO_KUPON_SUCCESS,
  GET_PROMO_SPESIAL,
  GET_PROMO_SPESIAL_FAILED,
  GET_PROMO_SPESIAL_SUCCESS,
  GET_PROVIDER_BY_PRODUCT_FAILED,
  GET_PROVIDER_BY_PRODUCT_SUCCESS,
  GET_SUBTYPE_BY_PRODUCT_FAILED,
  GET_SUBTYPE_BY_PRODUCT_SUCCESS,
  GET_TOTAL_INFORMASI_MENARIK_FAILED,
  GET_TOTAL_INFORMASI_MENARIK_SUCCESS,
  GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED,
  GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS,
  GET_TOTAL_INFORMASI_PRODUK_FAILED,
  GET_TOTAL_INFORMASI_PRODUK_SUCCESS,
  GET_TOTAL_PROMO_FLASHSALE_FAILED,
  GET_TOTAL_PROMO_FLASHSALE_SUCCESS,
  GET_TOTAL_PROMO_KUPON_FAILED,
  GET_TOTAL_PROMO_KUPON_SUCCESS,
  GET_TOTAL_PROMO_SPESIAL_FAILED,
  GET_TOTAL_PROMO_SPESIAL_SUCCESS,
  GET_TYPE_BY_PRODUCT_FAILED,
  GET_TYPE_BY_PRODUCT_SUCCESS,
  LOADING_FLASHSALE_ITEM,
  LOADING_KUPON_ITEM,
  LOADING_MENARIK_ITEM,
  LOADING_PEMBERITAHUAN_ITEM,
  LOADING_SPESIAL_ITEM,
  PREVIEW_INFORMASI_PRODUCT_FAILED,
  PREVIEW_INFORMASI_PRODUCT_SUCCESS,
  PUBLISH_INFORMASI_PRODUCT_SUCCESS,
  GET_MASTER_CONFIGURATION,
  GET_MASTER_CONFIGURATION_SUCCESS,
  GET_MASTER_CONFIGURATION_FAILED,
} from "./constants";

const initialState = {
  loadingFlashsaleItem: null,
  loadingSpesialItem: null,
  loadingKuponItem: null,
  loadingPromoFlashsale: true,
  loadingPromoSpesial: true,
  loadingPromoKupon: true,
  totalPromoFlashsale: 0,
  totalPromoSpesial: 0,
  totalPromoKupon: 0,
  promoFlashsale: [],
  promoSpesial: [],
  promoKupon: [],
  allProduct: [],
  providerByProduct: [],
  typeByProduct: [],
  subtypeByProduct: [],
  categoryByProvider: [],
  caption: [],
  editPromoFlashsale: false,
  editDataPromoFlashsale: [],
  editPromoSpesial: false,
  editDataPromoSpesial: [],
  editPromoKupon: false,
  editDataPromoKupon: [],
  loadingInformasiPemberitahuan: true,
  loadingInformasiMenarik: true,
  loadingInformasiProduk: true,
  totalInformasiPemberitahuan: 0,
  totalInformasiMenarik: 0,
  totalInformasiProduk: 0,
  informasiPemberitahuan: [],
  informasiMenarik: [],
  informasiProduk: [],
  editInformasiPemberitahuan: false,
  editDataInformasiPemberitahuan: [],
  editInformasiMenarik: false,
  editDataInformasiMenarik: [],
  editInformasiProduk: false,
  editDataInformasiProduk: [],
  masterConfiguration: null,
  previewProduct: "...",
  forbiddenPromoFlashsale: false,
  forbiddenPromoSpesial: false,
  forbiddenPromoKupon: false,
  forbiddenInformasiPemberitahuan: false,
  forbiddenInformasiMenarik: false,
  forbiddenInformasiProduk: false,
  forbiddenEditDataPromoFlashsale: false,
  forbiddenEditDataPromoSpesial: false,
  forbiddenEditDataPromoKupon: false,
  forbiddenEditDataInformasiPemberitahuan: false,
  forbiddenEditDataInformasiMenarik: false,
  forbiddenEditDataInformasiProduk: false,
  forbiddenMasterConfiguration: false,
};

export const publishReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_PROMO_FLASHSALE:
      return {
        ...state,
        loadingPromoFlashsale: true,
        forbiddenPromoFlashsale: initialState.forbiddenPromoFlashsale,
      };
    case GET_PROMO_FLASHSALE_SUCCESS:
      return {
        ...state,
        loadingPromoFlashsale: false,
        promoFlashsale: action.payload,
      };
    case GET_PROMO_FLASHSALE_FAILED:
      return {
        ...state,
        loadingPromoFlashsale: false,
        promoFlashsale: initialState.promoFlashsale,
        forbiddenPromoFlashsale: action.payload === 403,
      };
    case GET_TOTAL_PROMO_FLASHSALE_SUCCESS:
      return {
        ...state,
        totalPromoFlashsale: action.payload,
      };
    case GET_TOTAL_PROMO_FLASHSALE_FAILED:
      return {
        ...state,
        totalPromoFlashsale: initialState.totalPromoFlashsale,
      };
    case LOADING_FLASHSALE_ITEM:
      return {
        ...state,
        loadingFlashsaleItem: true,
      };
    case FORM_PROMO_FLASHSALE_ADD_DATA:
      return {
        ...state,
        editPromoFlashsale: false,
        editDataPromoFlashsale: {
          id: undefined,
          product: undefined,
          provider: undefined,
          sub_type: undefined,
          category: undefined,
          caption: undefined,
          title: "",
          flash_price: "0",
          start_date: moment(),
          start_time: moment(moment(), "HH:mm:ss"),
          end_date: moment(),
          end_time: moment(moment(), "HH:mm:ss"),
        },
      };
    case GET_DETAIL_PROMO_FLASHSALE:
      return {
        ...state,
        forbiddenEditDataPromoFlashsale:
          initialState.forbiddenEditDataPromoFlashsale,
      };
    case GET_DETAIL_PROMO_FLASHSALE_SUCCESS:
      return {
        ...state,
        editPromoFlashsale: true,
        editDataPromoFlashsale: action.payload,
      };
    case GET_DETAIL_PROMO_FLASHSALE_FAILED:
      return {
        ...state,
        forbiddenEditDataPromoFlashsale: action.payload === 403,
      };
    case GET_ALL_PRODUCT_SUCCESS:
      const allProduct = {
        id_product: 'Semua Product',
        name: 'Semua Product',
        id_product_type: 'Semua Product',
      };
      return {
        ...state,
        allProduct: [...action.payload, allProduct],
      };
    case GET_ALL_PRODUCT_FAILED:
      return {
        ...state,
        allProduct: initialState.allProduct,
      };
    case GET_PROVIDER_BY_PRODUCT_SUCCESS:
      const allProvider = {
          id_product_provider: 'Semua Provider',
          name: "Semua Provider",
          type: "Semua Type"
      }
      return {
        ...state,
        providerByProduct: [...action.payload, allProvider],
      };
    case GET_PROVIDER_BY_PRODUCT_FAILED:
      return {
        ...state,
        providerByProduct: initialState.providerByProduct,
      };
    case GET_TYPE_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        typeByProduct: action.payload,
      };
    case GET_TYPE_BY_PRODUCT_FAILED:
      return {
        ...state,
        typeByProduct: initialState.typeByProduct,
      };
    case GET_SUBTYPE_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        subtypeByProduct: action.payload,
      };
    case GET_SUBTYPE_BY_PRODUCT_FAILED:
      return {
        ...state,
        subtypeByProduct: initialState.subtypeByProduct,
      };
    case GET_CATEGORY_BY_PROVIDER_SUCCESS:
      return {
        ...state,
        categoryByProvider: action.payload,
      };
    case GET_CATEGORY_BY_PROVIDER_FAILED:
      return {
        ...state,
        categoryByProvider: initialState.categoryByProvider,
      };
    case GET_CAPTION_SUCCESS:
      return {
        ...state,
        caption: action.payload,
        loadingFlashsaleItem: false,
      };
    case GET_CAPTION_FAILED:
      return {
        ...state,
        caption: initialState.caption,
      };
    case GET_PROMO_SPESIAL:
      return {
        ...state,
        loadingPromoSpesial: true,
        forbiddenPromoSpesial: initialState.forbiddenPromoSpesial,
      };
    case GET_PROMO_SPESIAL_SUCCESS:
      return {
        ...state,
        loadingPromoSpesial: false,
        promoSpesial: action.payload,
      };
    case GET_PROMO_SPESIAL_FAILED:
      return {
        ...state,
        loadingPromoSpesial: false,
        promoSpesial: initialState.promoSpesial,
        forbiddenPromoSpesial: action.payload === 403,
      };
    case GET_TOTAL_PROMO_SPESIAL_SUCCESS:
      return {
        ...state,
        totalPromoSpesial: action.payload,
      };
    case GET_TOTAL_PROMO_SPESIAL_FAILED:
      return {
        ...state,
        totalPromoSpesial: initialState.totalPromoSpesial,
      };
    case LOADING_SPESIAL_ITEM:
      return {
        ...state,
        loadingSpesialItem: true,
      };
    case FORM_PROMO_SPESIAL_ADD_DATA:
      return {
        ...state,
        editPromoSpesial: false,
        editDataPromoSpesial: {
          id: undefined,
          product: undefined,
          provider: undefined,
          type: undefined,
          start_date: moment(),
          end_date: moment(),
          title: "",
          content: "",
          terms: "",
          how_to_get: "",
          banner: "",
        },
      };
    case GET_DETAIL_PROMO_SPESIAL:
      return {
        ...state,
        forbiddenEditDataPromoSpesial:
          initialState.forbiddenEditDataPromoSpesial,
      };
    case GET_DETAIL_PROMO_SPESIAL_SUCCESS:
      return {
        ...state,
        editPromoSpesial: true,
        editDataPromoSpesial: action.payload,
      };
    case GET_DETAIL_PROMO_SPESIAL_FAILED:
      return {
        ...state,
        forbiddenEditDataPromoSpesial: action.payload === 403,
      };
    case GET_PROMO_KUPON:
      return {
        ...state,
        loadingPromoKupon: true,
        forbiddenPromoKupon: initialState.forbiddenPromoKupon,
      };
    case GET_PROMO_KUPON_SUCCESS:
      return {
        ...state,
        loadingPromoKupon: false,
        promoKupon: action.payload,
      };
    case GET_PROMO_KUPON_FAILED:
      return {
        ...state,
        loadingPromoKupon: false,
        promoKupon: initialState.promoKupon,
        forbiddenPromoKupon: action.payload === 403,
      };
    case GET_TOTAL_PROMO_KUPON_SUCCESS:
      return {
        ...state,
        totalPromoKupon: action.payload,
      };
    case GET_TOTAL_PROMO_KUPON_FAILED:
      return {
        ...state,
        totalPromoKupon: initialState.totalPromoKupon,
      };
    case LOADING_KUPON_ITEM:
      return {
        ...state,
        loadingKuponItem: true,
      };
    case FORM_PROMO_KUPON_ADD_DATA:
      return {
        ...state,
        editPromoKupon: false,
        editDataPromoKupon: {
          id: undefined,
          code: "",
          customer: undefined,
          phone: undefined,
          need: undefined,
          product_id: undefined,
          provider: undefined,
          product_type: undefined,
          product_sub_type: undefined,
          product_category: undefined,
          caption: undefined,
          discount: 0,
          start_date: moment(),
          expired_date: moment(),
          title: "",
          description: "",
          terms: "",
          step: "",
        },
      };
    case GET_DETAIL_PROMO_KUPON:
      return {
        ...state,
        forbiddenEditDataPromoKupon: initialState.forbiddenEditDataPromoKupon,
      };
    case GET_DETAIL_PROMO_KUPON_SUCCESS:
      return {
        ...state,
        editPromoKupon: true,
        editDataPromoKupon: action.payload,
      };
    case GET_DETAIL_PROMO_KUPON_FAILED:
      return {
        ...state,
        forbiddenEditDataPromoKupon: action.payload === 403,
      };
    case GET_INFORMASI_PEMBERITAHUAN:
      return {
        ...state,
        loadingInformasiPemberitahuan: true,
        forbiddenInformasiPemberitahuan:
          initialState.forbiddenInformasiPemberitahuan,
      };
    case GET_INFORMASI_PEMBERITAHUAN_SUCCESS:
      return {
        ...state,
        loadingInformasiPemberitahuan: false,
        informasiPemberitahuan: action.payload,
      };
    case GET_INFORMASI_PEMBERITAHUAN_FAILED:
      return {
        ...state,
        loadingInformasiPemberitahuan: false,
        informasiPemberitahuan: initialState.informasiPemberitahuan,
        forbiddenInformasiPemberitahuan: action.payload === 403,
      };
    case GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS:
      return {
        ...state,
        totalInformasiPemberitahuan: action.payload,
      };
    case GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED:
      return {
        ...state,
        totalInformasiPemberitahuan: initialState.totalInformasiPemberitahuan,
      };
    case LOADING_PEMBERITAHUAN_ITEM:
      return {
        ...state,
        loadingInformasiPemberitahuan: true,
      };
    case FORM_INFORMASI_PEMBERITAHUAN_ADD_DATA:
      return {
        ...state,
        editInformasiPemberitahuan: false,
        editDataInformasiPemberitahuan: {
          id: undefined,
          need: undefined,
          start_date: moment(),
          start_time: moment(),
          title: "",
          informasi: "",
          img: "",
        },
      };
    case GET_DETAIL_INFORMASI_PEMBERITAHUAN:
      return {
        ...state,
        forbiddenEditDataInformasiPemberitahuan:
          initialState.forbiddenEditDataInformasiPemberitahuan,
      };
    case GET_DETAIL_INFORMASI_PEMBERITAHUAN_SUCCESS:
      return {
        ...state,
        editInformasiPemberitahuan: true,
        editDataInformasiPemberitahuan: action.payload,
      };
    case GET_DETAIL_INFORMASI_PEMBERITAHUAN_FAILED:
      return {
        ...state,
        forbiddenEditDataInformasiPemberitahuan: action.payload === 403,
      };
    case GET_INFORMASI_MENARIK:
      return {
        ...state,
        loadingInformasiMenarik: true,
        forbiddenInformasiMenarik: initialState.forbiddenInformasiMenarik,
      };
    case GET_INFORMASI_MENARIK_SUCCESS:
      return {
        ...state,
        loadingInformasiMenarik: false,
        informasiMenarik: action.payload,
      };
    case GET_INFORMASI_MENARIK_FAILED:
      return {
        ...state,
        loadingInformasiMenarik: false,
        informasiMenarik: initialState.informasiMenarik,
        forbiddenInformasiMenarik: action.payload === 403,
      };
    case GET_TOTAL_INFORMASI_MENARIK_SUCCESS:
      return {
        ...state,
        totalInformasiMenarik: action.payload,
      };
    case GET_TOTAL_INFORMASI_MENARIK_FAILED:
      return {
        ...state,
        totalInformasiMenarik: initialState.totalInformasiMenarik,
      };
    case LOADING_MENARIK_ITEM:
      return {
        ...state,
        loadingInformasiMenarik: true,
      };
    case FORM_INFORMASI_MENARIK_ADD_DATA:
      return {
        ...state,
        editInformasiMenarik: false,
        editDataInformasiMenarik: {
          id: undefined,
          category: "",
          start_date: moment(),
          title: "",
          informasi: "",
          img: "",
        },
      };
    case GET_DETAIL_INFORMASI_MENARIK:
      return {
        ...state,
        forbiddenEditDataInformasiMenarik:
          initialState.forbiddenEditDataInformasiMenarik,
      };
    case GET_DETAIL_INFORMASI_MENARIK_SUCCESS:
      return {
        ...state,
        editInformasiMenarik: true,
        editDataInformasiMenarik: action.payload,
      };
    case GET_DETAIL_INFORMASI_MENARIK_FAILED:
      return {
        ...state,
        forbiddenEditDataInformasiMenarik: action.payload === 403,
      };
    case GET_INFORMASI_PRODUK:
      return {
        ...state,
        loadingInformasiProduk: true,
        forbiddenInformasiProduk: initialState.forbiddenInformasiProduk,
      };
    case GET_INFORMASI_PRODUK_SUCCESS:
      return {
        ...state,
        loadingInformasiProduk: false,
        informasiProduk: action.payload,
      };
    case GET_INFORMASI_PRODUK_FAILED:
      return {
        ...state,
        loadingInformasiProduk: false,
        informasiProduk: initialState.informasiProduk,
        forbiddenInformasiProduk: action.payload === 403,
      };
    case GET_TOTAL_INFORMASI_PRODUK_SUCCESS:
      return {
        ...state,
        totalInformasiProduk: action.payload,
      };
    case GET_TOTAL_INFORMASI_PRODUK_FAILED:
      return {
        ...state,
        totalInformasiProduk: initialState.totalInformasiProduk,
      };
    case FORM_INFORMASI_PRODUK_ADD_DATA:
      return {
        ...state,
        editInformasiProduk: false,
        editDataInformasiProduk: {
          id: "",
          judul: "",
          footer: "",
          image: "",
          produk: null,
          provider: null,
          type: null,
          sub_type: null,
          category: null,
          bullet: "",
        },
      };
    case GET_DETAIL_INFORMASI_PRODUK:
      return {
        ...state,
        forbiddenEditDataInformasiProduk:
          initialState.forbiddenEditDataInformasiProduk,
      };
    case GET_DETAIL_INFORMASI_PRODUK_SUCCESS:
      return {
        ...state,
        editInformasiProduk: true,
        editDataInformasiProduk: action.payload,
      };
    case GET_DETAIL_INFORMASI_PRODUK_FAILED:
      return {
        ...state,
        forbiddenEditDataInformasiProduk: action.payload === 403,
      };
    case PREVIEW_INFORMASI_PRODUCT_SUCCESS:
      return {
        ...state,
        previewProduct: action.payload,
      };
    case PREVIEW_INFORMASI_PRODUCT_FAILED:
      return {
        ...state,
        previewProduct: initialState.previewProduct,
      };
    case PUBLISH_INFORMASI_PRODUCT_SUCCESS:
      return {
        ...state,
        previewProduct: "...",
      };
    case GET_MASTER_CONFIGURATION:
      return {
        ...state,
        loadingMasterConfiguration: true,
        forbiddenMasterConfiguration: initialState.forbiddenMasterConfiguration,
      };
    case GET_MASTER_CONFIGURATION_SUCCESS:
      var temp = [];
      action.payload.forEach(item => {
        temp[item.name] = item.value;
      });
      return {
        ...state,
        loadingMasterConfiguration: false,
        masterConfiguration: temp,
      };
    case GET_MASTER_CONFIGURATION_FAILED:
      return {
        ...state,
        loadingMasterConfiguration: false,
        masterConfiguration: initialState.MasterConfiguration,
        forbiddenMasterConfiguration: action.payload === 403,
      };
  }
};
