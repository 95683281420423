import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const HeaderEmpty = (props) => {
  const navigate = useNavigate();
  const { menu } = props;

  const handleBackScreen = async () => {
    navigate(-1);
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>
          {menu === "detailpengecekan" ? "Detail Transaksi Pengecekan" : ""}
        </h1>
      </div>
      <div className="rightHeader"></div>
    </>
  );
};
export default HeaderEmpty;
