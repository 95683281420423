import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";

const HeaderBlacklist = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menu, form, loading } = props;

  const handleBackScreen = async () => {
    await dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    form.resetFields();
    navigate("/pelanggan/blacklist");
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>{menu === "tambahblacklist" ? "Tambah Nomor" : ""}</h1>
      </div>
      <div className="rightHeader">
        <Button
          className="btnAct btnActCancel"
          onClick={() => handleBackScreen()}
        >
          Batal
        </Button>
        <Button
          className="btnAct btnActSubmit"
          onClick={() => form.submit()}
          loading={loading}
        >
          Tambah
        </Button>
      </div>
    </>
  );
};
export default HeaderBlacklist;
