import { Button } from "antd";
import { useState } from "react";
import AddLineIcon from "remixicon-react/AddLineIcon";
import KeyValueItem from "./KeyValueItem";

const KeyValueList = () => {
  const [keyValue, setKeyValue] = useState([
    <KeyValueItem index={1} key={1} />,
    <KeyValueItem index={2} key={2} />,
  ]);
  const [indicator, setIndicator] = useState(2);

  const addKeyValue = () => {
    let key = indicator + 1;
    setIndicator(key);
    setKeyValue(keyValue.concat(<KeyValueItem index={key} key={key} />));
  };

  return (
    <div className="wrapKeyValue">
      <div className="listKeyValue">{keyValue}</div>
      <Button
        className="addKeyValue"
        onClick={() => addKeyValue()}
        style={{ marginRight: "6px" }}
      >
        <AddLineIcon /> Tambah Field
      </Button>
    </div>
  );
};

export default KeyValueList;
