import moment from "moment";
import {
  GET_LIST_PRODUK,
  GET_LIST_PRODUK_SUCCESS,
  GET_LIST_PRODUK_FAILED,
  GET_TOTAL_LIST_PRODUK_SUCCESS,
  GET_TOTAL_LIST_PRODUK_FAILED,
  GET_PRODUK_EDIT_DATA,
  GET_PRODUK_EDIT_DATA_SUCCESS,
  GET_PRODUK_EDIT_DATA_FAILED,
  SET_STATUS_PRODUK_SUCCESS,
  GET_LIST_SET_PRODUK,
  GET_LIST_SET_PRODUK_SUCCESS,
  GET_LIST_SET_PRODUK_FAILED,
  GET_TOTAL_LIST_SET_PRODUK_SUCCESS,
  GET_TOTAL_LIST_SET_PRODUK_FAILED,
  GET_MASTER_PRODUK,
  GET_MASTER_PRODUK_SUCCESS,
  GET_MASTER_PRODUK_FAILED,
  GET_MASTER_PROVIDER,
  GET_MASTER_PROVIDER_SUCCESS,
  GET_MASTER_PROVIDER_FAILED,
  GET_MASTER_TIPE,
  GET_MASTER_TIPE_SUCCESS,
  GET_MASTER_TIPE_FAILED,
  GET_MASTER_SUBTIPE,
  GET_MASTER_SUBTIPE_SUCCESS,
  GET_MASTER_SUBTIPE_FAILED,
  GET_MASTER_KATEGORI,
  GET_MASTER_KATEGORI_SUCCESS,
  GET_MASTER_KATEGORI_FAILED,
  GET_SUPPLIER_PRODUK_SUCCESS,
  GET_SUPPLIER_PRODUK_FAILED,
  GET_SUPPLIER_PROVIDER_SUCCESS,
  GET_SUPPLIER_PROVIDER_FAILED,
  SEARCH_SETDATA_FAILED,
  SET_PRODUK_EDIT,
  GET_SET_PRODUK_BY_CODE_SUCCESS,
  FORM_PROVIDER_ADD_DATA,
  FORM_PROVIDER_EDIT_DATA_SUCCESS,
  FORM_PROVIDER_EDIT_DATA_FAILED,
  FORM_TIPE_ADD_DATA,
  FORM_TIPE_EDIT_DATA_SUCCESS,
  FORM_TIPE_EDIT_DATA_FAILED,
  FORM_SUBTIPE_ADD_DATA,
  FORM_SUBTIPE_EDIT_DATA_SUCCESS,
  FORM_SUBTIPE_EDIT_DATA_FAILED,
  FORM_KATEGORI_ADD_DATA,
  FORM_KATEGORI_EDIT_DATA_SUCCESS,
  FORM_KATEGORI_EDIT_DATA_FAILED,
  FORM_PRODUK_ADD_DATA,
  SET_FAILED_EDIT_KODE_INADD_SUCCESS,
  SET_FAILED_EDIT_KODE_INADD_FAILED,
  SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS,
  SET_FAILED_EDIT_KODE_INDETAIL_FAILED,
  DELETE_PRODUK_SUCCESS,
  GET_MAINTENANCE_PRODUK_SUCCESS,
  SET_PREMIUM_MASTER_PRODUK_SUCCESS,
  GET_MASTER_SUPPLIER_V2,
  GET_MASTER_SUPPLIER_V2_SUCCESS,
  GET_MASTER_SUPPLIER_V2_FAILED,
  GET_MASTER_PRODUK_V2,
  GET_MASTER_PRODUK_V2_SUCCESS,
  GET_MASTER_PRODUK_V2_FAILED,
  GET_MASTER_PRODUK_TYPE_V2,
  GET_MASTER_PRODUK_TYPE_V2_SUCCESS,
  GET_MASTER_PRODUK_TYPE_V2_FAILED,
  GET_MASTER_PRODUK_TYPE_DETAIL_V2,
  GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS,
  GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED,
  GET_MASTER_PRODUK_PROVIDER_V2,
  GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS,
  GET_MASTER_PRODUK_PROVIDER_V2_FAILED,
  GET_MASTER_PRODUK_CATEGORY_V2,
  GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS,
  GET_MASTER_PRODUK_CATEGORY_V2_FAILED,
  SET_SEARCH_TEXT,
  SET_SEARCH_TEXT_ENTER,
  SET_SEARCH_TEXT_OPTION,
} from "./constants";

const initialState = {
  loadingListProduk: true,
  loadingListSetProduk: true,
  loadingProduk: true,
  loadingProvider: true,
  loadingTipe: true,
  loadingSubTipe: true,
  loadingKategori: true,
  failedEditKode: true,
  listProduk: [],
  listSetProduk: [],
  masterProduk: [],
  masterProvider: [],
  masterTipe: [],
  masterSubTipe: [],
  masterKategori: [],
  supplierProduk: [],
  supplierProvider: [],
  totalListProduk: 0,
  totalListSetProduk: 0,
  editProduk: false,
  editDataProduk: [],
  singleDataSetProduk: [],
  listSupplier: [],
  customMarginProduk: [],
  editProvider: false,
  editDataProvider: [],
  editTipe: false,
  editDataTipe: [],
  editSubTipe: false,
  editDataSubTipe: [],
  editKategori: false,
  editDataKategori: [],
  deleteProduk: false,
  maintenance: {},
  masterSupplierV2: [],
  masterProdukV2: [],
  masterProdukTypeV2: [],
  masterProdukTypeDetailV2: [],
  masterProdukProviderV2: [],
  masterProdukCategoryV2: [],
  forbiddenProduk: false,
  forbiddenSetProduk: false,
  forbiddenMasterProduk: false,
  forbiddenMasterProvider: false,
  forbiddenMasterTipe: false,
  forbiddenMasterSubTipe: false,
  forbiddenMasterKategori: false,
  forbiddenEditProduk: false,
  forbiddenMasterSupplierV2: false,
  forbiddenMasterProdukV2: false,
  forbiddenMasterProdukTypeV2: false,
  forbiddenMasterProdukTypeDetailV2: false,
  forbiddenMasterProdukProviderV2: false,
  forbiddenMasterProdukCategoryV2: false,
  searchText: "",
  searchTextEnter: "",
  searchTextOption: "product_code",
  searchPage: 1,
};

export const produkReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_LIST_PRODUK:
      return {
        ...state,
        loadingListProduk: true,
        listProduk: [],
        forbiddenProduk: initialState.forbiddenProduk,
      };
    case GET_LIST_PRODUK_SUCCESS:
      return {
        ...state,
        loadingListProduk: false,
        listProduk: action.payload,
      };
    case GET_LIST_PRODUK_FAILED:
      return {
        ...state,
        loadingListProduk: false,
        listProduk: initialState.listProduk,
        forbiddenProduk: action.payload === 403,
      };
    case GET_TOTAL_LIST_PRODUK_SUCCESS:
      return {
        ...state,
        totalListProduk: action.payload,
      };
    case GET_TOTAL_LIST_PRODUK_FAILED:
      return {
        ...state,
        totalListProduk: initialState.totalListProduk,
      };
    case DELETE_PRODUK_SUCCESS:
      return {
        ...state,
        deleteProduk: action.payload === true ? false : true,
      };
    case SET_STATUS_PRODUK_SUCCESS:
      let newList = [...state.listProduk];
      let index = newList.findIndex(
        (x) => x.code_product === action.payload.code
      );
      newList[index].status = action.payload.status;
      return {
        ...state,
        listProduk: newList,
      };
    case FORM_PRODUK_ADD_DATA:
      return {
        ...state,
        editProduk: false,
        editDataProduk: {
          product: undefined,
          provider: undefined,
          type: undefined,
          sub_type: undefined,
          category: undefined,
          code_product: "",
          caption: "",
          description: "",
          terms: "",
          zona: "",
        },
        customMarginProduk: {
          code_product: null,
          id_margin: null,
          margin: "0",
          start_date: moment(),
          start_time: moment(moment(), "HH:mm:ss"),
          end_date: moment(),
          end_time: moment(moment(), "HH:mm:ss"),
        },
      };
    case GET_PRODUK_EDIT_DATA:
      return {
        ...state,
        forbiddenEditProduk: initialState.forbiddenEditProduk,
      };
    case GET_PRODUK_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editProduk: true,
        listSupplier: action.payload.list_supplier,
        editDataProduk: action.payload.detail,
        singleDataSetProduk: action.payload.supplier,
        customMarginProduk: action.payload.customMargin,
      };
    case GET_PRODUK_EDIT_DATA_FAILED:
      return {
        ...state,
        editProduk: false,
        editDataProduk: null,
        forbiddenEditProduk: action.payload === 403,
      };
    case SET_PRODUK_EDIT:
      return {
        ...state,
        editProduk: action.payload,
      };
    case GET_LIST_SET_PRODUK:
      return {
        ...state,
        loadingListSetProduk: true,
        forbiddenSetProduk: initialState.forbiddenSetProduk,
      };
    case GET_LIST_SET_PRODUK_SUCCESS:
      return {
        ...state,
        loadingListSetProduk: false,
        listSetProduk: action.payload,
      };
    case GET_LIST_SET_PRODUK_FAILED:
      return {
        ...state,
        loadingListSetProduk: false,
        listSetProduk: initialState.listProduk,
        forbiddenSetProduk: action.payload === 403,
      };
    case GET_TOTAL_LIST_SET_PRODUK_SUCCESS:
      return {
        ...state,
        totalListSetProduk: action.payload,
      };
    case GET_TOTAL_LIST_SET_PRODUK_FAILED:
      return {
        ...state,
        totalListSetProduk: initialState.totalListProduk,
      };
    case GET_SET_PRODUK_BY_CODE_SUCCESS:
      return {
        ...state,
        singleDataSetProduk: action.payload,
      };
    case SET_FAILED_EDIT_KODE_INADD_SUCCESS:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case SET_FAILED_EDIT_KODE_INADD_FAILED:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case SET_FAILED_EDIT_KODE_INDETAIL_FAILED:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case GET_MASTER_PRODUK:
      return {
        ...state,
        forbiddenMasterProduk: initialState.forbiddenMasterProduk,
      };
    case GET_MASTER_PRODUK_SUCCESS:
      return {
        ...state,
        loadingProduk: false,
        masterProduk: action.payload,
      };
    case GET_MASTER_PRODUK_FAILED:
      return {
        ...state,
        loadingProduk: false,
        masterProduk: initialState.masterProduk,
        forbiddenMasterProduk: action.payload === 403,
      };
    case SET_PREMIUM_MASTER_PRODUK_SUCCESS:
      let newMasterProduk = [...state.masterProduk];
      let idx = newMasterProduk.findIndex(
        (x) => x.id_product === action.payload.id_product
      );
      newMasterProduk[idx].is_premium = action.payload.is_premium;
      return {
        ...state,
        masterProduk: newMasterProduk,
      };
    case GET_MASTER_PROVIDER:
      return {
        ...state,
        forbiddenMasterProvider: initialState.forbiddenMasterProvider,
      };
    case GET_MASTER_PROVIDER_SUCCESS:
      return {
        ...state,
        loadingProvider: false,
        masterProvider: action.payload,
      };
    case GET_MASTER_PROVIDER_FAILED:
      return {
        ...state,
        loadingProvider: false,
        masterProvider: initialState.masterProvider,
        forbiddenMasterProvider: action.payload === 403,
      };
    case GET_MASTER_TIPE:
      return {
        ...state,
        forbiddenMasterTipe: initialState.forbiddenMasterTipe,
      };
    case GET_MASTER_TIPE_SUCCESS:
      return {
        ...state,
        loadingTipe: false,
        masterTipe: action.payload,
      };
    case GET_MASTER_TIPE_FAILED:
      return {
        ...state,
        loadingTipe: false,
        masterTipe: initialState.masterTipe,
        forbiddenMasterTipe: action.payload === 403,
      };
    case GET_MASTER_SUBTIPE:
      return {
        ...state,
        forbiddenMasterSubTipe: initialState.forbiddenMasterSubTipe,
      };
    case GET_MASTER_SUBTIPE_SUCCESS:
      return {
        ...state,
        loadingSubTipe: false,
        masterSubTipe: action.payload,
        forbiddenMasterSubTipe: action.payload === 403,
      };
    case GET_MASTER_SUBTIPE_FAILED:
      return {
        ...state,
        loadingSubTipe: false,
        masterSubTipe: initialState.masterSubTipe,
      };
    case GET_MASTER_KATEGORI:
      return {
        ...state,
        forbiddenMasterKategori: initialState.forbiddenMasterKategori,
      };
    case GET_MASTER_KATEGORI_SUCCESS:
      return {
        ...state,
        loadingKategori: false,
        masterKategori: action.payload,
      };
    case GET_MASTER_KATEGORI_FAILED:
      return {
        ...state,
        loadingKategori: false,
        masterKategori: initialState.masterKategori,
        forbiddenMasterKategori: action.payload === 403,
      };
    case GET_SUPPLIER_PRODUK_SUCCESS:
      return {
        ...state,
        supplierProduk: action.payload,
      };
    case GET_SUPPLIER_PRODUK_FAILED:
      return {
        ...state,
        supplierProduk: initialState.supplierProduk,
      };
    case GET_SUPPLIER_PROVIDER_SUCCESS:
      return {
        ...state,
        supplierProvider: action.payload,
      };
    case GET_SUPPLIER_PROVIDER_FAILED:
      return {
        ...state,
        supplierProvider: initialState.supplierProvider,
      };
    case SEARCH_SETDATA_FAILED:
      return {
        ...state,
        loadingProduk: false,
        masterProduk: initialState.masterProduk,
        loadingProvider: false,
        masterProvider: initialState.masterProvider,
        loadingTipe: false,
        masterTipe: initialState.masterTipe,
        loadingSubTipe: false,
        masterSubTipe: initialState.masterSubTipe,
        loadingKategori: false,
        masterKategori: initialState.masterKategori,
      };
    case FORM_PROVIDER_ADD_DATA:
      return {
        ...state,
        editProvider: false,
        editDataProvider: {
          product: "",
          id_product_provider: "",
          id_product: "",
          name: "",
          icon: "",
          type: "",
          description: "",
          status: "",
          is_visible_android: "",
          is_visible_web: "",
        },
      };
    case FORM_PROVIDER_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editProvider: true,
        editDataProvider: action.payload,
      };
    case FORM_PROVIDER_EDIT_DATA_FAILED:
      return {
        ...state,
        editProvider: true,
        editDataProvider: initialState.editDataProvider,
      };
    case FORM_TIPE_ADD_DATA:
      return {
        ...state,
        editTipe: false,
        editDataTipe: {
          product: "",
          id_product: "",
          id_product_type: "",
          name: "",
        },
      };
    case FORM_TIPE_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editTipe: true,
        editDataTipe: action.payload,
      };
    case FORM_TIPE_EDIT_DATA_FAILED:
      return {
        ...state,
        editTipe: true,
        editDataTipe: initialState.editDataTipe,
      };
    case FORM_SUBTIPE_ADD_DATA:
      return {
        ...state,
        editSubTipe: false,
        editDataSubTipe: {
          product: "",
          id_product_type: "",
          id_product_type_detail: "",
          name: "",
          type: "",
        },
      };
    case FORM_SUBTIPE_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editSubTipe: true,
        editDataSubTipe: action.payload,
      };
    case FORM_SUBTIPE_EDIT_DATA_FAILED:
      return {
        ...state,
        editSubTipe: true,
        editDataSubTipe: initialState.editDataSubTipe,
      };
    case FORM_KATEGORI_ADD_DATA:
      return {
        ...state,
        editKategori: false,
        editDataKategori: {
          product: "",
          id_product_category: "",
          id_product_provider: "",
          name: "",
          provider: "",
        },
      };
    case FORM_KATEGORI_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editKategori: true,
        editDataKategori: action.payload,
      };
    case FORM_KATEGORI_EDIT_DATA_FAILED:
      return {
        ...state,
        editKategori: true,
        editDataKategori: initialState.editDataKategori,
      };
    case GET_MAINTENANCE_PRODUK_SUCCESS:
      return {
        ...state,
        maintenance: action.payload,
      };
    case GET_MASTER_SUPPLIER_V2:
      return {
        ...state,
        forbiddenMasterSupplierV2: initialState.forbiddenMasterSupplierV2,
      };
    case GET_MASTER_SUPPLIER_V2_SUCCESS:
      return {
        ...state,
        masterSupplierV2: action.payload,
      };
    case GET_MASTER_SUPPLIER_V2_FAILED:
      return {
        ...state,
        masterSupplierV2: initialState.masterSupplierV2,
        forbiddenMasterSupplierV2: action.payload === 403,
      };
    case GET_MASTER_PRODUK_V2:
      return {
        ...state,
        masterProdukTypeDetailV2: initialState.masterProdukTypeDetailV2,
        masterProdukProviderV2: initialState.masterProdukProviderV2,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukV2: initialState.forbiddenMasterProdukV2,
      };
    case GET_MASTER_PRODUK_V2_SUCCESS:
      return {
        ...state,
        masterProdukV2: action.payload,
      };
    case GET_MASTER_PRODUK_V2_FAILED:
      return {
        ...state,
        masterProdukV2: initialState.masterProdukV2,
        forbiddenMasterProdukV2: action.payload === 403,
      };
    case GET_MASTER_PRODUK_TYPE_V2:
      return {
        ...state,
        masterProdukTypeV2: initialState.masterProdukTypeV2,
        masterProdukTypeDetailV2: initialState.masterProdukTypeDetailV2,
        masterProdukProviderV2: initialState.masterProdukProviderV2,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukTypeV2: initialState.forbiddenMasterProdukTypeV2,
      };
    case GET_MASTER_PRODUK_TYPE_V2_SUCCESS:
      return {
        ...state,
        masterProdukTypeV2: action.payload,
      };
    case GET_MASTER_PRODUK_TYPE_V2_FAILED:
      return {
        ...state,
        masterProdukTypeV2: initialState.masterProdukTypeV2,
        forbiddenMasterProdukTypeV2: action.payload === 403,
      };
    case GET_MASTER_PRODUK_TYPE_DETAIL_V2:
      return {
        ...state,
        forbiddenMasterProdukTypeDetailV2:
          initialState.forbiddenMasterProdukTypeDetailV2,
      };
    case GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS:
      return {
        ...state,
        masterProdukTypeDetailV2: action.payload,
      };
    case GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED:
      return {
        ...state,
        masterProdukTypeDetailV2: initialState.masterProdukTypeDetailV2,
        forbiddenMasterProdukTypeDetailV2: action.payload === 403,
      };
    case GET_MASTER_PRODUK_PROVIDER_V2:
      return {
        ...state,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukProviderV2:
          initialState.forbiddenMasterProdukProviderV2,
      };
    case GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS:
      return {
        ...state,
        masterProdukProviderV2: action.payload,
      };
    case GET_MASTER_PRODUK_PROVIDER_V2_FAILED:
      return {
        ...state,
        masterProdukProviderV2: initialState.masterProdukProviderV2,
        forbiddenMasterProdukProviderV2: action.payload === 403,
      };
    case GET_MASTER_PRODUK_CATEGORY_V2:
      return {
        ...state,
        forbiddenMasterProdukCategoryV2:
          initialState.forbiddenMasterProdukCategoryV2,
      };
    case GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS:
      return {
        ...state,
        masterProdukCategoryV2: action.payload,
      };
    case GET_MASTER_PRODUK_CATEGORY_V2_FAILED:
      return {
        ...state,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukCategoryV2: action.payload === 403,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case SET_SEARCH_TEXT_ENTER:
      return {
        ...state,
        searchTextEnter: action.payload,
      };
    case SET_SEARCH_TEXT_OPTION:
      return {
        ...state,
        searchTextOption: action.payload,
      };
  }
};
