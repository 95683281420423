import axios from "axios";
import { VERIFIED_LOGIN } from "../containers/Main/constants";
import openNotificationWithIcon from "./baseNotification";

const apiToken = process.env.REACT_APP_APITOKEN;

const baseAction = async ({
  dispatch,
  method,
  url,
  params = {},
  data = {},
  resRequest = {},
  resFailed1 = null,
  resFailed2 = null,
  deleteState = null,
  resType,
  typeSuccess,
  typeFailed,
  type = null,
  mockHttp = null,
  mockData = null,
  notify = false,
  header = null,
}) => {
  try {
    dispatch({
      type: type,
      payload: null,
    });

    let res = null;

    //fake reply untuk testing
    if (mockHttp != null && mockData != null) {
      res = mockHttp;
      res.data = mockData;
    } else {
      var finalHeader = {};
      if (header != null) {
        finalHeader = header;
      }

      //header bisa ditambah, contoh untuk request patch
      finalHeader["Api-Token"] = apiToken;
      finalHeader["Kioser-Token"] = localStorage.getItem("token");

      res = await axios({
        method: method,
        url: url,
        data: data,
        headers: finalHeader,
        params: params,
      });
    }

    if (res.status >= 200 && res.status <= 204) {
      if (res.data.responseCode === 1000) {
      let payload =
          resType === "list"
            ? res.data.data.list
            : resType === "one"
            ? res.data.data
            : resType === "total"
            ? res.data.data.total
            : resType === "delete"
            ? deleteState
            : resType === "res-request"
            ? resRequest
            : resType === "description"
            ? res.data.responseDescription
            : resType === "list-total"
            ? { data: res.data.data.list, total: res.data.data.total }
            : resType === "combine"
            ? { condition: resRequest, data: res.data.data }
            : res.data;

        dispatch({
          type: typeSuccess,
          payload: payload,
        });
        if (notify === true) {
          openNotificationWithIcon(
            "success",
            res.data.responseCode.toString(),
            res.data.responseDescription
          );
        }
      } else {
        dispatch({
          type: typeFailed,
          payload:
            resFailed1 === null
              ? resType === "add"
                ? res.data.responseStatus
                : res.data.responseDescription
              : resFailed1,
        });
        openNotificationWithIcon(
          "error",
          res.data.responseCode.toString(),
          res.data.responseDescription
        );
      }
    }
  } catch (error) {
    console.log(error);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 500)
    ) {
      //Token Invalid, Logout
      localStorage.setItem("token", "");
      localStorage.setItem("isAuth", "0");
      localStorage.setItem("nik", "");
      localStorage.setItem("nama", "");
      localStorage.setItem("key", "");

      dispatch({
        type: VERIFIED_LOGIN,
      });

      openNotificationWithIcon(
        "error",
        error.response.data.responseCode.toString(),
        "Silahkan Login Kembali"
      );
    } else if (error.response && error.response.status === 403) {
      //Access Forbidden
      dispatch({
        type: typeFailed,
        payload: error.response.status,
      });

      if (notify === true) {
        openNotificationWithIcon(
          "error",
          "403",
          "Akses tidak diizinkan"
        );
      }
    } else {
      dispatch({
        type: typeFailed,
        payload: resFailed2 === null ? error.response.data : resFailed2,
      });

      openNotificationWithIcon(
        "error",
        error.response.data?.responseCode?.toString() ??
          error.response.status.toString(),
        error.response.data?.responseDescription ?? error.message
      );
    }
  }
};

export default baseAction;
