import axios from "axios";
import Swal from "sweetalert2";
import { SIGN_OUT_SUCCESS, SIGN_OUT_FAILED, SIGN_OUT } from "./constants";
const baseUrlAcl = process.env.REACT_APP_API_ACL;
const apiToken = process.env.REACT_APP_APITOKEN;

export const execSignOut = (values) => async (dispatch) => {
  dispatch({type: SIGN_OUT});
  try {
    const res = await axios.post(`${baseUrlAcl}/auth/logout`, values, {
      headers: {
        "Api-Token": apiToken,
        "Kioser-Token": localStorage.getItem("token"),
      },
    });
    console.log(res);
    if (res.status === 200) {
      localStorage.setItem("token", "");
      localStorage.setItem("isAuth", "0");
      localStorage.setItem("nik", "");
      localStorage.setItem("nama", "");
      localStorage.setItem("key", "");
      dispatch({
        type: SIGN_OUT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SIGN_OUT_FAILED,
      payload: error.response.data,
    });
    Swal.fire(
      error.response.data.responseCode.toString(),
      error.response.data.responseDescription,
      "error"
    );
  }
};
