export const GET_SETTING = "GET_SETTING";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_FAILED = "GET_SETTING_FAILED";
export const GET_TOTAL_SETTING = "GET_TOTAL_SETTING";
export const GET_TOTAL_SETTING_SUCCESS = "GET_TOTAL_SETTING_SUCCESS";
export const GET_TOTAL_SETTING_FAILED = "GET_TOTAL_SETTING_FAILED";
export const DELETE_SETTING = "DELETE_SETTING";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
export const DELETE_SETTING_FAILED = "DELETE_SETTING_FAILED";
export const ADD_SETTING = "ADD_SETTING";
export const ADD_SETTING_SUCCESS = "ADD_SETTING_SUCCESS";
export const ADD_SETTING_FAILED = "ADD_SETTING_FAILED";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_FAILED = "UPDATE_SETTING_FAILED";
export const RESET_SETTING_DETAIL = "RESET_SETTING_DETAIL";
export const SET_SETTING_DETAIL = "SET_SETTING_DETAIL";
export const UPDATE_SETTING_DATA = "UPDATE_SETTING_DATA";

