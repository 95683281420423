import AlertFillIcon from "remixicon-react/AlertFillIcon";

const Forbidden = () => {

  return (
    <div className="wrapForbidden">
      <div className="wrapIcon">
          <div className="icon">
            <AlertFillIcon size={52} />
          </div>
      </div>
      <div className="caption">Maaf, Anda tidak punya akses untuk halaman ini</div>
    </div>
    );
};

export default Forbidden;
