import {
  GET_SETTING,
  GET_SETTING_SUCCESS,
  GET_SETTING_FAILED,
  GET_TOTAL_SETTING_SUCCESS,
  GET_TOTAL_SETTING_FAILED,
  DELETE_SETTING,
  DELETE_SETTING_SUCCESS,
  DELETE_SETTING_FAILED,
  ADD_SETTING,
  ADD_SETTING_SUCCESS,
  ADD_SETTING_FAILED,
  UPDATE_SETTING,
  UPDATE_SETTING_SUCCESS,
  UPDATE_SETTING_FAILED,
  RESET_SETTING_DETAIL,
  SET_SETTING_DETAIL,
  UPDATE_SETTING_DATA,
} from "./constants";

const initialState = {
  loadingSetting: true,
  loadingAddSetting: false,
  loadingUpdateSetting: false,
  loadingDeleteSetting: false,
  setting: [],
  detailSetting: null,
  totalSetting: 0,
  forbiddenSetting: false,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_SETTING:
      return {
        ...state,
        loadingSetting: true,
        forbiddenSetting: initialState.forbiddenSetting,
      };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        loadingSetting: false,
        setting: action.payload,
      };
    case GET_SETTING_FAILED:
      return {
        ...state,
        loadingSetting: false,
        setting: initialState.setting,
        forbiddenSetting: action.payload === 403,
      };
    case GET_TOTAL_SETTING_SUCCESS:
      return {
        ...state,
        totalSetting: action.payload,
      };
    case GET_TOTAL_SETTING_FAILED:
      return {
        ...state,
        totalSetting: initialState.totalSetting,
      };

    case UPDATE_SETTING:
      return {
        ...state,
        loadingUpdateSetting: true,
      };
    case UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        loadingUpdateSetting: false,
      };
    case UPDATE_SETTING_FAILED:
      return {
        ...state,
        loadingUpdateSetting: false,
      };
    case UPDATE_SETTING_DATA:
      return {
        ...state,
        setting: action.payload,
      };
    case ADD_SETTING:
      return {
        ...state,
        loadingAddSetting: true,
      };
    case ADD_SETTING_SUCCESS:
      return {
        ...state,
        loadingAddSetting: false,
      };
    case ADD_SETTING_FAILED:
      return {
        ...state,
        loadingAddSetting: false,
      };
    case DELETE_SETTING:
      return {
        ...state,
        loadingDeleteSetting: true,
      };
    case DELETE_SETTING_SUCCESS:
      return {
        ...state,
        loadingDeleteSetting: false,
      };
    case DELETE_SETTING_FAILED:
      return {
        ...state,
        loadingDeleteSetting: false,
      };
    case RESET_SETTING_DETAIL:
      return {
        ...state,
        detailSetting: initialState.detailSetting,
      };
    case SET_SETTING_DETAIL:
      return {
        ...state,
        detailSetting: action.payload,
      };
  }
};
