import { Alert, Button, Form, Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import FloatTextArea from "../../../components/FloatForm/FloatTextArea";
import { unverifikasiPelanggan, verifikasiPelanggan } from "../actions";
import { useDispatch } from "react-redux";

const ModalVerifikasiAkun = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(false);
  const [reset, setReset] = useState(false);
  const [alertEmpty, setAlertEmpty] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log("onfinish form");
    setLoading(true);
    if (values.verify === "sukses") {
      await dispatch(verifikasiPelanggan(props.id));
      window.setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 1500);
    } else if (values.verify === "gagal") {
      await dispatch(unverifikasiPelanggan(props.id, values.keterangan));
      window.setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 1500);
    } else {
      setAlertEmpty(true);
      window.setTimeout(() => {
        setAlertEmpty(false);
      }, 2000);
    }
    console.log(values);
  };

  const onFinishFailed = async (errorInfo) => {
    console.log("Failed: ", errorInfo);
  };

  const handleCancel = () => {
    props.setModalVisible(false);
  };

  const hideReason = () => {
    setReason(false);
    form.setFieldsValue({
      verify: "sukses",
    });
  };

  const showReason = () => {
    setReason(true);
    form.setFieldsValue({
      verify: "gagal",
    });
  };

  useEffect(() => {
    if (props.modalVisible === true) {
      form.resetFields();
      setReason(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalVisible]);

  return (
    <Modal
      title="Verifikasi Akun"
      visible={props.modalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <ul className="formOptionRadio">
          <Form.Item name="verify" style={{ marginBottom: "0px" }}>
            <Radio.Group>
              {props.openLimit === 1 ? (
                <li>
                  <label className="rdVerify rdVerifyDisable">
                    <h3>Sukseskan Verifikasi</h3>
                    <h4>Jadikan status akun pelanggan bisnis</h4>
                  </label>
                  <Radio value={"sukses"} disabled></Radio>
                </li>
              ) : (
                <li onClick={() => hideReason()}>
                  <label className="rdVerify">
                    <h3>Sukseskan Verifikasi</h3>
                    <h4>Jadikan status akun pelanggan bisnis</h4>
                  </label>
                  <Radio value={"sukses"}></Radio>
                </li>
              )}
              {props.openLimit === 0 ? (
                <li>
                  <label className="rdVerify rdVerifyDisable">
                    <h3>Gagalkan Verifikasi</h3>
                    <h4>Jadikan status akun pelanggan tetap atau pribadi</h4>
                  </label>
                  <Radio value={"gagal"} disabled></Radio>
                </li>
              ) : (
                <li onClick={() => showReason()}>
                  <label className="rdVerify">
                    <h3>Gagalkan Verifikasi</h3>
                    <h4>Jadikan status akun pelanggan tetap atau pribadi</h4>
                  </label>
                  <Radio value={"gagal"}></Radio>
                </li>
              )}
            </Radio.Group>
          </Form.Item>
          {reason ? (
            <div style={{ paddingRight: 24 }}>
              <FloatTextArea
                row="4"
                label="Keterangan"
                placeholder="Keterangan"
                name="keterangan"
                reset={reset}
                setReset={setReset}
              />
            </div>
          ) : (
            <></>
          )}
        </ul>
        {alertEmpty ? (
          <div style={{ paddingLeft: 24, paddingRight: 24 }}>
            <Alert type="error" message="Verifikasi Akun harus dipilih" />
          </div>
        ) : (
          <></>
        )}
        <div className="submitModal">
          <Button key="back" onClick={handleCancel}>
            Batal
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            Simpan
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalVerifikasiAkun;
