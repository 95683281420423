import baseAction from "../../utils/baseAction";
import openNotificationWithIcon from "../../utils/baseNotification";
import basePromiseAction from "../../utils/basePromiseAction";
import {
  GET_LIST_PRODUK,
  GET_LIST_PRODUK_SUCCESS,
  GET_LIST_PRODUK_FAILED,
  GET_TOTAL_LIST_PRODUK_SUCCESS,
  GET_TOTAL_LIST_PRODUK_FAILED,
  SYNC_PRODUCT_SUCCESS,
  SYNC_PRODUCT_FAILED,
  DELETE_PRODUK_SUCCESS,
  DELETE_PRODUK_FAILED,
  SET_STATUS_PRODUK_SUCCESS,
  SET_STATUS_PRODUK_FAILED,
  GET_LIST_SET_PRODUK,
  GET_LIST_SET_PRODUK_SUCCESS,
  GET_LIST_SET_PRODUK_FAILED,
  GET_TOTAL_LIST_SET_PRODUK_SUCCESS,
  GET_TOTAL_LIST_SET_PRODUK_FAILED,
  SYNC_SET_PRODUCT_SUCCESS,
  SYNC_SET_PRODUCT_FAILED,
  GET_PRODUK_EDIT_DATA_SUCCESS,
  GET_PRODUK_EDIT_DATA_FAILED,
  ADD_PRODUK_SUCCESS,
  ADD_PRODUK_FAILED,
  GET_MASTER_PRODUK,
  GET_MASTER_PRODUK_SUCCESS,
  GET_MASTER_PRODUK_FAILED,
  GET_MASTER_PROVIDER,
  GET_MASTER_PROVIDER_SUCCESS,
  GET_MASTER_PROVIDER_FAILED,
  GET_MASTER_TIPE,
  GET_MASTER_TIPE_SUCCESS,
  GET_MASTER_TIPE_FAILED,
  GET_MASTER_SUBTIPE,
  GET_MASTER_SUBTIPE_SUCCESS,
  GET_MASTER_SUBTIPE_FAILED,
  GET_MASTER_KATEGORI,
  GET_MASTER_KATEGORI_SUCCESS,
  GET_MASTER_KATEGORI_FAILED,
  SEARCH_SETDATA_FAILED,
  ADD_MASTER_SUBTIPE_SUCCESS,
  ADD_MASTER_SUBTIPE_FAILED,
  ADD_MASTER_KATEGORI_SUCCESS,
  ADD_MASTER_KATEGORI_FAILED,
  UPDATE_MASTER_KATEGORI_SUCCESS,
  UPDATE_MASTER_KATEGORI_FAILED,
  DELETE_MASTER_KATEGORI_SUCCESS,
  DELETE_MASTER_KATEGORI_FAILED,
  UPDATE_MASTER_SUBTIPE_SUCCESS,
  UPDATE_MASTER_SUBTIPE_FAILED,
  DELETE_MASTER_SUBTIPE_SUCCESS,
  ADD_MASTER_TIPE_SUCCESS,
  ADD_MASTER_TIPE_FAILED,
  UPDATE_MASTER_TIPE_SUCCESS,
  UPDATE_MASTER_TIPE_FAILED,
  DELETE_MASTER_TIPE_SUCCESS,
  DELETE_MASTER_TIPE_FAILED,
  DELETE_MASTER_SUBTIPE_FAILED,
  ADD_MASTER_PROVIDER_SUCCESS,
  ADD_MASTER_PROVIDER_FAILED,
  UPDATE_MASTER_PROVIDER_SUCCESS,
  UPDATE_MASTER_PROVIDER_FAILED,
  DELETE_MASTER_PROVIDER_SUCCESS,
  DELETE_MASTER_PROVIDER_FAILED,
  GET_SUPPLIER_PRODUK_SUCCESS,
  GET_SUPPLIER_PRODUK_FAILED,
  GET_SUPPLIER_PROVIDER_SUCCESS,
  GET_SUPPLIER_PROVIDER_FAILED,
  GET_SET_PRODUK_BY_CODE_FAILED,
  GET_SET_PRODUK_BY_CODE_SUCCESS,
  FORM_PROVIDER_ADD_DATA,
  FORM_PROVIDER_EDIT_DATA_SUCCESS,
  FORM_PROVIDER_EDIT_DATA_FAILED,
  FORM_TIPE_ADD_DATA,
  FORM_TIPE_EDIT_DATA_SUCCESS,
  FORM_TIPE_EDIT_DATA_FAILED,
  FORM_SUBTIPE_ADD_DATA,
  FORM_SUBTIPE_EDIT_DATA_SUCCESS,
  FORM_SUBTIPE_EDIT_DATA_FAILED,
  FORM_KATEGORI_ADD_DATA,
  FORM_KATEGORI_EDIT_DATA_SUCCESS,
  FORM_KATEGORI_EDIT_DATA_FAILED,
  FORM_PRODUK_ADD_DATA,
  SET_FAILED_EDIT_KODE_INADD_SUCCESS,
  SET_FAILED_EDIT_KODE_INADD_FAILED,
  SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS,
  SET_FAILED_EDIT_KODE_INDETAIL_FAILED,
  ADD_PRODUK_MARGIN_SUCCESS,
  ADD_PRODUK_MARGIN_FAILED,
  SET_MAINTENANCE_PRODUK_SUCCESS,
  SET_MAINTENANCE_PRODUK_FAILED,
  GET_MAINTENANCE_PRODUK_SUCCESS,
  GET_MAINTENANCE_PRODUK_FAILED,
  SET_PREMIUM_MASTER_PRODUK_SUCCESS,
  SET_PREMIUM_MASTER_PRODUK_FAILED,
  GET_MASTER_SUPPLIER_V2,
  GET_MASTER_SUPPLIER_V2_SUCCESS,
  GET_MASTER_SUPPLIER_V2_FAILED,
  GET_MASTER_PRODUK_V2,
  GET_MASTER_PRODUK_V2_SUCCESS,
  GET_MASTER_PRODUK_V2_FAILED,
  GET_MASTER_PRODUK_TYPE_V2,
  GET_MASTER_PRODUK_TYPE_V2_SUCCESS,
  GET_MASTER_PRODUK_TYPE_V2_FAILED,
  GET_MASTER_PRODUK_TYPE_DETAIL_V2,
  GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS,
  GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED,
  GET_MASTER_PRODUK_PROVIDER_V2,
  GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS,
  GET_MASTER_PRODUK_PROVIDER_V2_FAILED,
  GET_MASTER_PRODUK_CATEGORY_V2,
  GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS,
  GET_MASTER_PRODUK_CATEGORY_V2_FAILED,
  ADD_PRODUCT_DETAIL,
  ADD_PRODUCT_DETAIL_SUCCESS,
  ADD_PRODUCT_DETAIL_FAILED,
  SET_SEARCH_TEXT,
  SET_SEARCH_TEXT_ENTER,
  SET_SEARCH_TEXT_OPTION,
} from "./constants";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API;
const apiToken = process.env.REACT_APP_APITOKEN;

export const getListProduk =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/list`,
      params: param,
      resType: "list",
      typeSuccess: GET_LIST_PRODUK_SUCCESS,
      typeFailed: GET_LIST_PRODUK_FAILED,
      type: GET_LIST_PRODUK,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/list_count`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_LIST_PRODUK_SUCCESS,
        typeFailed: GET_TOTAL_LIST_PRODUK_FAILED,
      });
    }
  };

export const syncProduk = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/sync_product_local`,
    resType: "description",
    typeSuccess: SYNC_PRODUCT_SUCCESS,
    typeFailed: SYNC_PRODUCT_FAILED,
  });
};

export const getDetailProduk = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/detail/${id}`,
    resType: "one",
    typeSuccess: GET_PRODUK_EDIT_DATA_SUCCESS,
    typeFailed: GET_PRODUK_EDIT_DATA_FAILED,
  });
};

export const deleteProduk = (id, isDelete) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/product/delete/${id}`,
    deleteState: isDelete,
    resType: "delete",
    typeSuccess: DELETE_PRODUK_SUCCESS,
    typeFailed: DELETE_PRODUK_FAILED,
  });
};

export const setStatusProduk = (id, status) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/update_status/${id}`,
    data: { status: status },
    resRequest: { code: id, status: status },
    resType: "res-request",
    typeSuccess: SET_STATUS_PRODUK_SUCCESS,
    typeFailed: SET_STATUS_PRODUK_FAILED,
    notify: true,
  });
};

export const updateProduk = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/product/update`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PRODUK_SUCCESS,
    typeFailed: ADD_PRODUK_FAILED,
    notify: true,
  });
};

export const updateProdukMargin = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/custom-margin/update`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PRODUK_MARGIN_SUCCESS,
    typeFailed: ADD_PRODUK_MARGIN_FAILED,
  });
};

export const getListSetProduk =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/product/listSupplierDetail`,
      params: param,
      resType: "list",
      typeSuccess: GET_LIST_SET_PRODUK_SUCCESS,
      typeFailed: GET_LIST_SET_PRODUK_FAILED,
      type: GET_LIST_SET_PRODUK,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/product/listSupplierDetailCount`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_LIST_SET_PRODUK_SUCCESS,
        typeFailed: GET_TOTAL_LIST_SET_PRODUK_FAILED,
      });
    }
  };

export const getSetProdukSingleData = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/findSupplierByCode`,
    params: { kodeproduk: id },
    resType: "one",
    typeSuccess: GET_SET_PRODUK_BY_CODE_SUCCESS,
    typeFailed: GET_SET_PRODUK_BY_CODE_FAILED,
  });
};

export const setTrueFailedEditKodeSupplierInAdd = () => async (dispatch) => {
  dispatch({
    type: SET_FAILED_EDIT_KODE_INADD_FAILED,
    payload: true,
  });
};

export const setTrueFailedEditKodeSupplierInDetail = () => async (dispatch) => {
  dispatch({
    type: SET_FAILED_EDIT_KODE_INDETAIL_FAILED,
    payload: true,
  });
};

export const changeKodeSupplierInAdd = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/findSupplierByCode`,
    params: { kodeproduk: id },
    resRequest: false,
    resFailed1: true,
    resFailed2: true,
    resType: "res-request",
    typeSuccess: SET_FAILED_EDIT_KODE_INADD_SUCCESS,
    typeFailed: SET_FAILED_EDIT_KODE_INADD_FAILED,
  });
};

export const changeKodeSupplierInDetail = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/product/updateCodeSupplier`,
    data: values,
    resRequest: false,
    resFailed1: true,
    resFailed2: true,
    resType: "res-request",
    typeSuccess: SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS,
    typeFailed: SET_FAILED_EDIT_KODE_INDETAIL_FAILED,
    notify: true,
  });
};

export const addProduk = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/product/add/newproduct`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PRODUK_SUCCESS,
    typeFailed: ADD_PRODUK_FAILED,
    notify: true,
  });
};

export const addProdukMargin = (values) => async (dispatch) => {
  console.log("margin: " + JSON.stringify(values));
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/custom-margin/insert`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PRODUK_MARGIN_SUCCESS,
    typeFailed: ADD_PRODUK_MARGIN_FAILED,
  });
};

export const syncSetProduk = () => async (dispatch) => {
  console.log("action sync");
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/sync_products_supplier`,
    resType: "description",
    typeSuccess: SYNC_SET_PRODUCT_SUCCESS,
    typeFailed: SYNC_SET_PRODUCT_FAILED,
  });
};

export const getMasterProduk = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/list`,
    resType: "list",
    typeFailed: GET_MASTER_PRODUK_FAILED,
    typeSuccess: GET_MASTER_PRODUK_SUCCESS,
    type: GET_MASTER_PRODUK,
  });
};

export const getMasterSupplierV2 = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listSupplier`,
    resType: "list",
    typeFailed: GET_MASTER_SUPPLIER_V2_FAILED,
    typeSuccess: GET_MASTER_SUPPLIER_V2_SUCCESS,
    type: GET_MASTER_SUPPLIER_V2,
  });
};

export const getMasterProdukV2 = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProduct`,
    resType: "list",
    typeFailed: GET_MASTER_PRODUK_V2_FAILED,
    typeSuccess: GET_MASTER_PRODUK_V2_SUCCESS,
    type: GET_MASTER_PRODUK_V2,
  });
};

export const getMasterProdukTypeV2 = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProductType/${id}`,
    resType: "list",
    typeFailed: GET_MASTER_PRODUK_TYPE_V2_FAILED,
    typeSuccess: GET_MASTER_PRODUK_TYPE_V2_SUCCESS,
    type: GET_MASTER_PRODUK_TYPE_V2,
  });
};

export const getMasterProdukTypeDetailV2 = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProductTypeDetail/${id}`,
    resType: "list",
    typeFailed: GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED,
    typeSuccess: GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS,
    type: GET_MASTER_PRODUK_TYPE_DETAIL_V2,
  });
};

export const getMasterProdukProviderV2 = (formData) => async (dispatch) => {
  let param = {};
  for (const pair of formData.entries()) {
    param[pair[0]] = pair[1];
  }

  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProductProvider`,
    params: param,
    resType: "list",
    typeFailed: GET_MASTER_PRODUK_PROVIDER_V2_FAILED,
    typeSuccess: GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS,
    type: GET_MASTER_PRODUK_PROVIDER_V2,
  });
};

export const getMasterProdukCategoryV2 = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProductCategory/${id}`,
    resType: "list",
    typeFailed: GET_MASTER_PRODUK_CATEGORY_V2_FAILED,
    typeSuccess: GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS,
    type: GET_MASTER_PRODUK_CATEGORY_V2,
  });
};

export const setPremiumMasterProduk = (id, status) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/product/change-premium-status`,
    data: {
      id_product: id,
      is_premium: status,
    },
    resRequest: {
      id_product: id,
      is_premium: status,
    },
    resType: "res-request",
    typeSuccess: SET_PREMIUM_MASTER_PRODUK_SUCCESS,
    typeFailed: SET_PREMIUM_MASTER_PRODUK_FAILED,
    notify: true,
  });
};

export const getMasterProvider = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/provider/list`,
    resType: "list",
    type: GET_MASTER_PROVIDER,
    typeSuccess: GET_MASTER_PROVIDER_SUCCESS,
    typeFailed: GET_MASTER_PROVIDER_FAILED,
  });
};

export const getMasterTipe = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/type/list`,
    resType: "list",
    type: GET_MASTER_TIPE,
    typeSuccess: GET_MASTER_TIPE_SUCCESS,
    typeFailed: GET_MASTER_TIPE_FAILED,
  });
};

export const getMasterSubTipe = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/type_detail/list`,
    resType: "list",
    type: GET_MASTER_SUBTIPE,
    typeSuccess: GET_MASTER_SUBTIPE_SUCCESS,
    typeFailed: GET_MASTER_SUBTIPE_FAILED,
  });
};

export const getMasterKategori = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/category/list`,
    resType: "list",
    type: GET_MASTER_KATEGORI,
    typeSuccess: GET_MASTER_KATEGORI_SUCCESS,
    typeFailed: GET_MASTER_KATEGORI_FAILED,
  });
};

export const getSupplierProduk = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProductSupplier`,
    resType: "list",
    typeSuccess: GET_SUPPLIER_PRODUK_SUCCESS,
    typeFailed: GET_SUPPLIER_PRODUK_FAILED,
  });
};

// ke bawah belum di tes

export const getSupplierProvider = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product/listProviderSupplier`,
    resType: "list",
    typeSuccess: GET_SUPPLIER_PROVIDER_SUCCESS,
    typeFailed: GET_SUPPLIER_PROVIDER_FAILED,
  });
};

export const searchSetData = (value) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/search_set_data`, {
      headers: {
        "Api-Token": apiToken,
        "Kioser-Token": localStorage.getItem("token"),
      },
      params: {
        value: value,
      },
    });
    let response = res.data.data;
    if (
      res.status === 200 &&
      res.data.responseCode === 1000 &&
      response.produk
    ) {
      dispatch({
        type: GET_MASTER_PRODUK_SUCCESS,
        payload: response.produk,
      });
    } else {
      dispatch({
        type: GET_MASTER_PRODUK_FAILED,
        payload: res.data.responseDescription,
      });
      openNotificationWithIcon(
        "error",
        res.data.responseCode.toString(),
        res.data.responseDescription
      );
    }
    if (
      res.status === 200 &&
      res.data.responseCode === 1000 &&
      response.provider
    ) {
      dispatch({
        type: GET_MASTER_PROVIDER_SUCCESS,
        payload: response.provider,
      });
    } else {
      dispatch({
        type: GET_MASTER_PROVIDER_FAILED,
        payload: res.data.responseDescription,
      });
      openNotificationWithIcon(
        "error",
        res.data.responseCode.toString(),
        res.data.responseDescription
      );
    }
    if (res.status === 200 && res.data.responseCode === 1000 && response.type) {
      dispatch({
        type: GET_MASTER_TIPE_SUCCESS,
        payload: response.type,
      });
    } else {
      dispatch({
        type: GET_MASTER_TIPE_FAILED,
        payload: res.data.responseDescription,
      });
      openNotificationWithIcon(
        "error",
        res.data.responseCode.toString(),
        res.data.responseDescription
      );
    }
    if (
      res.status === 200 &&
      res.data.responseCode === 1000 &&
      response.type_detail
    ) {
      dispatch({
        type: GET_MASTER_SUBTIPE_SUCCESS,
        payload: response.type_detail,
      });
    } else {
      dispatch({
        type: GET_MASTER_SUBTIPE_FAILED,
        payload: res.data.responseDescription,
      });
      openNotificationWithIcon(
        "error",
        res.data.responseCode.toString(),
        res.data.responseDescription
      );
    }
    if (
      res.status === 200 &&
      res.data.responseCode === 1000 &&
      response.category
    ) {
      dispatch({
        type: GET_MASTER_KATEGORI_SUCCESS,
        payload: response.category,
      });
    } else {
      dispatch({
        type: GET_MASTER_KATEGORI_FAILED,
        payload: res.data.responseDescription,
      });
      openNotificationWithIcon(
        "error",
        res.data.responseCode.toString(),
        res.data.responseDescription
      );
    }
  } catch (error) {
    dispatch({
      type: SEARCH_SETDATA_FAILED,
      payload: error.response.data,
    });
  }
};

export const addMasterProvider = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/provider/insert`,
    data: values,
    resType: "description",
    typeSuccess: ADD_MASTER_PROVIDER_SUCCESS,
    typeFailed: ADD_MASTER_PROVIDER_FAILED,
    notify: true,
  });
};

export const addMasterTipe = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/type/insert`,
    data: values,
    resType: "description",
    typeSuccess: ADD_MASTER_TIPE_SUCCESS,
    typeFailed: ADD_MASTER_TIPE_FAILED,
    notify: true,
  });
};

export const addMasterSubTipe = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/type_detail/insert`,
    data: values,
    resType: "description",
    typeSuccess: ADD_MASTER_SUBTIPE_SUCCESS,
    typeFailed: ADD_MASTER_SUBTIPE_FAILED,
    notify: true,
  });
};

export const addMasterKategori = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/category/insert`,
    data: values,
    resType: "description",
    typeSuccess: ADD_MASTER_KATEGORI_SUCCESS,
    typeFailed: ADD_MASTER_KATEGORI_FAILED,
    notify: true,
  });
};

export const formProdukAddData = () => {
  return {
    type: FORM_PRODUK_ADD_DATA,
  };
};

export const formProviderAddData = () => {
  return {
    type: FORM_PROVIDER_ADD_DATA,
  };
};

export const formProviderEditData = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/provider/detail/${id}`,
    resType: "one",
    typeSuccess: FORM_PROVIDER_EDIT_DATA_SUCCESS,
    typeFailed: FORM_PROVIDER_EDIT_DATA_FAILED,
  });
};

export const formTipeAddData = () => {
  return {
    type: FORM_TIPE_ADD_DATA,
  };
};

export const formTipeEditData = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/type/detail/${id}`,
    resType: "one",
    typeSuccess: FORM_TIPE_EDIT_DATA_SUCCESS,
    typeFailed: FORM_TIPE_EDIT_DATA_FAILED,
  });
};

export const formSubTipeAddData = () => {
  return {
    type: FORM_SUBTIPE_ADD_DATA,
  };
};

export const formSubTipeEditData = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/type_detail/detail/${id}`,
    resType: "one",
    typeSuccess: FORM_SUBTIPE_EDIT_DATA_SUCCESS,
    typeFailed: FORM_SUBTIPE_EDIT_DATA_FAILED,
  });
};

export const formKategoriAddData = () => {
  return {
    type: FORM_KATEGORI_ADD_DATA,
  };
};

export const formKategoriEditData = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/category/detail/${id}`,
    resType: "one",
    typeSuccess: FORM_KATEGORI_EDIT_DATA_SUCCESS,
    typeFailed: FORM_KATEGORI_EDIT_DATA_FAILED,
  });
};

export const updateMasterProvider = (id, formData) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/provider/update/${id}`,
    data: formData,
    resType: "description",
    typeSuccess: UPDATE_MASTER_PROVIDER_SUCCESS,
    typeFailed: UPDATE_MASTER_PROVIDER_FAILED,
    notify: true,
  });
};

export const updateMasterTipe = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/type/update/${values.id_product_type}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_MASTER_TIPE_SUCCESS,
    typeFailed: UPDATE_MASTER_TIPE_FAILED,
    notify: true,
  });
};

export const updateMasterSubTipe = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/type_detail/update/${values.id_product_type_detail}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_MASTER_SUBTIPE_SUCCESS,
    typeFailed: UPDATE_MASTER_SUBTIPE_FAILED,
    notify: true,
  });
};

export const updateMasterKategori = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/category/update/${values.id_product_category}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_MASTER_KATEGORI_SUCCESS,
    typeFailed: UPDATE_MASTER_KATEGORI_FAILED,
    notify: true,
  });
};

export const deleteProvider =
  (id_product_provider, id_product) => async (dispatch) => {
    baseAction({
      dispatch: dispatch,
      method: "delete",
      url: `${baseUrl}/provider/delete`,
      params: {
        id_product_provider: id_product_provider,
        id_product: id_product,
      },
      resRequest: { id_product_provider, id_product },
      resType: "res-request",
      typeSuccess: DELETE_MASTER_PROVIDER_SUCCESS,
      typeFailed: DELETE_MASTER_PROVIDER_FAILED,
      notify: true,
    });
  };

export const deleteTipe = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/type/delete`,
    params: {
      id_product_type: id,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_MASTER_TIPE_SUCCESS,
    typeFailed: DELETE_MASTER_TIPE_FAILED,
    notify: true,
  });
};

export const deleteSubTipe = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/type_detail/delete`,
    params: {
      id_product_type_detail: id,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_MASTER_SUBTIPE_SUCCESS,
    typeFailed: DELETE_MASTER_SUBTIPE_FAILED,
    notify: true,
  });
};

export const deleteKategori = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/category/delete`,
    params: {
      id_product_category: id,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_MASTER_KATEGORI_SUCCESS,
    typeFailed: DELETE_MASTER_KATEGORI_FAILED,
    notify: true,
  });
};

export const setMaintenanceProduct = (status) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/info_page/maintenance`,
    data: {
      type: "product",
      is_maintenance: status,
      category: "maintenance-noNotif",
    },
    resRequest: { type: "product", status: status },
    resType: "res-request",
    typeSuccess: SET_MAINTENANCE_PRODUK_SUCCESS,
    typeFailed: SET_MAINTENANCE_PRODUK_FAILED,
    notify: true,
  });
};

export const getMaintenanceProduct = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/info_page/maintenance_status`,
    data: { type: "product" },
    resType: "one",
    typeSuccess: GET_MAINTENANCE_PRODUK_SUCCESS,
    typeFailed: GET_MAINTENANCE_PRODUK_FAILED,
    notify: true,
  });
};

export const addProductDetail = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/product/create_product_detail`,
    data: values,
    resType: "description",
    type: ADD_PRODUCT_DETAIL,
    typeSuccess: ADD_PRODUCT_DETAIL_SUCCESS,
    typeFailed: ADD_PRODUCT_DETAIL_FAILED,
    notify: true,
  });
};

export const setSearchText = (text) => {
  return {
    type: SET_SEARCH_TEXT,
    payload: text,
  };
};

export const setSearchTextEnter = (text) => {
  return {
    type: SET_SEARCH_TEXT_ENTER,
    payload: text,
  };
};

export const setSearchTextOption = (text) => {
  return {
    type: SET_SEARCH_TEXT_OPTION,
    payload: text,
  };
};
