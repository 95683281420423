export const GET_PROMO_FLASHSALE = "GET_PROMO_FLASHSALE";
export const GET_PROMO_FLASHSALE_SUCCESS = "GET_PROMO_FLASHSALE_SUCCESS";
export const GET_PROMO_FLASHSALE_FAILED = "GET_PROMO_FLASHSALE_FAILED";
export const GET_TOTAL_PROMO_FLASHSALE_SUCCESS =
  "GET_TOTAL_PROMO_FLASHSALE_SUCCESS";
export const GET_TOTAL_PROMO_FLASHSALE_FAILED =
  "GET_TOTAL_PROMO_FLASHSALE_FAILED";
export const LOADING_FLASHSALE_ITEM = "LOADING_FLASHSALE_ITEM";
export const FORM_PROMO_FLASHSALE_ADD_DATA = "FORM_PROMO_FLASHSALE_ADD_DATA";
export const ADD_PROMO_FLASHSALE_SUCCESS = "ADD_PROMO_FLASHSALE_SUCCESS";
export const ADD_PROMO_FLASHSALE_FAILED = "ADD_PROMO_FLASHSALE_FAILED";
export const GET_DETAIL_PROMO_FLASHSALE =
  "GET_DETAIL_PROMO_FLASHSALE";
  export const GET_DETAIL_PROMO_FLASHSALE_SUCCESS =
  "GET_DETAIL_PROMO_FLASHSALE_SUCCESS";
export const GET_DETAIL_PROMO_FLASHSALE_FAILED =
  "GET_DETAIL_PROMO_FLASHSALE_FAILED";
export const UPDATE_PROMO_FLASHSALE_SUCCESS = "UPDATE_PROMO_FLASHSALE_SUCCESS";
export const UPDATE_PROMO_FLASHSALE_FAILED = "UPDATE_PROMO_FLASHSALE_FAILED";
export const PUBLISH_PROMO_FLASHSALE_SUCCESS =
  "PUBLISH_PROMO_FLASHSALE_SUCCESS";
export const PUBLISH_PROMO_FLASHSALE_FAILED = "PUBLISH_PROMO_FLASHSALE_FAILED";
export const UNPUBLISH_PROMO_FLASHSALE_SUCCESS =
  "UNPUBLISH_PROMO_FLASHSALE_SUCCESS";
export const UNPUBLISH_PROMO_FLASHSALE_FAILED =
  "UNPUBLISH_PROMO_FLASHSALE_FAILED";
export const DUPLICATE_PROMO_FLASHSALE_SUCCESS =
  "DUPLICATE_PROMO_FLASHSALE_SUCCESS";
export const DUPLICATE_PROMO_FLASHSALE_FAILED =
  "DUPLICATE_PROMO_FLASHSALE_FAILED";
export const DELETE_PROMO_FLASHSALE_SUCCESS = "DELETE_PROMO_FLASHSALE_SUCCESS";
export const DELETE_PROMO_FLASHSALE_FAILED = "DELETE_PROMO_FLASHSALE_FAILED";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILED = "GET_ALL_PRODUCT_FAILED";
export const GET_PROVIDER_BY_PRODUCT_SUCCESS =
  "GET_PROVIDER_BY_PRODUCT_SUCCESS";
export const GET_PROVIDER_BY_PRODUCT_FAILED = "GET_PROVIDER_BY_PRODUCT_FAILED";
export const GET_TYPE_BY_PRODUCT_SUCCESS = "GET_TYPE_BY_PRODUCT_SUCCESS";
export const GET_TYPE_BY_PRODUCT_FAILED = "GET_TYPE_BY_PRODUCT_FAILED";
export const GET_SUBTYPE_BY_PRODUCT_SUCCESS = "GET_SUBTYPE_BY_PRODUCT_SUCCESS";
export const GET_SUBTYPE_BY_PRODUCT_FAILED = "GET_SUBTYPE_BY_PRODUCT_FAILED";
export const GET_CATEGORY_BY_PROVIDER_SUCCESS =
  "GET_CATEGORY_BY_PROVIDER_SUCCESS";
export const GET_CATEGORY_BY_PROVIDER_FAILED =
  "GET_CATEGORY_BY_PROVIDER_FAILED";
export const GET_CAPTION_SUCCESS = "GET_CAPTION_SUCCESS";
export const GET_CAPTION_FAILED = "GET_CAPTION_FAILED";

export const GET_PROMO_SPESIAL = "GET_PROMO_SPESIAL";
export const GET_PROMO_SPESIAL_SUCCESS = "GET_PROMO_SPESIAL_SUCCESS";
export const GET_PROMO_SPESIAL_FAILED = "GET_PROMO_SPESIAL_FAILED";
export const GET_TOTAL_PROMO_SPESIAL_SUCCESS =
  "GET_TOTAL_PROMO_SPESIAL_SUCCESS";
export const GET_TOTAL_PROMO_SPESIAL_FAILED = "GET_TOTAL_PROMO_SPESIAL_FAILED";
export const LOADING_SPESIAL_ITEM = "LOADING_SPESIAL_ITEM";
export const FORM_PROMO_SPESIAL_ADD_DATA = "FORM_PROMO_SPESIAL_ADD_DATA";
export const ADD_PROMO_SPESIAL_SUCCESS = "ADD_PROMO_SPESIAL_SUCCESS";
export const ADD_PROMO_SPESIAL_FAILED = "ADD_PROMO_SPESIAL_FAILED";
export const GET_DETAIL_PROMO_SPESIAL =
  "GET_DETAIL_PROMO_SPESIAL";
  export const GET_DETAIL_PROMO_SPESIAL_SUCCESS =
  "GET_DETAIL_PROMO_SPESIAL_SUCCESS";
export const GET_DETAIL_PROMO_SPESIAL_FAILED =
  "GET_DETAIL_PROMO_SPESIAL_FAILED";
export const UPDATE_PROMO_SPESIAL_SUCCESS = "UPDATE_PROMO_SPESIAL_SUCCESS";
export const UPDATE_PROMO_SPESIAL_FAILED = "UPDATE_PROMO_SPESIAL_FAILED";
export const PUBLISH_PROMO_SPESIAL_SUCCESS = "PUBLISH_PROMO_SPESIAL_SUCCESS";
export const PUBLISH_PROMO_SPESIAL_FAILED = "PUBLISH_PROMO_SPESIAL_FAILED";
export const UNPUBLISH_PROMO_SPESIAL_SUCCESS =
  "UNPUBLISH_PROMO_SPESIAL_SUCCESS";
export const UNPUBLISH_PROMO_SPESIAL_FAILED = "UNPUBLISH_PROMO_SPESIAL_FAILED";
export const DUPLICATE_PROMO_SPESIAL_SUCCESS =
  "DUPLICATE_PROMO_SPESIAL_SUCCESS";
export const DUPLICATE_PROMO_SPESIAL_FAILED = "DUPLICATE_PROMO_SPESIAL_FAILED";
export const DELETE_PROMO_SPESIAL_SUCCESS = "DELETE_PROMO_SPESIAL_SUCCESS";
export const DELETE_PROMO_SPESIAL_FAILED = "DELETE_PROMO_SPESIAL_FAILED";

export const GET_PROMO_KUPON = "GET_PROMO_KUPON";
export const GET_PROMO_KUPON_SUCCESS = "GET_PROMO_KUPON_SUCCESS";
export const GET_PROMO_KUPON_FAILED = "GET_PROMO_KUPON_FAILED";
export const GET_TOTAL_PROMO_KUPON_SUCCESS = "GET_TOTAL_PROMO_KUPON_SUCCESS";
export const GET_TOTAL_PROMO_KUPON_FAILED = "GET_TOTAL_PROMO_KUPON_FAILED";
export const LOADING_KUPON_ITEM = "LOADING_KUPON_ITEM";
export const FORM_PROMO_KUPON_ADD_DATA = "FORM_PROMO_KUPON_ADD_DATA";
export const ADD_PROMO_KUPON_SUCCESS = "ADD_PROMO_KUPON_SUCCESS";
export const ADD_PROMO_KUPON_FAILED = "ADD_PROMO_KUPON_FAILED";
export const GET_DETAIL_PROMO_KUPON = "GET_DETAIL_PROMO_KUPON";
export const GET_DETAIL_PROMO_KUPON_SUCCESS = "GET_DETAIL_PROMO_KUPON_SUCCESS";
export const GET_DETAIL_PROMO_KUPON_FAILED = "GET_DETAIL_PROMO_KUPON_FAILED";
export const UPDATE_PROMO_KUPON_SUCCESS = "UPDATE_PROMO_KUPON_SUCCESS";
export const UPDATE_PROMO_KUPON_FAILED = "UPDATE_PROMO_KUPON_FAILED";
export const PUBLISH_PROMO_KUPON_SUCCESS = "PUBLISH_PROMO_KUPON_SUCCESS";
export const PUBLISH_PROMO_KUPON_FAILED = "PUBLISH_PROMO_KUPON_FAILED";
export const UNPUBLISH_PROMO_KUPON_SUCCESS = "UNPUBLISH_PROMO_KUPON_SUCCESS";
export const UNPUBLISH_PROMO_KUPON_FAILED = "UNPUBLISH_PROMO_KUPON_FAILED";
export const DUPLICATE_PROMO_KUPON_SUCCESS = "DUPLICATE_PROMO_KUPON_SUCCESS";
export const DUPLICATE_PROMO_KUPON_FAILED = "DUPLICATE_PROMO_KUPON_FAILED";
export const DELETE_PROMO_KUPON_SUCCESS = "DELETE_PROMO_KUPON_SUCCESS";
export const DELETE_PROMO_KUPON_FAILED = "DELETE_PROMO_KUPON_FAILED";

export const GET_INFORMASI_PEMBERITAHUAN = "GET_INFORMASI_PEMBERITAHUAN";
export const GET_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "GET_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const GET_INFORMASI_PEMBERITAHUAN_FAILED =
  "GET_INFORMASI_PEMBERITAHUAN_FAILED";
export const GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED =
  "GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED";
export const LOADING_PEMBERITAHUAN_ITEM = "LOADING_PEMBERITAHUAN_ITEM";
export const FORM_INFORMASI_PEMBERITAHUAN_ADD_DATA =
  "FORM_INFORMASI_PEMBERITAHUAN_ADD_DATA";
export const ADD_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "ADD_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const ADD_INFORMASI_PEMBERITAHUAN_FAILED =
  "ADD_INFORMASI_PEMBERITAHUAN_FAILED";
export const GET_DETAIL_INFORMASI_PEMBERITAHUAN =
  "GET_DETAIL_INFORMASI_PEMBERITAHUAN";
export const GET_DETAIL_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "GET_DETAIL_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const GET_DETAIL_INFORMASI_PEMBERITAHUAN_FAILED =
  "GET_DETAIL_INFORMASI_PEMBERITAHUAN_FAILED";
export const UPDATE_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "UPDATE_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const UPDATE_INFORMASI_PEMBERITAHUAN_FAILED =
  "UPDATE_INFORMASI_PEMBERITAHUAN_FAILED";
export const PUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "PUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const PUBLISH_INFORMASI_PEMBERITAHUAN_FAILED =
  "PUBLISH_INFORMASI_PEMBERITAHUAN_FAILED";
export const UNPUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "UNPUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const UNPUBLISH_INFORMASI_PEMBERITAHUAN_FAILED =
  "UNPUBLISH_INFORMASI_PEMBERITAHUAN_FAILED";
export const DUPLICATE_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "DUPLICATE_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const DUPLICATE_INFORMASI_PEMBERITAHUAN_FAILED =
  "DUPLICATE_INFORMASI_PEMBERITAHUAN_FAILED";
export const DELETE_INFORMASI_PEMBERITAHUAN_SUCCESS =
  "DELETE_INFORMASI_PEMBERITAHUAN_SUCCESS";
export const DELETE_INFORMASI_PEMBERITAHUAN_FAILED =
  "DELETE_INFORMASI_PEMBERITAHUAN_FAILED";

export const GET_INFORMASI_MENARIK = "GET_INFORMASI_MENARIK";
export const GET_INFORMASI_MENARIK_SUCCESS = "GET_INFORMASI_MENARIK_SUCCESS";
export const GET_INFORMASI_MENARIK_FAILED = "GET_INFORMASI_MENARIK_FAILED";
export const GET_TOTAL_INFORMASI_MENARIK_SUCCESS =
  "GET_TOTAL_INFORMASI_MENARIK_SUCCESS";
export const GET_TOTAL_INFORMASI_MENARIK_FAILED =
  "GET_TOTAL_INFORMASI_MENARIK_FAILED";
export const LOADING_MENARIK_ITEM = "LOADING_MENARIK_ITEM";
export const FORM_INFORMASI_MENARIK_ADD_DATA =
  "FORM_INFORMASI_MENARIK_ADD_DATA";
export const ADD_INFORMASI_MENARIK_SUCCESS = "ADD_INFORMASI_MENARIK_SUCCESS";
export const ADD_INFORMASI_MENARIK_FAILED = "ADD_INFORMASI_MENARIK_FAILED";
export const GET_DETAIL_INFORMASI_MENARIK =
  "GET_DETAIL_INFORMASI_MENARIK";
  export const GET_DETAIL_INFORMASI_MENARIK_SUCCESS =
  "GET_DETAIL_INFORMASI_MENARIK_SUCCESS";
export const GET_DETAIL_INFORMASI_MENARIK_FAILED =
  "GET_DETAIL_INFORMASI_MENARIK_FAILED";
export const UPDATE_INFORMASI_MENARIK_SUCCESS =
  "UPDATE_INFORMASI_MENARIK_SUCCESS";
export const UPDATE_INFORMASI_MENARIK_FAILED =
  "UPDATE_INFORMASI_MENARIK_FAILED";
export const PUBLISH_INFORMASI_MENARIK_SUCCESS =
  "PUBLISH_INFORMASI_MENARIK_SUCCESS";
export const PUBLISH_INFORMASI_MENARIK_FAILED =
  "PUBLISH_INFORMASI_MENARIK_FAILED";
export const UNPUBLISH_INFORMASI_MENARIK_SUCCESS =
  "UNPUBLISH_INFORMASI_MENARIK_SUCCESS";
export const UNPUBLISH_INFORMASI_MENARIK_FAILED =
  "UNPUBLISH_INFORMASI_MENARIK_FAILED";
export const DUPLICATE_INFORMASI_MENARIK_SUCCESS =
  "DUPLICATE_INFORMASI_MENARIK_SUCCESS";
export const DUPLICATE_INFORMASI_MENARIK_FAILED =
  "DUPLICATE_INFORMASI_MENARIK_FAILED";
export const DELETE_INFORMASI_MENARIK_SUCCESS =
  "DELETE_INFORMASI_MENARIK_SUCCESS";
export const DELETE_INFORMASI_MENARIK_FAILED =
  "DELETE_INFORMASI_MENARIK_FAILED";

export const GET_INFORMASI_PRODUK = "GET_INFORMASI_PRODUK";
export const GET_INFORMASI_PRODUK_SUCCESS = "GET_INFORMASI_PRODUK_SUCCESS";
export const GET_INFORMASI_PRODUK_FAILED = "GET_INFORMASI_PRODUK_FAILED";
export const GET_TOTAL_INFORMASI_PRODUK_SUCCESS =
  "GET_TOTAL_INFORMASI_PRODUK_SUCCESS";
export const GET_TOTAL_INFORMASI_PRODUK_FAILED =
  "GET_TOTAL_INFORMASI_PRODUK_FAILED";
export const GET_DETAIL_INFORMASI_PRODUK =
  "GET_DETAIL_INFORMASI_PRODUK";
export const GET_DETAIL_INFORMASI_PRODUK_SUCCESS =
  "GET_DETAIL_INFORMASI_PRODUK_SUCCESS";
export const GET_DETAIL_INFORMASI_PRODUK_FAILED =
  "GET_DETAIL_INFORMASI_PRODUK_FAILED";
export const FORM_INFORMASI_PRODUK_ADD_DATA = "FORM_INFORMASI_PRODUK_ADD_DATA";
export const PREVIEW_INFORMASI_PRODUCT_SUCCESS =
  "PREVIEW_INFORMASI_PRODUCT_SUCCESS";
export const PREVIEW_INFORMASI_PRODUCT_FAILED =
  "PREVIEW_INFORMASI_PRODUCT_FAILED";
export const PUBLISH_INFORMASI_PRODUCT_SUCCESS =
  "PUBLISH_INFORMASI_PRODUCT_SUCCESS";
export const PUBLISH_INFORMASI_PRODUCT_FAILED =
  "PUBLISH_INFORMASI_PRODUCT_FAILED";
export const GET_MASTER_CONFIGURATION = "GET_MASTER_CONFIGURATION";
export const GET_MASTER_CONFIGURATION_SUCCESS = "GET_MASTER_CONFIGURATION_SUCCESS";
export const GET_MASTER_CONFIGURATION_FAILED = "GET_MASTER_CONFIGURATION_FAILED";
