import axios from "axios";
import {
  FORM_HEADER,
  HEADER_DEFAULT,
  HEADER_DETAIL_PRODUK,
  HEADER_TAMBAH_PRODUK,
  VERIFIED_LOGIN,
  PREVIOUS_PAGE,
} from "./constants";

const baseUrl = process.env.REACT_APP_API;
const apiToken = process.env.REACT_APP_APITOKEN;

export const setHeader = (val) => async (dispatch) => {
  if (val === "default") {
    dispatch({
      type: HEADER_DEFAULT,
      payload: null,
    });
  } else if (val === "tambahproduk") {
    dispatch({
      type: HEADER_TAMBAH_PRODUK,
      payload: null,
    });
  } else if (val === "detailproduk") {
    dispatch({
      type: HEADER_DETAIL_PRODUK,
      payload: null,
    });
  } else {
    dispatch({
      type: FORM_HEADER,
      payload: null,
    });
  }
};

export const cekToken = () => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}/cek_token`, {
      headers: {
        "Api-Token": apiToken,
        "Kioser-Token": localStorage.getItem("token"),
      },
    });
    if (res.status === 200 && res.data.responseCode === 2072) {
      localStorage.setItem("token", "");
      localStorage.setItem("isAuth", "0");
      localStorage.setItem("nik", "");
      localStorage.setItem("nama", "");
      localStorage.setItem("key", "");
      dispatch({
        type: VERIFIED_LOGIN,
      });
    }
  } catch (error) {
    console.log("axios " + error);
  }
};


export const setPrevPage = (val) => async (dispatch) => {
  dispatch({
    type: PREVIOUS_PAGE,
    payload: val,
  });
}