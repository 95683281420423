import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";
import ModalVerifikasiAkun from "../../../containers/Pelanggan/DetailPelanggan/ModalVerifikasiAkun";
import { useState } from "react";
import {
  bannedPelanggan,
  openBannedPelanggan,
} from "../../../containers/Pelanggan/actions";
import FloatTextArea from "../../FloatForm/FloatTextArea";

const HeaderPelanggan = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reset, setReset] = useState(false);

  const pelanggan = useSelector(
    (state) => state.detailPelanggan.detailPelanggan
  );
  const [modalVisibleVerifikasi, setModalVisibleVerifikasi] = useState(false);

  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { loading } = props;
  const handleBackScreen = async () => {
    await dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    // form.resetFields();
    navigate("/pelanggan/list/semua");
  };

  const openModalVerifikasi = async () => {
    setModalVisibleVerifikasi(true);
  };

  const bannedSubmit = async (values) => {
    await dispatch(bannedPelanggan(values));
    window.setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const blokirPelanggan = (id) => {
    form.resetFields();
    form.setFieldsValue({ id: id });
    console.log("detail pelanggan: " + id);
    confirm({
      title: "Yakin ingin Blokir pelanggan ?",
      icon: null,
      content: (
        <div className="listForm">
          <div className="itemForm">
            <Form form={form} onFinish={bannedSubmit}>
              <Form.Item name="id" style={{ height: 0, marginBottom: 0 }}>
                <Input type="hidden" />
              </Form.Item>
              <FloatTextArea
                row="3"
                label="Keterangan"
                placeholder="Keterangan"
                name="note"
                reset={reset}
                setReset={setReset}
              />
            </Form>
          </div>
        </div>
      ),
      centered: true,
      okText: "Ya, Blokir",
      cancelText: "Tidak",
      async onOk() {
        form.submit();
      },
      onCancel() {
        console.log("Cancel blokir");
      },
    });
  };

  const openBlokirPelanggan = (id) => {
    confirm({
      title: "Yakin ingin Buka Blokir pelanggan ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Buka Blokir",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(openBannedPelanggan(id));
        window.setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      onCancel() {
        console.log("Cancel buka blokir");
      },
    });
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>Detail Pelanggan</h1>
      </div>
      <div className="rightHeader">
        {pelanggan.active === 1 ? (
          <>
            <Button
              danger
              className="btnAct btnActAlert"
              onClick={() => blokirPelanggan(pelanggan.id)}
            >
              Blokir
            </Button>
            <Button
              className="btnAct btnActSubmit"
              loading={loading}
              onClick={() => openModalVerifikasi()}
            >
              Verifikasi
            </Button>
          </>
        ) : pelanggan.active === 2 ? (
          <>
            <Button danger disabled className="btnAct btnActAlert">
              Blokir
            </Button>
            <Button
              className="btnAct btnActSubmit"
              loading={loading}
              onClick={() => openBlokirPelanggan(pelanggan.id)}
            >
              Buka Blokir
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
      <ModalVerifikasiAkun
        id={pelanggan.id}
        openLimit={pelanggan.is_open_limit}
        modalVisible={modalVisibleVerifikasi}
        setModalVisible={setModalVisibleVerifikasi}
      />
    </>
  );
};
export default HeaderPelanggan;
