import { Menu } from "antd";
import SignOut from "./SignOut";

const Account = () => {
  const accountName = localStorage.getItem("nama");
  const accountNik = localStorage.getItem("nik");

  return (
    <Menu>
      <Menu.Item key="account_name">
        <p style={{ fontWeight: "500", margin: 0 }}>{accountName}</p>
        {accountNik}
      </Menu.Item>
      <Menu.Divider />
      <SignOut />
    </Menu>
  );
};

export default Account;
