import { combineReducers } from "redux";
import { globalReducer } from "./containers/Main/reducers";
import { produkReducer } from "./containers/Produk/reducers";
import { depositReducer } from "./containers/Deposit/reducers";
import { publishReducer } from "./containers/Publish/reducers";
import { pelangganReducer } from "./containers/Pelanggan/reducers";
import { settingReducer } from "./containers/Setting/reducers";
import { integrasiReducer } from "./containers/Integrasi/reducers";
import { detailPelangganReducer } from "./containers/Pelanggan/DetailPelanggan/reducers";
import { penggunaReducer } from "./containers/Pengguna/reducers";

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    produk: produkReducer,
    deposit: depositReducer,
    publish: publishReducer,
    detailPelanggan: detailPelangganReducer,
    pelanggan: pelangganReducer,
    integrasi: integrasiReducer,
    pengguna: penggunaReducer,
    setting: settingReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
