import { Button, Form, Input, Upload } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FloatInput from "../../components/FloatForm/FloatInput";
import useBackListener from "../../utils/useBackListener";
import { getDetailSetMetode } from "./actions";
import "../../assets/style/SingleColTemplate.scss";
import { setHeader } from "../Main/actions";
import Forbidden from "../../components/Forbidden";

const DetailSetMetode = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editDataMetode = useSelector((state) => state.deposit.detailSetMetode);
  const editMetode = useSelector((state) => state.deposit.editSetMetode);
  const forbiddenDetailSetMetode = useSelector((state) => state.deposit.forbiddenDetailSetMetode);

  const [upload, setUpload] = useState([]);
  const [metodeTitle, setMetodeTitle] = useState("");
  const [metodeName, setMetodeName] = useState("");

  const { code } = useParams();
  let { form, onFinish, reset, setReset } = props;

  const onChangeImage = ({ fileList }) => {
    setUpload(fileList);
  };

  const handleChangeNumber = (event) => {
    const attribute = event.target.id;
    let value = parseInt(
      event.target.value.replace(",00", "").replace(/\D/g, "")
    );
    if (attribute === "fee") form.setFieldsValue({ fee: value });
    else if (attribute === "fee_lain") form.setFieldsValue({ fee_lain: value });
    else if (attribute === "limit_deposit")
      form.setFieldsValue({ limit_deposit: value });
  };

  useBackListener(({ location }) => {
    console.log("Navigate Back", { location });
    dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    navigate("/deposit/setmetode", { replace: true });
  });

  useEffect(() => {
    dispatch(getDetailSetMetode(code));
  }, [dispatch, code]);

  const imageBeforeUpload = useMemo(
    () => [
      // useMemo suggest by useEffect ESLint
      {
        uid: "metode123",
        status: "done",
        thumbUrl: editDataMetode.img,
        name: editDataMetode.img,
        url: editDataMetode.img,
        originFileObj: {
          arrayBuffer: {},
          lastModified: 1233232332,
          lastModifiedDate: "09 Jul 2020",
          name: editDataMetode.img,
          size: 20000,
          slice: {},
          stream: {},
          text: {},
          type: "image/png",
          uid: "metode123",
          webkitRelativePath: "",
        },
      },
    ],
    [editDataMetode.img]
  );

  useEffect(() => {
    form.setFieldsValue({
      id: code,
      product_name: editDataMetode.name,
      fee: editDataMetode.fee,
      fee_lain: editDataMetode.fee_lain,
      limit_deposit: editDataMetode.limit_deposit,
      description: editDataMetode.description,
    });
    if (editMetode === true) {
      setReset(false);
      form.setFieldsValue({
        image: editDataMetode.img,
      });
      setUpload(imageBeforeUpload);
    } else {
      setReset(true);
      setUpload([]);
    }
    if (editDataMetode.category === "bank") {
      setMetodeTitle("Transfer Bank");
      setMetodeName("Bank");
    } else if (editDataMetode.category === "winpay_virtual_account") {
      setMetodeTitle("Virtual Account");
      setMetodeName("Virtual Account");
    } else if (editDataMetode.category === "qris") {
      setMetodeTitle("QR Code");
      setMetodeName("QR Code");
    } else if (editDataMetode.category === "winpay_modern_store") {
      setMetodeTitle("Gerai Retail");
      setMetodeName("Gerai");
    }
  }, [
    form,
    code,
    editMetode,
    setReset,
    editDataMetode.name,
    editDataMetode.img,
    editDataMetode.fee,
    editDataMetode.fee_lain,
    editDataMetode.limit_deposit,
    editDataMetode.description,
    editDataMetode.category,
    imageBeforeUpload,
  ]);

  return forbiddenDetailSetMetode === true ? (
    <Forbidden />
  ) : (
    <div className="wrapSingleColDetail">
      <div className="listItem">
        <h2>Metode {metodeTitle}</h2>
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="id" style={{ marginBottom: "0px", height: "0px" }}>
            <Input type="hidden" disabled />
          </Form.Item>
          <FloatInput
            label={"Nama " + metodeName}
            placeholder={"Nama " + metodeName}
            name="product_name"
            required
            reset={reset}
            setReset={setReset}
            data={editDataMetode.name}
          />
          <Form.Item name="image">
            <Upload
              fileList={upload}
              onChange={onChangeImage}
              maxCount={1}
              beforeUpload={() => false}
            >
              {upload.length < 1 && <Button>Upload Photo</Button>}
            </Upload>
          </Form.Item>
          <FloatInput
            label="Biaya Admin"
            placeholder="Biaya Admin"
            name="fee"
            prefix="Rp"
            type="change"
            onChange={handleChangeNumber}
            required
            reset={reset}
            setReset={setReset}
            data={editDataMetode.fee}
            always={true}
          />
          <FloatInput
            label="Biaya Lain"
            placeholder="Biaya Lain"
            name="fee_lain"
            prefix="Rp"
            type="change"
            onChange={handleChangeNumber}
            required
            reset={reset}
            setReset={setReset}
            data={editDataMetode.fee_lain}
            always={true}
          />
          <FloatInput
            label="Atur Limit"
            placeholder="Atur Limit"
            name="limit_deposit"
            type="change"
            onChange={handleChangeNumber}
            required
            reset={reset}
            setReset={setReset}
            data={editDataMetode.limit_deposit}
          />
          <FloatInput
            label="Keterangan"
            placeholder="Keterangan"
            name="description"
            reset={reset}
            setReset={setReset}
            data={editDataMetode.description}
          />
        </Form>
      </div>
    </div>
  );
};

export default DetailSetMetode;
