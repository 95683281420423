import baseAction from "../../utils/baseAction";
import basePromiseAction from "../../utils/basePromiseAction";
import {
  ADD_INFORMASI_MENARIK_FAILED,
  ADD_INFORMASI_MENARIK_SUCCESS,
  ADD_INFORMASI_PEMBERITAHUAN_FAILED,
  ADD_INFORMASI_PEMBERITAHUAN_SUCCESS,
  ADD_PROMO_FLASHSALE_FAILED,
  ADD_PROMO_FLASHSALE_SUCCESS,
  ADD_PROMO_KUPON_FAILED,
  ADD_PROMO_KUPON_SUCCESS,
  ADD_PROMO_SPESIAL_FAILED,
  ADD_PROMO_SPESIAL_SUCCESS,
  DELETE_INFORMASI_MENARIK_FAILED,
  DELETE_INFORMASI_MENARIK_SUCCESS,
  DELETE_INFORMASI_PEMBERITAHUAN_FAILED,
  DELETE_INFORMASI_PEMBERITAHUAN_SUCCESS,
  DELETE_PROMO_FLASHSALE_FAILED,
  DELETE_PROMO_FLASHSALE_SUCCESS,
  DELETE_PROMO_KUPON_FAILED,
  DELETE_PROMO_KUPON_SUCCESS,
  DELETE_PROMO_SPESIAL_FAILED,
  DELETE_PROMO_SPESIAL_SUCCESS,
  DUPLICATE_INFORMASI_MENARIK_FAILED,
  DUPLICATE_INFORMASI_MENARIK_SUCCESS,
  DUPLICATE_INFORMASI_PEMBERITAHUAN_FAILED,
  DUPLICATE_INFORMASI_PEMBERITAHUAN_SUCCESS,
  DUPLICATE_PROMO_FLASHSALE_FAILED,
  DUPLICATE_PROMO_FLASHSALE_SUCCESS,
  DUPLICATE_PROMO_KUPON_FAILED,
  DUPLICATE_PROMO_KUPON_SUCCESS,
  DUPLICATE_PROMO_SPESIAL_FAILED,
  DUPLICATE_PROMO_SPESIAL_SUCCESS,
  GET_DETAIL_INFORMASI_MENARIK,
  GET_DETAIL_INFORMASI_PEMBERITAHUAN,
  GET_DETAIL_INFORMASI_PRODUK,
  GET_DETAIL_PROMO_FLASHSALE,
  GET_DETAIL_PROMO_KUPON,
  GET_DETAIL_PROMO_SPESIAL,
  FORM_INFORMASI_MENARIK_ADD_DATA,
  FORM_INFORMASI_PEMBERITAHUAN_ADD_DATA,
  FORM_INFORMASI_PRODUK_ADD_DATA,
  FORM_PROMO_FLASHSALE_ADD_DATA,
  FORM_PROMO_KUPON_ADD_DATA,
  FORM_PROMO_SPESIAL_ADD_DATA,
  GET_ALL_PRODUCT_FAILED,
  GET_ALL_PRODUCT_SUCCESS,
  GET_CAPTION_FAILED,
  GET_CAPTION_SUCCESS,
  GET_CATEGORY_BY_PROVIDER_FAILED,
  GET_CATEGORY_BY_PROVIDER_SUCCESS,
  GET_DETAIL_INFORMASI_MENARIK_FAILED,
  GET_DETAIL_INFORMASI_MENARIK_SUCCESS,
  GET_DETAIL_INFORMASI_PEMBERITAHUAN_FAILED,
  GET_DETAIL_INFORMASI_PEMBERITAHUAN_SUCCESS,
  GET_DETAIL_INFORMASI_PRODUK_FAILED,
  GET_DETAIL_INFORMASI_PRODUK_SUCCESS,
  GET_DETAIL_PROMO_FLASHSALE_FAILED,
  GET_DETAIL_PROMO_FLASHSALE_SUCCESS,
  GET_DETAIL_PROMO_KUPON_FAILED,
  GET_DETAIL_PROMO_KUPON_SUCCESS,
  GET_DETAIL_PROMO_SPESIAL_FAILED,
  GET_DETAIL_PROMO_SPESIAL_SUCCESS,
  GET_INFORMASI_MENARIK,
  GET_INFORMASI_MENARIK_FAILED,
  GET_INFORMASI_MENARIK_SUCCESS,
  GET_INFORMASI_PEMBERITAHUAN,
  GET_INFORMASI_PEMBERITAHUAN_FAILED,
  GET_INFORMASI_PEMBERITAHUAN_SUCCESS,
  GET_INFORMASI_PRODUK,
  GET_INFORMASI_PRODUK_FAILED,
  GET_INFORMASI_PRODUK_SUCCESS,
  GET_PROMO_FLASHSALE,
  GET_PROMO_FLASHSALE_FAILED,
  GET_PROMO_FLASHSALE_SUCCESS,
  GET_PROMO_KUPON,
  GET_PROMO_KUPON_FAILED,
  GET_PROMO_KUPON_SUCCESS,
  GET_PROMO_SPESIAL,
  GET_PROMO_SPESIAL_FAILED,
  GET_PROMO_SPESIAL_SUCCESS,
  GET_PROVIDER_BY_PRODUCT_FAILED,
  GET_PROVIDER_BY_PRODUCT_SUCCESS,
  GET_SUBTYPE_BY_PRODUCT_FAILED,
  GET_SUBTYPE_BY_PRODUCT_SUCCESS,
  GET_TOTAL_INFORMASI_MENARIK_FAILED,
  GET_TOTAL_INFORMASI_MENARIK_SUCCESS,
  GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED,
  GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS,
  GET_TOTAL_INFORMASI_PRODUK_FAILED,
  GET_TOTAL_INFORMASI_PRODUK_SUCCESS,
  GET_TOTAL_PROMO_FLASHSALE_FAILED,
  GET_TOTAL_PROMO_FLASHSALE_SUCCESS,
  GET_TOTAL_PROMO_KUPON_FAILED,
  GET_TOTAL_PROMO_KUPON_SUCCESS,
  GET_TOTAL_PROMO_SPESIAL_FAILED,
  GET_TOTAL_PROMO_SPESIAL_SUCCESS,
  GET_TYPE_BY_PRODUCT_FAILED,
  GET_TYPE_BY_PRODUCT_SUCCESS,
  LOADING_FLASHSALE_ITEM,
  LOADING_KUPON_ITEM,
  LOADING_MENARIK_ITEM,
  LOADING_PEMBERITAHUAN_ITEM,
  LOADING_SPESIAL_ITEM,
  PREVIEW_INFORMASI_PRODUCT_FAILED,
  PREVIEW_INFORMASI_PRODUCT_SUCCESS,
  PUBLISH_INFORMASI_MENARIK_FAILED,
  PUBLISH_INFORMASI_MENARIK_SUCCESS,
  PUBLISH_INFORMASI_PEMBERITAHUAN_FAILED,
  PUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS,
  PUBLISH_INFORMASI_PRODUCT_FAILED,
  PUBLISH_INFORMASI_PRODUCT_SUCCESS,
  PUBLISH_PROMO_FLASHSALE_FAILED,
  PUBLISH_PROMO_FLASHSALE_SUCCESS,
  PUBLISH_PROMO_KUPON_FAILED,
  PUBLISH_PROMO_KUPON_SUCCESS,
  PUBLISH_PROMO_SPESIAL_FAILED,
  PUBLISH_PROMO_SPESIAL_SUCCESS,
  UNPUBLISH_INFORMASI_MENARIK_FAILED,
  UNPUBLISH_INFORMASI_MENARIK_SUCCESS,
  UNPUBLISH_INFORMASI_PEMBERITAHUAN_FAILED,
  UNPUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS,
  UNPUBLISH_PROMO_FLASHSALE_FAILED,
  UNPUBLISH_PROMO_FLASHSALE_SUCCESS,
  UNPUBLISH_PROMO_KUPON_FAILED,
  UNPUBLISH_PROMO_KUPON_SUCCESS,
  UNPUBLISH_PROMO_SPESIAL_FAILED,
  UNPUBLISH_PROMO_SPESIAL_SUCCESS,
  UPDATE_INFORMASI_MENARIK_FAILED,
  UPDATE_INFORMASI_MENARIK_SUCCESS,
  UPDATE_INFORMASI_PEMBERITAHUAN_FAILED,
  UPDATE_INFORMASI_PEMBERITAHUAN_SUCCESS,
  UPDATE_PROMO_FLASHSALE_FAILED,
  UPDATE_PROMO_FLASHSALE_SUCCESS,
  UPDATE_PROMO_KUPON_FAILED,
  UPDATE_PROMO_KUPON_SUCCESS,
  UPDATE_PROMO_SPESIAL_FAILED,
  UPDATE_PROMO_SPESIAL_SUCCESS,
  GET_MASTER_CONFIGURATION,
  GET_MASTER_CONFIGURATION_SUCCESS,
  GET_MASTER_CONFIGURATION_FAILED,
} from "./constants";

const baseUrl = process.env.REACT_APP_API;

export const getPromoFlashsale = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale`,
    params: {
      page: page,
    },
    resType: "list",
    typeSuccess: GET_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_PROMO_FLASHSALE_FAILED,
    type: GET_PROMO_FLASHSALE,
  });
};

export const getTotalPromoFlashsale = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/count`,
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_FLASHSALE_FAILED,
  });
};

export const filterPromoFlashsale = (values, page) => async (dispatch) => {
  console.log("value: " + values);
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale`,
    params: {
      page: page,
      filter_status: values.status,
    },
    resType: "list",
    typeSuccess: GET_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_PROMO_FLASHSALE_FAILED,
    type: GET_PROMO_FLASHSALE,
  });
};

export const filterTotalPromoFlashsale = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/count`,
    params: {
      filter_status: values.status,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_FLASHSALE_FAILED,
  });
};

export const searchPromoFlashsale = (value, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale`,
    params: {
      page: page,
      name: value,
    },
    resType: "list",
    typeSuccess: GET_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_PROMO_FLASHSALE_FAILED,
    type: GET_PROMO_FLASHSALE,
  });
};

export const searchTotalPromoFlashsale = (value) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/count`,
    params: {
      name: value,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_FLASHSALE_FAILED,
  });
};

export const formPromoFlashsaleAddData = () => {
  return {
    type: FORM_PROMO_FLASHSALE_ADD_DATA,
  };
};

export const setLoadingFlashsaleItem = () => {
  return {
    type: LOADING_FLASHSALE_ITEM,
  };
};

export const getAllProductPromo = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/product/list`,
    resType: "list",
    typeSuccess: GET_ALL_PRODUCT_SUCCESS,
    typeFailed: GET_ALL_PRODUCT_FAILED,
  });
};

export const getAllProductFlashsale = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product-flashsale/product`,
    resType: "list",
    typeSuccess: GET_ALL_PRODUCT_SUCCESS,
    typeFailed: GET_ALL_PRODUCT_FAILED,
  });
};

export const getProviderByProduct = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product-flashsale/provider-by-product`,
    params: {
      id_product: id,
    },
    resType: "list",
    typeSuccess: GET_PROVIDER_BY_PRODUCT_SUCCESS,
    typeFailed: GET_PROVIDER_BY_PRODUCT_FAILED,
  });
};

export const getTypeByProduct = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product-flashsale/type`,
    params: {
      id_product: id,
    },
    resType: "list",
    typeSuccess: GET_TYPE_BY_PRODUCT_SUCCESS,
    typeFailed: GET_TYPE_BY_PRODUCT_FAILED,
  });
};

export const getSubTypeByProduct = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product-flashsale/type-by-product`,
    params: {
      id_product: id,
    },
    resType: "list",
    typeSuccess: GET_SUBTYPE_BY_PRODUCT_SUCCESS,
    typeFailed: GET_SUBTYPE_BY_PRODUCT_FAILED,
  });
};

export const getCategoryByProvider = (id) => async (dispatch) => {
  console.log("id provider: " + id);
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/product-flashsale/category-by-provider`,
    params: {
      id_product_provider: id,
    },
    resType: "list",
    typeSuccess: GET_CATEGORY_BY_PROVIDER_SUCCESS,
    typeFailed: GET_CATEGORY_BY_PROVIDER_FAILED,
  });
};

export const getCaptionProduct =
  (id, secondVar = "", secondValue = "") =>
  async (dispatch) => {
    console.log(
      "set getcaption: id:" +
        id +
        ",secondVar:" +
        secondVar +
        ", secondValue:" +
        secondValue
    );
    let param = { id_product_provider: id };
    if (secondVar !== "") {
      if (secondVar === "subtype") {
        param.id_product_type_detail = secondValue;
        param.category = "";
      } else if (secondVar === "kategori") {
        param.id_product_type_detail = "";
        param.category = secondValue;
      }
    }
    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/product-flashsale/product-detail`,
      params: param,
      resType: "list",
      typeSuccess: GET_CAPTION_SUCCESS,
      typeFailed: GET_CAPTION_FAILED,
    });
  };

export const addPromoFlashsale = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/flashsale`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PROMO_FLASHSALE_SUCCESS,
    typeFailed: ADD_PROMO_FLASHSALE_FAILED,
    notify: true,
  });
};

export const getDetailPromoFlashsale = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/${id}`,
    resType: "one",
    type: GET_DETAIL_PROMO_FLASHSALE,
    typeSuccess: GET_DETAIL_PROMO_FLASHSALE_SUCCESS,
    typeFailed: GET_DETAIL_PROMO_FLASHSALE_FAILED,
  });
};

export const updatePromoFlashsale = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/flashsale/update/${values.get("id")}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_PROMO_FLASHSALE_SUCCESS,
    typeFailed: UPDATE_PROMO_FLASHSALE_FAILED,
    notify: true,
  });
};

export const publishPromoFlashsale = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/publish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: PUBLISH_PROMO_FLASHSALE_SUCCESS,
    typeFailed: PUBLISH_PROMO_FLASHSALE_FAILED,
  });
};

export const unpublishPromoFlashsale = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/unpublish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: UNPUBLISH_PROMO_FLASHSALE_SUCCESS,
    typeFailed: UNPUBLISH_PROMO_FLASHSALE_FAILED,
  });
};

export const duplicatePromoFlashsale = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/flashsale/duplicate/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DUPLICATE_PROMO_FLASHSALE_SUCCESS,
    typeFailed: DUPLICATE_PROMO_FLASHSALE_FAILED,
  });
};

export const deletePromoFlashsale = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/flashsale/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_PROMO_FLASHSALE_SUCCESS,
    typeFailed: DELETE_PROMO_FLASHSALE_FAILED,
  });
};

export const getPromoSpesial = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo`,
    params: {
      page: page,
    },
    resType: "list",
    typeSuccess: GET_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_PROMO_SPESIAL_FAILED,
    type: GET_PROMO_SPESIAL,
  });
};

export const getTotalPromoSpesial = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/count`,
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_SPESIAL_FAILED,
  });
};

export const filterPromoSpesial = (values, page) => async (dispatch) => {
  console.log("value: " + values);
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo`,
    params: {
      page: page,
      filter_status: values.status,
    },
    resType: "list",
    typeSuccess: GET_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_PROMO_SPESIAL_FAILED,
    type: GET_PROMO_SPESIAL,
  });
};

export const filterTotalPromoSpesial = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/count`,
    params: {
      filter_status: values.status,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_SPESIAL_FAILED,
  });
};

export const searchPromoSpesial = (value, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo`,
    params: {
      page: page,
      name: value,
    },
    resType: "list",
    typeSuccess: GET_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_PROMO_SPESIAL_FAILED,
    type: GET_PROMO_SPESIAL,
  });
};

export const searchTotalPromoSpesial = (value) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/count`,
    params: {
      name: value,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_SPESIAL_FAILED,
  });
};

export const formPromoSpesialAddData = () => {
  return {
    type: FORM_PROMO_SPESIAL_ADD_DATA,
  };
};

export const setLoadingSpesialItem = () => {
  return {
    type: LOADING_SPESIAL_ITEM,
  };
};

export const addPromoSpesial = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/promo`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PROMO_SPESIAL_SUCCESS,
    typeFailed: ADD_PROMO_SPESIAL_FAILED,
    notify: true,
  });
};

export const getDetailPromoSpesial = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/${id}`,
    resType: "one",
    type: GET_DETAIL_PROMO_SPESIAL,
    typeSuccess: GET_DETAIL_PROMO_SPESIAL_SUCCESS,
    typeFailed: GET_DETAIL_PROMO_SPESIAL_FAILED,
  });
};

export const updatePromoSpesial = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/promo/update/${values.get("id")}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_PROMO_SPESIAL_SUCCESS,
    typeFailed: UPDATE_PROMO_SPESIAL_FAILED,
    notify: true,
  });
};

export const publishPromoSpesial = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/publish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: PUBLISH_PROMO_SPESIAL_SUCCESS,
    typeFailed: PUBLISH_PROMO_SPESIAL_FAILED,
  });
};

export const unpublishPromoSpesial = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/unpublish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: UNPUBLISH_PROMO_SPESIAL_SUCCESS,
    typeFailed: UNPUBLISH_PROMO_SPESIAL_FAILED,
  });
};

export const duplicatePromoSpesial = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/promo/duplicate/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DUPLICATE_PROMO_SPESIAL_SUCCESS,
    typeFailed: DUPLICATE_PROMO_SPESIAL_FAILED,
  });
};

export const deletePromoSpesial = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/promo/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_PROMO_SPESIAL_SUCCESS,
    typeFailed: DELETE_PROMO_SPESIAL_FAILED,
  });
};

export const getPromoKupon = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon`,
    params: {
      page: page,
    },
    resType: "list",
    typeSuccess: GET_PROMO_KUPON_SUCCESS,
    typeFailed: GET_PROMO_KUPON_FAILED,
    type: GET_PROMO_KUPON,
  });
};

export const getTotalPromoKupon = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/count`,
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_KUPON_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_KUPON_FAILED,
  });
};

export const filterPromoKupon = (values, page) => async (dispatch) => {
  console.log("value: " + values);
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon`,
    params: {
      page: page,
      need: values.need,
      pelanggan: values.pelanggan,
      filter_status: values.status,
    },
    resType: "list",
    typeSuccess: GET_PROMO_KUPON_SUCCESS,
    typeFailed: GET_PROMO_KUPON_FAILED,
    type: GET_PROMO_KUPON,
  });
};

export const filterTotalPromoKupon = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/count`,
    params: {
      need: values.need,
      pelanggan: values.pelanggan,
      filter_status: values.status,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_KUPON_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_KUPON_FAILED,
  });
};

export const searchPromoKupon = (values, page) => async (dispatch) => {
  let param = {
    page: page,
  };
  if (values.option === "kodekupon") param.code = values.text;
  else if (values.option === "namakupon") param.name = values.text;
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon`,
    params: param,
    resType: "list",
    typeSuccess: GET_PROMO_KUPON_SUCCESS,
    typeFailed: GET_PROMO_KUPON_FAILED,
    type: GET_PROMO_KUPON,
  });
};

export const searchTotalPromoKupon = (values) => async (dispatch) => {
  let param = {};
  if (values.option === "kodekupon") param.code = values.text;
  else if (values.option === "namakupon") param.name = values.text;
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/count`,
    params: param,
    resType: "total",
    typeSuccess: GET_TOTAL_PROMO_KUPON_SUCCESS,
    typeFailed: GET_TOTAL_PROMO_KUPON_FAILED,
  });
};

export const formPromoKuponAddData = () => {
  return {
    type: FORM_PROMO_KUPON_ADD_DATA,
  };
};

export const setLoadingKuponItem = () => {
  return {
    type: LOADING_KUPON_ITEM,
  };
};

export const addPromoKupon = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/kupon`,
    data: values,
    resType: "description",
    typeSuccess: ADD_PROMO_KUPON_SUCCESS,
    typeFailed: ADD_PROMO_KUPON_FAILED,
    notify: true,
  });
};

export const getDetailPromoKupon = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/${id}`,
    resType: "one",
    type: GET_DETAIL_PROMO_KUPON,
    typeSuccess: GET_DETAIL_PROMO_KUPON_SUCCESS,
    typeFailed: GET_DETAIL_PROMO_KUPON_FAILED,
  });
};

export const updatePromoKupon = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/kupon/update/${values.get("id")}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_PROMO_KUPON_SUCCESS,
    typeFailed: UPDATE_PROMO_KUPON_FAILED,
    notify: true,
  });
};

export const publishPromoKupon = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/publish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: PUBLISH_PROMO_KUPON_SUCCESS,
    typeFailed: PUBLISH_PROMO_KUPON_FAILED,
  });
};

export const unpublishPromoKupon = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/unpublish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: UNPUBLISH_PROMO_KUPON_SUCCESS,
    typeFailed: UNPUBLISH_PROMO_KUPON_FAILED,
  });
};

export const duplicatePromoKupon = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/kupon/duplicate/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DUPLICATE_PROMO_KUPON_SUCCESS,
    typeFailed: DUPLICATE_PROMO_KUPON_FAILED,
  });
};

export const deletePromoKupon = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/kupon/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_PROMO_KUPON_SUCCESS,
    typeFailed: DELETE_PROMO_KUPON_FAILED,
  });
};

export const getInformasiPemberitahuan = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pemberitahuan`,
    params: {
      page: page,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: GET_INFORMASI_PEMBERITAHUAN_FAILED,
    type: GET_INFORMASI_PEMBERITAHUAN,
  });
};

export const getTotalInformasiPemberitahuan = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pemberitahuan/count`,
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED,
  });
};

export const filterInformasiPemberitahuan =
  (values, page) => async (dispatch) => {
    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pemberitahuan`,
      params: {
        page: page,
        filter_status: values.status,
      },
      resType: "list",
      typeSuccess: GET_INFORMASI_PEMBERITAHUAN_SUCCESS,
      typeFailed: GET_INFORMASI_PEMBERITAHUAN_FAILED,
      type: GET_INFORMASI_PEMBERITAHUAN,
    });
  };

export const filterTotalInformasiPemberitahuan =
  (values) => async (dispatch) => {
    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pemberitahuan/count`,
      params: {
        filter_status: values.status,
      },
      resType: "total",
      typeSuccess: GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS,
      typeFailed: GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED,
    });
  };

export const searchInformasiPemberitahuan =
  (value, page) => async (dispatch) => {
    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pemberitahuan`,
      params: {
        page: page,
        name: value,
      },
      resType: "list",
      typeSuccess: GET_INFORMASI_PEMBERITAHUAN_SUCCESS,
      typeFailed: GET_INFORMASI_PEMBERITAHUAN_FAILED,
      type: GET_INFORMASI_PEMBERITAHUAN,
    });
  };

export const searchTotalInformasiPemberitahuan =
  (value) => async (dispatch) => {
    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pemberitahuan/count`,
      params: {
        name: value,
      },
      resType: "total",
      typeSuccess: GET_TOTAL_INFORMASI_PEMBERITAHUAN_SUCCESS,
      typeFailed: GET_TOTAL_INFORMASI_PEMBERITAHUAN_FAILED,
    });
  };

export const formInformasiPemberitahuanAddData = () => {
  return {
    type: FORM_INFORMASI_PEMBERITAHUAN_ADD_DATA,
  };
};

export const setLoadingPemberitahuanItem = () => {
  return {
    type: LOADING_PEMBERITAHUAN_ITEM,
  };
};

export const addInformasiPemberitahuan = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pemberitahuan`,
    data: values,
    resType: "description",
    typeSuccess: ADD_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: ADD_INFORMASI_PEMBERITAHUAN_FAILED,
    notify: true,
  });
};

export const getDetailInformasiPemberitahuan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pemberitahuan/${id}`,
    resType: "one",
    type: GET_DETAIL_INFORMASI_PEMBERITAHUAN,
    typeSuccess: GET_DETAIL_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: GET_DETAIL_INFORMASI_PEMBERITAHUAN_FAILED,
  });
};

export const updateInformasiPemberitahuan = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pemberitahuan/update/${values.get("id")}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: UPDATE_INFORMASI_PEMBERITAHUAN_FAILED,
    notify: true,
  });
};

export const publishInformasiPemberitahuan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pemberitahuan/publish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: PUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: PUBLISH_INFORMASI_PEMBERITAHUAN_FAILED,
  });
};

export const unpublishInformasiPemberitahuan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pemberitahuan/unpublish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: UNPUBLISH_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: UNPUBLISH_INFORMASI_PEMBERITAHUAN_FAILED,
  });
};

export const duplicateInformasiPemberitahuan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pemberitahuan/duplicate/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DUPLICATE_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: DUPLICATE_INFORMASI_PEMBERITAHUAN_FAILED,
  });
};

export const deleteInformasiPemberitahuan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/pemberitahuan/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_INFORMASI_PEMBERITAHUAN_SUCCESS,
    typeFailed: DELETE_INFORMASI_PEMBERITAHUAN_FAILED,
  });
};

export const getInformasiMenarik = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik`,
    params: {
      page: page,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_INFORMASI_MENARIK_FAILED,
    type: GET_INFORMASI_MENARIK,
  });
};

export const getTotalInformasiMenarik = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/count`,
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_MENARIK_FAILED,
  });
};

export const filterInformasiMenarik = (values, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik`,
    params: {
      page: page,
      filter_status: values.status,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_INFORMASI_MENARIK_FAILED,
    type: GET_INFORMASI_MENARIK,
  });
};

export const filterTotalInformasiMenarik = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/count`,
    params: {
      filter_status: values.status,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_MENARIK_FAILED,
  });
};

export const searchInformasiMenarik = (value, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik`,
    params: {
      page: page,
      name: value,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_INFORMASI_MENARIK_FAILED,
    type: GET_INFORMASI_MENARIK,
  });
};

export const searchTotalInformasiMenarik = (value) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/count`,
    params: {
      name: value,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_MENARIK_FAILED,
  });
};

export const formInformasiMenarikAddData = () => {
  return {
    type: FORM_INFORMASI_MENARIK_ADD_DATA,
  };
};

export const setLoadingMenarikItem = () => {
  return {
    type: LOADING_MENARIK_ITEM,
  };
};

export const addInformasiMenarik = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/menarik`,
    data: values,
    resType: "description",
    typeSuccess: ADD_INFORMASI_MENARIK_SUCCESS,
    typeFailed: ADD_INFORMASI_MENARIK_FAILED,
    notify: true,
  });
};

export const getDetailInformasiMenarik = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/${id}`,
    resType: "one",
    type: GET_DETAIL_INFORMASI_MENARIK,
    typeSuccess: GET_DETAIL_INFORMASI_MENARIK_SUCCESS,
    typeFailed: GET_DETAIL_INFORMASI_MENARIK_FAILED,
  });
};

export const updateInformasiMenarik = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/menarik/update/${values.get("id")}`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_INFORMASI_MENARIK_SUCCESS,
    typeFailed: UPDATE_INFORMASI_MENARIK_FAILED,
    notify: true,
  });
};

export const publishInformasiMenarik = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/publish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: PUBLISH_INFORMASI_MENARIK_SUCCESS,
    typeFailed: PUBLISH_INFORMASI_MENARIK_FAILED,
  });
};

export const unpublishInformasiMenarik = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/unpublish/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: UNPUBLISH_INFORMASI_MENARIK_SUCCESS,
    typeFailed: UNPUBLISH_INFORMASI_MENARIK_FAILED,
  });
};

export const duplicateInformasiMenarik = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/menarik/duplicate/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DUPLICATE_INFORMASI_MENARIK_SUCCESS,
    typeFailed: DUPLICATE_INFORMASI_MENARIK_FAILED,
  });
};

export const deleteInformasiMenarik = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/menarik/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_INFORMASI_MENARIK_SUCCESS,
    typeFailed: DELETE_INFORMASI_MENARIK_FAILED,
  });
};

export const getInformasiProduk = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product`,
    params: {
      page: page,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_INFORMASI_PRODUK_FAILED,
    type: GET_INFORMASI_PRODUK,
  });
};

export const getTotalInformasiProduk = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product/count`,
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_PRODUK_FAILED,
  });
};

export const filterInformasiProduk = (values, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product`,
    params: {
      page: page,
      produk: values.product,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_INFORMASI_PRODUK_FAILED,
    type: GET_INFORMASI_PRODUK,
  });
};

export const filterTotalInformasiProduk = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product/count`,
    params: {
      produk: values.product,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_PRODUK_FAILED,
  });
};

export const searchInformasiProduk = (value, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product/search`,
    params: {
      page: page,
      judul: value,
    },
    resType: "list",
    typeSuccess: GET_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_INFORMASI_PRODUK_FAILED,
    type: GET_INFORMASI_PRODUK,
  });
};

export const searchTotalInformasiProduk = (value) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product/search_count`,
    params: {
      judul: value,
    },
    resType: "total",
    typeSuccess: GET_TOTAL_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_TOTAL_INFORMASI_PRODUK_FAILED,
  });
};

export const getDetailInformasiProduk = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/information/product/detail`,
    params: { id: id },
    resType: "one",
    type: GET_DETAIL_INFORMASI_PRODUK,
    typeSuccess: GET_DETAIL_INFORMASI_PRODUK_SUCCESS,
    typeFailed: GET_DETAIL_INFORMASI_PRODUK_FAILED,
  });
};

export const formInformasiProdukAddData = () => {
  return {
    type: FORM_INFORMASI_PRODUK_ADD_DATA,
  };
};

export const previewInformasiProduk = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/information/product/publish_preview`,
    data: values,
    resType: "one",
    typeSuccess: PREVIEW_INFORMASI_PRODUCT_SUCCESS,
    typeFailed: PREVIEW_INFORMASI_PRODUCT_FAILED,
    notify: true,
  });
};

export const publishInformasiProduk = (values) => async (dispatch) => {
  // for (var pair of values.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/information/product/publish`,
    data: values,
    resType: "description",
    typeSuccess: PUBLISH_INFORMASI_PRODUCT_SUCCESS,
    typeFailed: PUBLISH_INFORMASI_PRODUCT_FAILED,
    notify: true,
  });
};

export const publishInformasiProdukById = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/information/product/publish`,
    data: { id: id },
    resType: "description",
    typeSuccess: PUBLISH_INFORMASI_PRODUCT_SUCCESS,
    typeFailed: PUBLISH_INFORMASI_PRODUCT_FAILED,
    notify: true,
  });
};

export const getMasterConfig = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/configurations`,
    resType: "list",
    type: GET_MASTER_CONFIGURATION,
    typeSuccess: GET_MASTER_CONFIGURATION_SUCCESS,
    typeFailed: GET_MASTER_CONFIGURATION_FAILED,
  });
};
