import { SIGN_IN_SUCCESS, SIGN_IN_FAILED, SIGN_IN } from "../SignIn/constants";
import {
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILED,
  SIGN_OUT,
} from "../../components/HeaderNav/SignOut/constants";
import {
  HEADER_DEFAULT,
  HEADER_TAMBAH_PRODUK,
  HEADER_DETAIL_PRODUK,
  VERIFIED_LOGIN,
  FORM_HEADER,
  PREVIOUS_PAGE,
} from "./constants";

const initialState = {
  nik: "",
  token: null,
  isAuthenticate:
    localStorage.getItem("isAuth") !== null &&
    localStorage.getItem("isAuth") === "1" &&
    localStorage.getItem("token") !== null
      ? true
      : false,
  otherTemplate: null,
  customHeader: "default",
  sideNav: true,
  // isVerify: true,
  isLoadingSignIn: null,
  isLoadingSignOut: null,
  prevPage: null,
};

export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case SIGN_IN:
      return {
        ...state,
        isLoadingSignIn: true,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        nik: localStorage.getItem("nik"),
        token: localStorage.getItem("token"),
        isAuthenticate: true,
        isLoadingSignIn: false,
      };
    case SIGN_IN_FAILED:
      return {
        ...state,
        nik: localStorage.removeItem("nik"),
        token: localStorage.removeItem("token"),
        isAuthenticate: false,
        isLoadingSignIn: false,
      };
    case SIGN_OUT:
      return {
        ...state,
        isLoadingSignOut: true,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        token: localStorage.removeItem("token"),
        isAuthenticate: false,
        isLoadingSignOut: false,
      };
    case SIGN_OUT_FAILED:
      return {
        ...state,
        isLoadingSignOut: false,
      };
    case HEADER_DEFAULT:
      return {
        ...state,
        customHeader: "default",
        otherTemplate: false,
        sideNav: true,
      };
    case FORM_HEADER:
      return {
        ...state,
        otherTemplate: true,
        sideNav: false,
      };
    case HEADER_TAMBAH_PRODUK:
      return {
        ...state,
        customHeader: "tambahproduk",
        otherTemplate: true,
        sideNav: false,
      };
    case HEADER_DETAIL_PRODUK:
      return {
        ...state,
        customHeader: "detailproduk",
        otherTemplate: true,
        sideNav: false,
      };
    case VERIFIED_LOGIN:
      return {
        ...state,
        isAuthenticate: false,
      };
    case PREVIOUS_PAGE:
      return {
        ...state,
        prevPage: action.payload,
      };
  }
};
