import { Fragment, useEffect, useState } from "react";
import { Layout } from "antd";
import MainRouter from "../../routes/MainRouter";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

import "../../assets/style/Main.scss";
import "../../assets/style/Notification.scss";

//This page use name index.js that its being root in views folder
const Main = () => {
  const formState = useSelector((state) => state.global.otherTemplate);
  const [formTemplate, setFormTemplate] = useState(false);

  useEffect(() => {
    let formLS =
      localStorage.getItem("otherTemplate") === "true" ? true : false;
    if (formState === true) setFormTemplate(formLS || formState);
    else if (formState === null) setFormTemplate(formLS);
    else setFormTemplate(formState);
    console.log(formTemplate);
  }, [formState, formTemplate]);

  return (
    <Fragment>
      <Helmet>
        <title>KIOSER: Internal Dashboard</title>
      </Helmet>

      <div className="main">
        <Layout>
          <MainRouter formTemplate={formTemplate} />
        </Layout>
      </div>
    </Fragment>
  );
};
export default Main;
