import {
  GET_PENGGUNA,
  GET_PENGGUNA_SUCCESS,
  GET_PENGGUNA_FAILED,
  HAPUS_PENGGUNA,
  HAPUS_PENGGUNA_SUCCESS,
  HAPUS_PENGGUNA_FAILED,
  TAMBAH_PENGGUNA,
  TAMBAH_PENGGUNA_SUCCESS,
  TAMBAH_PENGGUNA_FAILED,
  DETAIL_PENGGUNA,
  DETAIL_PENGGUNA_SUCCESS,
  DETAIL_PENGGUNA_FAILED,
  GET_JABATAN,
  GET_JABATAN_SUCCESS,
  GET_JABATAN_FAILED,
  GET_SIMPLE_JABATAN,
  GET_SIMPLE_JABATAN_SUCCESS,
  GET_SIMPLE_JABATAN_FAILED,
  TAMBAH_JABATAN,
  TAMBAH_JABATAN_SUCCESS,
  TAMBAH_JABATAN_FAILED,
  DETAIL_JABATAN,
  DETAIL_JABATAN_SUCCESS,
  DETAIL_JABATAN_FAILED,
  HAPUS_JABATAN,
  HAPUS_JABATAN_SUCCESS,
  HAPUS_JABATAN_FAILED,
  GET_PARENT,
  GET_PARENT_SUCCESS,
  GET_PARENT_FAILED,
  TAMBAH_PARENT,
  TAMBAH_PARENT_SUCCESS,
  TAMBAH_PARENT_FAILED,
  DETAIL_PARENT,
  DETAIL_PARENT_SUCCESS,
  DETAIL_PARENT_FAILED,
  HAPUS_PARENT,
  HAPUS_PARENT_SUCCESS,
  HAPUS_PARENT_FAILED,
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
  TAMBAH_MENU,
  TAMBAH_MENU_SUCCESS,
  TAMBAH_MENU_FAILED,
  DETAIL_MENU,
  DETAIL_MENU_SUCCESS,
  DETAIL_MENU_FAILED,
  HAPUS_MENU,
  HAPUS_MENU_SUCCESS,
  HAPUS_MENU_FAILED,
  GET_FITUR,
  GET_FITUR_SUCCESS,
  GET_FITUR_FAILED,
  TAMBAH_FITUR,
  TAMBAH_FITUR_SUCCESS,
  TAMBAH_FITUR_FAILED,
  DETAIL_FITUR,
  DETAIL_FITUR_SUCCESS,
  DETAIL_FITUR_FAILED,
  HAPUS_FITUR,
  HAPUS_FITUR_SUCCESS,
  HAPUS_FITUR_FAILED,
  GET_NESTED_FITUR,
  GET_NESTED_FITUR_SUCCESS,
  GET_NESTED_FITUR_FAILED,
  SET_FILTER_JABATAN,
  RESET_FILTER_JABATAN,
  GET_TOTAL_JABATAN,
  GET_TOTAL_JABATAN_SUCCESS,
  GET_TOTAL_JABATAN_FAILED,
} from "./constants";

import baseAction from "../../utils/baseAction";
import basePromiseAction from "../../utils/basePromiseAction";

const baseUrl = process.env.REACT_APP_API;

export const getPengguna =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pegawai/list`,
      params: param,
      resType: "list",
      type: GET_PENGGUNA,
      typeSuccess: GET_PENGGUNA_SUCCESS,
      typeFailed: GET_PENGGUNA_FAILED,
    });
  };

export const addPengguna = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pegawai/create`,
    data: values,
    resType: "description",
    type: TAMBAH_PENGGUNA,
    typeSuccess: TAMBAH_PENGGUNA_SUCCESS,
    typeFailed: TAMBAH_PENGGUNA_FAILED,
    notify: true,
  });
};

export const getDetailPengguna = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/detail/${id}`,
    resType: "one",
    type: DETAIL_PENGGUNA,
    typeSuccess: DETAIL_PENGGUNA_SUCCESS,
    typeFailed: DETAIL_PENGGUNA_FAILED,
  });
};

export const editPengguna = (id, values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "patch",
    url: `${baseUrl}/pegawai/update/${id}`,
    data: values,
    resType: "description",
    type: TAMBAH_PENGGUNA,
    typeSuccess: TAMBAH_PENGGUNA_SUCCESS,
    typeFailed: TAMBAH_PENGGUNA_FAILED,
    notify: true,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const deletePengguna = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/pegawai/delete/${id}`,
    resRequest: id,
    resType: "res-request",
    type: HAPUS_PENGGUNA,
    typeSuccess: HAPUS_PENGGUNA_SUCCESS,
    typeFailed: HAPUS_PENGGUNA_FAILED,
    notify: true,
  });
};

export const getJabatan =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pegawai/jabatan/list`,
      params: param,
      resType: "list",
      type: GET_JABATAN,
      typeSuccess: GET_JABATAN_SUCCESS,
      typeFailed: GET_JABATAN_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/pegawai/jabatan/count`,
        params: param,
        resType: "total",
        type: GET_TOTAL_JABATAN,
        typeSuccess: GET_TOTAL_JABATAN_SUCCESS,
        typeFailed: GET_TOTAL_JABATAN_FAILED,
      });
    }
  };

export const getSimpleJabatan = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/jabatan/list_simple`,
    resType: "list",
    type: GET_SIMPLE_JABATAN,
    typeSuccess: GET_SIMPLE_JABATAN_SUCCESS,
    typeFailed: GET_SIMPLE_JABATAN_FAILED,
  });
};

export const addJabatan = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pegawai/jabatan/create`,
    data: values,
    resType: "description",
    type: TAMBAH_JABATAN,
    typeSuccess: TAMBAH_JABATAN_SUCCESS,
    typeFailed: TAMBAH_JABATAN_FAILED,
    notify: true,
  });
};

export const getDetailJabatan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/jabatan/detail/${id}`,
    resType: "one",
    type: DETAIL_JABATAN,
    typeSuccess: DETAIL_JABATAN_SUCCESS,
    typeFailed: DETAIL_JABATAN_FAILED,
  });
};

export const editJabatan = (id, values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "patch",
    url: `${baseUrl}/pegawai/jabatan/update/${id}`,
    data: values,
    resType: "description",
    type: TAMBAH_JABATAN,
    typeSuccess: TAMBAH_JABATAN_SUCCESS,
    typeFailed: TAMBAH_JABATAN_FAILED,
    notify: true,
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const deleteJabatan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/pegawai/jabatan/delete/${id}`,
    resRequest: id,
    resType: "res-request",
    type: HAPUS_JABATAN,
    typeSuccess: HAPUS_JABATAN_SUCCESS,
    typeFailed: HAPUS_JABATAN_FAILED,
    notify: true,
  });
};

export const getMenuParent =
  (formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pegawai/parent/list`,
      params: param,
      resType: "list",
      type: GET_PARENT,
      typeSuccess: GET_PARENT_SUCCESS,
      typeFailed: GET_PARENT_FAILED,
    });
  };

export const addParent = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pegawai/parent/create`,
    data: values,
    resType: "description",
    type: TAMBAH_PARENT,
    typeSuccess: TAMBAH_PARENT_SUCCESS,
    typeFailed: TAMBAH_PARENT_FAILED,
    notify: true,
  });
};

export const getDetailParent = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/parent/detail/${id}`,
    resType: "one",
    type: DETAIL_PARENT,
    typeSuccess: DETAIL_PARENT_SUCCESS,
    typeFailed: DETAIL_PARENT_FAILED,
  });
};

export const editParent = (id, values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "patch",
    url: `${baseUrl}/pegawai/parent/update/${id}`,
    data: values,
    resType: "description",
    type: TAMBAH_PARENT,
    typeSuccess: TAMBAH_PARENT_SUCCESS,
    typeFailed: TAMBAH_PARENT_FAILED,
    notify: true,
  });
};

export const deleteParent = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/pegawai/parent/delete/${id}`,
    resRequest: id,
    resType: "res-request",
    type: HAPUS_PARENT,
    typeSuccess: HAPUS_PARENT_SUCCESS,
    typeFailed: HAPUS_PARENT_FAILED,
    notify: true,
  });
};

export const getMenu =
  (formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pegawai/menu/list`,
      params: param,
      resType: "list",
      type: GET_MENU,
      typeSuccess: GET_MENU_SUCCESS,
      typeFailed: GET_MENU_FAILED,
    });
  };

export const addMenu = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pegawai/menu/create`,
    data: values,
    resType: "description",
    type: TAMBAH_MENU,
    typeSuccess: TAMBAH_MENU_SUCCESS,
    typeFailed: TAMBAH_MENU_FAILED,
    notify: true,
  });
};

export const getDetailMenu = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/menu/detail/${id}`,
    resType: "one",
    type: DETAIL_MENU,
    typeSuccess: DETAIL_MENU_SUCCESS,
    typeFailed: DETAIL_MENU_FAILED,
  });
};

export const editMenu = (id, values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "patch",
    url: `${baseUrl}/pegawai/menu/update/${id}`,
    data: values,
    resType: "description",
    type: TAMBAH_MENU,
    typeSuccess: TAMBAH_MENU_SUCCESS,
    typeFailed: TAMBAH_MENU_FAILED,
    notify: true,
  });
};

export const deleteMenu = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/pegawai/menu/delete/${id}`,
    resRequest: id,
    resType: "res-request",
    type: HAPUS_MENU,
    typeSuccess: HAPUS_MENU_SUCCESS,
    typeFailed: HAPUS_MENU_FAILED,
    notify: true,
  });
};

export const getFitur =
  (formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/pegawai/fitur/list`,
      params: param,
      resType: "list",
      type: GET_FITUR,
      typeSuccess: GET_FITUR_SUCCESS,
      typeFailed: GET_FITUR_FAILED,
    });
  };

export const addFitur = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/pegawai/fitur/create`,
    data: values,
    resType: "description",
    type: TAMBAH_FITUR,
    typeSuccess: TAMBAH_FITUR_SUCCESS,
    typeFailed: TAMBAH_FITUR_FAILED,
    notify: true,
  });
};

export const getDetailFitur = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/fitur/detail/${id}`,
    resType: "one",
    type: DETAIL_FITUR,
    typeSuccess: DETAIL_FITUR_SUCCESS,
    typeFailed: DETAIL_FITUR_FAILED,
  });
};

export const editFitur = (id, values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "patch",
    url: `${baseUrl}/pegawai/fitur/update/${id}`,
    data: values,
    resType: "description",
    type: TAMBAH_FITUR,
    typeSuccess: TAMBAH_FITUR_SUCCESS,
    typeFailed: TAMBAH_FITUR_FAILED,
    notify: true,
  });
};

export const deleteFitur = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/pegawai/fitur/delete/${id}`,
    resRequest: id,
    resType: "res-request",
    type: HAPUS_FITUR,
    typeSuccess: HAPUS_FITUR_SUCCESS,
    typeFailed: HAPUS_FITUR_FAILED,
    notify: true,
  });
};

export const getNestedFitur = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/pegawai/fitur/nested`,
    resType: "list",
    type: GET_NESTED_FITUR,
    typeSuccess: GET_NESTED_FITUR_SUCCESS,
    typeFailed: GET_NESTED_FITUR_FAILED,
  });
};

export const setFilterJabatan = (formData) => async (dispatch) => {
  dispatch({
    type: SET_FILTER_JABATAN,
    payload: formData,
  });
};

export const resetFilterJabatan = () => async (dispatch) => {
  dispatch({
    type: RESET_FILTER_JABATAN,
  });
};
