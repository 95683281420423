import {
  GET_DETAIL_SET_METODE,
  GET_DETAIL_SET_METODE_FAILED,
  GET_DETAIL_SET_METODE_SUCCESS,
  GET_LIMIT_DEPOSIT_FAILED,
  GET_LIMIT_DEPOSIT_SUCCESS,
  GET_LIST_SET_METODE,
  GET_LIST_SET_METODE_FAILED,
  GET_LIST_SET_METODE_SUCCESS,
  GET_MAINTENANCE_DEPOSIT_SUCCESS,
  GET_WAKTU_METODE_FAILED,
  GET_WAKTU_METODE_SUCCESS,
  SET_STATUS_METODE_BT_SUCCESS,
  SET_STATUS_METODE_GR_SUCCESS,
  SET_STATUS_METODE_QR_SUCCESS,
  SET_STATUS_METODE_VA_SUCCESS,
  GET_MUTASI,
  GET_MUTASI_SUCCESS,
  GET_MUTASI_FAILED,
  GET_TOTAL_MUTASI_SUCCESS,
  GET_TOTAL_MUTASI_FAILED,
} from "./constants";

const initialState = {
  loadingSetMetode: true,
  loadingMutasi: true,
  listSetMetode: [],
  listSetMetodeTB: [],
  listSetMetodeVA: [],
  listSetMetodeQR: [],
  listSetMetodeGR: [],
  detailSetMetode: {},
  editSetMetode: false,
  realtime: {},
  limitDeposit: {},
  waktuMetodeBank: {},
  waktuMetodeVA: {},
  waktuMetodeQR: {},
  waktuMetodeGR: {},
  maintenance: {},
  mutasi: [],
  forbiddenListSetMetode: false,
  forbiddenDetailSetMetode: false,
  totalMutasi: 0,
  forbiddenMutasi: false,
};

export const depositReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case GET_LIST_SET_METODE:
      return {
        ...state,
        forbiddenListSetMetode: initialState.forbiddenListSetMetode,
      };
    case GET_LIST_SET_METODE_SUCCESS:
      return {
        ...state,
        loadingSetMetode: false,
        listSetMetodeTB: action.payload.banktransfer,
        listSetMetodeVA: action.payload.virtualakun,
        listSetMetodeQR: action.payload.qrcode,
        listSetMetodeGR: action.payload.gerairetail,
      };
    case GET_LIST_SET_METODE_FAILED:
      return {
        ...state,
        loadingSetMetode: false,
        listSetMetode: initialState.listSetMetode,
        forbiddenListSetMetode: action.payload === 403,
      };
    case SET_STATUS_METODE_BT_SUCCESS:
      let newListTB = [...state.listSetMetodeTB];
      let iTB = newListTB.findIndex((x) => x.id_depo === action.payload.code);
      newListTB[iTB].status = action.payload.status;
      return {
        ...state,
        listSetMetodeTB: newListTB,
      };
    case SET_STATUS_METODE_VA_SUCCESS:
      let newListVA = [...state.listSetMetodeVA];
      let iVA = newListVA.findIndex((x) => x.id_depo === action.payload.code);
      newListVA[iVA].status = action.payload.status;
      return {
        ...state,
        listSetMetodeVA: newListVA,
      };
    case SET_STATUS_METODE_QR_SUCCESS:
      let newListQR = [...state.listSetMetodeQR];
      let iQR = newListQR.findIndex((x) => x.id_depo === action.payload.code);
      newListQR[iQR].status = action.payload.status;
      return {
        ...state,
        listSetMetodeQR: newListQR,
      };
    case SET_STATUS_METODE_GR_SUCCESS:
      let newListGR = [...state.listSetMetodeGR];
      let iGR = newListGR.findIndex((x) => x.id_depo === action.payload.code);
      newListGR[iGR].status = action.payload.status;
      return {
        ...state,
        listSetMetodeGR: newListGR,
      };
    case GET_DETAIL_SET_METODE:
      return {
        ...state,
        forbiddenDetailSetMetode: initialState.forbiddenDetailSetMetode,
      };
    case GET_DETAIL_SET_METODE_SUCCESS:
      return {
        ...state,
        detailSetMetode: action.payload,
        editSetMetode: true,
      };
    case GET_DETAIL_SET_METODE_FAILED:
      return {
        ...state,
        detailSetMetode: initialState.detailSetMetode,
        forbiddenDetailSetMetode: action.payload === 403,
      };
    case GET_LIMIT_DEPOSIT_SUCCESS:
      return {
        ...state,
        limitDeposit: action.payload,
      };
    case GET_LIMIT_DEPOSIT_FAILED:
      return {
        ...state,
        limitDeposit: initialState.limitDeposit,
      };
    case GET_WAKTU_METODE_SUCCESS:
      if (action.payload.condition === "bank")
        return {
          ...state,
          waktuMetodeBank: action.payload.data,
        };
      else if (action.payload.condition === "virtual_account")
        return {
          ...state,
          waktuMetodeVA: action.payload.data,
        };
      else if (action.payload.condition === "qris")
        return {
          ...state,
          waktuMetodeQR: action.payload.data,
        };
      else if (action.payload.condition === "gerai_retail")
        return {
          ...state,
          waktuMetodeGR: action.payload.data,
        };
      break;
    case GET_WAKTU_METODE_FAILED:
      return {
        ...state,
        waktuMetodeBank: initialState.waktuMetodeBank,
        waktuMetodeVA: initialState.waktuMetodeVA,
        waktuMetodeQR: initialState.waktuMetodeQR,
        waktuMetodeGR: initialState.waktuMetodeGR,
      };
    case GET_MAINTENANCE_DEPOSIT_SUCCESS:
      return {
        ...state,
        maintenance: action.payload,
      };
    case GET_MUTASI:
      return {
        ...state,
        loadingMutasi: true,
        forbiddenMutasi: initialState.forbiddenMutasi,
      };
    case GET_MUTASI_SUCCESS:
      return {
        ...state,
        loadingMutasi: false,
        mutasi: action.payload,
      };
    case GET_MUTASI_FAILED:
      return {
        ...state,
        loadingMutasi: false,
        mutasi: initialState.mutasi,
        forbiddenMutasi: action.payload === 403,
      };
    case GET_TOTAL_MUTASI_SUCCESS:
      return {
        ...state,
        totalMutasi: action.payload,
      };
    case GET_TOTAL_MUTASI_FAILED:
      return {
        ...state,
        totalMutasi: initialState.totalMutasi,
      };
  }
};
