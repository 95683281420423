import baseAction from "../../utils/baseAction";
import {
  GET_LIST_SET_METODE,
  GET_LIST_SET_METODE_FAILED,
  GET_LIST_SET_METODE_SUCCESS,
  SET_STATUS_METODE_GR_FAILED,
  SET_STATUS_METODE_GR_SUCCESS,
  SET_STATUS_METODE_QR_FAILED,
  SET_STATUS_METODE_QR_SUCCESS,
  SET_STATUS_METODE_BT_FAILED,
  SET_STATUS_METODE_BT_SUCCESS,
  SET_STATUS_METODE_VA_FAILED,
  SET_STATUS_METODE_VA_SUCCESS,
  GET_DETAIL_SET_METODE,
  GET_DETAIL_SET_METODE_SUCCESS,
  GET_DETAIL_SET_METODE_FAILED,
  UPDATE_SET_METODE_SUCCESS,
  UPDATE_SET_METODE_FAILED,
  GET_LIMIT_DEPOSIT_SUCCESS,
  GET_LIMIT_DEPOSIT_FAILED,
  UPDATE_LIMIT_DEPOSIT_SUCCESS,
  UPDATE_LIMIT_DEPOSIT_FAILED,
  GET_WAKTU_METODE_SUCCESS,
  GET_WAKTU_METODE_FAILED,
  UPDATE_WAKTU_METODE_SUCCESS,
  UPDATE_WAKTU_METODE_FAILED,
  SET_MAINTENANCE_DEPOSIT_SUCCESS,
  GET_MAINTENANCE_DEPOSIT_SUCCESS,
  GET_MAINTENANCE_DEPOSIT_FAILED,
  GET_MUTASI,
  GET_MUTASI_SUCCESS,
  GET_MUTASI_FAILED,
  GET_TOTAL_MUTASI,
  GET_TOTAL_MUTASI_SUCCESS,
  GET_TOTAL_MUTASI_FAILED,
} from "./constants";

const baseUrl = process.env.REACT_APP_API;

export const getListSetMetode = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/deposit/deposit_method`,
    resType: "one",
    type: GET_LIST_SET_METODE,
    typeSuccess: GET_LIST_SET_METODE_SUCCESS,
    typeFailed: GET_LIST_SET_METODE_FAILED,
  });
};

export const searchListSetMetode = (search) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/deposit/deposit_method`,
    params: { name: search },
    resType: "one",
    type: GET_LIST_SET_METODE,
    typeSuccess: GET_LIST_SET_METODE_SUCCESS,
    typeFailed: GET_LIST_SET_METODE_FAILED,
  });
};

export const setStatusMetode = (id, status, type) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/update_status`,
    data: { id: id, status: status },
    resRequest: { code: id, status: status },
    resType: "res-request",
    typeSuccess:
      type === "BT"
        ? SET_STATUS_METODE_BT_SUCCESS
        : type === "VA"
        ? SET_STATUS_METODE_VA_SUCCESS
        : type === "QR"
        ? SET_STATUS_METODE_QR_SUCCESS
        : SET_STATUS_METODE_GR_SUCCESS,
    typeFailed:
      type === "BT"
        ? SET_STATUS_METODE_BT_FAILED
        : type === "VA"
        ? SET_STATUS_METODE_VA_FAILED
        : type === "QR"
        ? SET_STATUS_METODE_QR_FAILED
        : SET_STATUS_METODE_GR_FAILED,
    notify: true,
  });
};

export const getDetailSetMetode = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    params: { id: id },
    url: `${baseUrl}/deposit/find_deposit_method`,
    resType: "one",
    type: GET_DETAIL_SET_METODE,
    typeSuccess: GET_DETAIL_SET_METODE_SUCCESS,
    typeFailed: GET_DETAIL_SET_METODE_FAILED,
  });
};

export const updateSetMetode = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/update_method`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_SET_METODE_SUCCESS,
    typeFailed: UPDATE_SET_METODE_FAILED,
    notify: true,
  });
};

export const getLimitDeposit = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/deposit/limit-deposit`,
    resType: "one",
    typeSuccess: GET_LIMIT_DEPOSIT_SUCCESS,
    typeFailed: GET_LIMIT_DEPOSIT_FAILED,
  });
};

export const updateLimitDeposit = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/update-limit-deposit`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_LIMIT_DEPOSIT_SUCCESS,
    typeFailed: UPDATE_LIMIT_DEPOSIT_FAILED,
    notify: true,
  });
};

export const getWaktuMetode = (type) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    resRequest: type,
    url: `${baseUrl}/deposit/method_time`,
    params: { type: type },
    resType: "combine",
    typeSuccess: GET_WAKTU_METODE_SUCCESS,
    typeFailed: GET_WAKTU_METODE_FAILED,
  });
};

export const updateWaktuMetode = (values) => async (dispatch) => {
  console.log(values);
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/update_method_time`,
    data: values,
    resType: "description",
    typeSuccess: UPDATE_WAKTU_METODE_SUCCESS,
    typeFailed: UPDATE_WAKTU_METODE_FAILED,
    notify: true,
  });
};

export const setMaintenanceDeposit = (status) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/info_page/maintenance`,
    data: {
      type: "deposit",
      is_maintenance: status,
      category: "maintenance-noNotif",
    },
    resRequest: { type: "deposit", status: status },
    resType: "res-request",
    typeSuccess: SET_MAINTENANCE_DEPOSIT_SUCCESS,
    typeFailed: SET_MAINTENANCE_DEPOSIT_SUCCESS,
    notify: true,
  });
};

export const getMaintenanceDeposit = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/info_page/maintenance_status`,
    data: { type: "deposit" },
    resType: "one",
    typeSuccess: GET_MAINTENANCE_DEPOSIT_SUCCESS,
    typeFailed: GET_MAINTENANCE_DEPOSIT_FAILED,
  });
};

export const getMutasi =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        if (pair[1] !== "undefined") {
          param[pair[0]] = pair[1];
        }
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }
    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/log/mutation/all`,
      params: param,
      resType: "list",
      type: GET_MUTASI,
      typeSuccess: GET_MUTASI_SUCCESS,
      typeFailed: GET_MUTASI_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/log/mutation/count`,
        params: param,
        resType: "total",
        type: GET_TOTAL_MUTASI,
        typeSuccess: GET_TOTAL_MUTASI_SUCCESS,
        typeFailed: GET_TOTAL_MUTASI_FAILED,
      });
    }
  };
