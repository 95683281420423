import { Button, Col, Row } from "antd";
import { useState } from "react";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import FloatInput from "./FloatInput";

const KeyValueItem = (props) => {
  const [reset, setReset] = useState(false);
  const [itemDelete, setItemDelete] = useState(false);

  const { index } = props;

  const deleteKeyValue = () => {
    setItemDelete(true);
  };

  return (
    <>
      {itemDelete ? (
        <></>
      ) : (
        <Row gutter={[12, 12]} className="itemKeyValue" key={index}>
          <Col span={11}>
            <FloatInput
              label="Key"
              placeholder="Key"
              name="key"
              reset={reset}
              setReset={setReset}
            />
          </Col>
          <Col span={11}>
            <FloatInput
              label="Value"
              placeholder="Value"
              name="value"
              reset={reset}
              setReset={setReset}
            />
          </Col>
          <Col span={2}>
            <Button
              danger
              className="deleteKeyValue"
              onClick={() => deleteKeyValue()}
            >
              <DeleteBinLineIcon size={20} />
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default KeyValueItem;
